@keyframes cssload-dots {
	0%, 15% {
		transform: rotateZ(0deg); }

	25%, 40% {
		transform: rotateZ(90deg); }

	50%, 65% {
		transform: rotateZ(180deg); }

	75%, 90% {
		transform: rotateZ(270deg); }

	100% {
		transform: rotateZ(360deg); } }



@-o-keyframes cssload-dots {
	0%, 15% {
		-o-transform: rotateZ(0deg); }

	25%, 40% {
		-o-transform: rotateZ(90deg); }

	50%, 65% {
		-o-transform: rotateZ(180deg); }

	75%, 90% {
		-o-transform: rotateZ(270deg); }

	100% {
		-o-transform: rotateZ(360deg); } }



@-ms-keyframes cssload-dots {
	0%, 15% {
		-ms-transform: rotateZ(0deg); }

	25%, 40% {
		-ms-transform: rotateZ(90deg); }

	50%, 65% {
		-ms-transform: rotateZ(180deg); }

	75%, 90% {
		-ms-transform: rotateZ(270deg); }

	100% {
		-ms-transform: rotateZ(360deg); } }



@-webkit-keyframes cssload-dots {
	0%, 15% {
		-webkit-transform: rotateZ(0deg); }

	25%, 40% {
		-webkit-transform: rotateZ(90deg); }

	50%, 65% {
		-webkit-transform: rotateZ(180deg); }

	75%, 90% {
		-webkit-transform: rotateZ(270deg); }

	100% {
		-webkit-transform: rotateZ(360deg); } }



@-moz-keyframes cssload-dots {
	0%, 15% {
		-moz-transform: rotateZ(0deg); }

	25%, 40% {
		-moz-transform: rotateZ(90deg); }

	50%, 65% {
		-moz-transform: rotateZ(180deg); }

	75%, 90% {
		-moz-transform: rotateZ(270deg); }

	100% {
		-moz-transform: rotateZ(360deg); } }



@keyframes cssload-dot {
	0% {
		transform: translateX(0) translateY(-11px); }

	10%, 20% {
		transform: translateX(5px) translateY(-5px); }

	30%, 50% {
		transform: translateX(0px) translateY(0px); } }



@-o-keyframes cssload-dot {
	0% {
		-o-transform: translateX(0) translateY(-11px); }

	10%, 20% {
		-o-transform: translateX(5px) translateY(-5px); }

	30%, 50% {
		-o-transform: translateX(0px) translateY(0px); } }



@-ms-keyframes cssload-dot {
	0% {
		-ms-transform: translateX(0) translateY(-11px); }

	10%, 20% {
		-ms-transform: translateX(5px) translateY(-5px); }

	30%, 50% {
		-ms-transform: translateX(0px) translateY(0px); } }



@-webkit-keyframes cssload-dot {
	0% {
		-webkit-transform: translateX(0) translateY(-11px); }

	10%, 20% {
		-webkit-transform: translateX(5px) translateY(-5px); }

	30%, 50% {
		-webkit-transform: translateX(0px) translateY(0px); } }



@-moz-keyframes cssload-dot {
	0% {
		-moz-transform: translateX(0) translateY(-11px); }

	10%, 20% {
		-moz-transform: translateX(5px) translateY(-5px); }

	30%, 50% {
		-moz-transform: translateX(0px) translateY(0px); } }



@keyframes cssload-dot2 {
	0% {
		transform: translateX(-5px) translateY(-5px); }

	10%, 55% {
		transform: translateX(0px) translateY(0px); } }



@-o-keyframes cssload-dot2 {
	0% {
		-o-transform: translateX(-5px) translateY(-5px); }

	10%, 55% {
		-o-transform: translateX(0px) translateY(0px); } }



@-ms-keyframes cssload-dot2 {
	0% {
		-ms-transform: translateX(-5px) translateY(-5px); }

	10%, 55% {
		-ms-transform: translateX(0px) translateY(0px); } }



@-webkit-keyframes cssload-dot2 {
	0% {
		-webkit-transform: translateX(-5px) translateY(-5px); }

	10%, 55% {
		-webkit-transform: translateX(0px) translateY(0px); } }



@-moz-keyframes cssload-dot2 {
	0% {
		-moz-transform: translateX(-5px) translateY(-5px); }

	10%, 55% {
		-moz-transform: translateX(0px) translateY(0px); } }



@keyframes cssload-dot4 {
	0% {
		transform: translateX(-11px) translateY(0px); }

	10%, 30% {
		transform: translateX(-5px) translateY(5px); }

	40%, 65% {
		transform: translateX(0px) translateY(0px); } }



@-o-keyframes cssload-dot4 {
	0% {
		-o-transform: translateX(-11px) translateY(0px); }

	10%, 30% {
		-o-transform: translateX(-5px) translateY(5px); }

	40%, 65% {
		-o-transform: translateX(0px) translateY(0px); } }



@-ms-keyframes cssload-dot4 {
	0% {
		-ms-transform: translateX(-11px) translateY(0px); }

	10%, 30% {
		-ms-transform: translateX(-5px) translateY(5px); }

	40%, 65% {
		-ms-transform: translateX(0px) translateY(0px); } }



@-webkit-keyframes cssload-dot4 {
	0% {
		-webkit-transform: translateX(-11px) translateY(0px); }

	10%, 30% {
		-webkit-transform: translateX(-5px) translateY(5px); }

	40%, 65% {
		-webkit-transform: translateX(0px) translateY(0px); } }



@-moz-keyframes cssload-dot4 {
	0% {
		-moz-transform: translateX(-11px) translateY(0px); }

	10%, 30% {
		-moz-transform: translateX(-5px) translateY(5px); }

	40%, 65% {
		-moz-transform: translateX(0px) translateY(0px); } }



@keyframes cssload-dot6 {
	0%, 10% {
		transform: translateX(11px) translateY(0px); }

	20% {
		transform: translateX(5px) translateY(-5px); }

	30%, 70% {
		transform: translateX(0px) translateY(0px); } }



@-o-keyframes cssload-dot6 {
	0%, 10% {
		-o-transform: translateX(11px) translateY(0px); }

	20% {
		-o-transform: translateX(5px) translateY(-5px); }

	30%, 70% {
		-o-transform: translateX(0px) translateY(0px); } }



@-ms-keyframes cssload-dot6 {
	0%, 10% {
		-ms-transform: translateX(11px) translateY(0px); }

	20% {
		-ms-transform: translateX(5px) translateY(-5px); }

	30%, 70% {
		-ms-transform: translateX(0px) translateY(0px); } }



@-webkit-keyframes cssload-dot6 {
	0%, 10% {
		-webkit-transform: translateX(11px) translateY(0px); }

	20% {
		-webkit-transform: translateX(5px) translateY(-5px); }

	30%, 70% {
		-webkit-transform: translateX(0px) translateY(0px); } }



@-moz-keyframes cssload-dot6 {
	0%, 10% {
		-moz-transform: translateX(11px) translateY(0px); }

	20% {
		-moz-transform: translateX(5px) translateY(-5px); }

	30%, 70% {
		-moz-transform: translateX(0px) translateY(0px); } }



@keyframes cssload-dot8 {
	0%, 10% {
		transform: translateX(5px) translateY(5px); }

	20%, 80% {
		transform: translateX(0px) translateY(0px); } }



@-o-keyframes cssload-dot8 {
	0%, 10% {
		-o-transform: translateX(5px) translateY(5px); }

	20%, 80% {
		-o-transform: translateX(0px) translateY(0px); } }



@-ms-keyframes cssload-dot8 {
	0%, 10% {
		-ms-transform: translateX(5px) translateY(5px); }

	20%, 80% {
		-ms-transform: translateX(0px) translateY(0px); } }



@-webkit-keyframes cssload-dot8 {
	0%, 10% {
		-webkit-transform: translateX(5px) translateY(5px); }

	20%, 80% {
		-webkit-transform: translateX(0px) translateY(0px); } }



@-moz-keyframes cssload-dot8 {
	0%, 10% {
		-moz-transform: translateX(5px) translateY(5px); }

	20%, 80% {
		-moz-transform: translateX(0px) translateY(0px); } }



@keyframes cssload-dot9 {
	0%, 10% {
		transform: translateX(0px) translateY(11px); }

	20%, 30% {
		transform: translateX(-5px) translateY(5px); }

	40%, 85% {
		transform: translateX(0px) translateY(0px); } }



@-o-keyframes cssload-dot9 {
	0%, 10% {
		-o-transform: translateX(0px) translateY(11px); }

	20%, 30% {
		-o-transform: translateX(-5px) translateY(5px); }

	40%, 85% {
		-o-transform: translateX(0px) translateY(0px); } }



@-ms-keyframes cssload-dot9 {
	0%, 10% {
		-ms-transform: translateX(0px) translateY(11px); }

	20%, 30% {
		-ms-transform: translateX(-5px) translateY(5px); }

	40%, 85% {
		-ms-transform: translateX(0px) translateY(0px); } }



@-webkit-keyframes cssload-dot9 {
	0%, 10% {
		-webkit-transform: translateX(0px) translateY(11px); }

	20%, 30% {
		-webkit-transform: translateX(-5px) translateY(5px); }

	40%, 85% {
		-webkit-transform: translateX(0px) translateY(0px); } }



@-moz-keyframes cssload-dot9 {
	0%, 10% {
		-moz-transform: translateX(0px) translateY(11px); }

	20%, 30% {
		-moz-transform: translateX(-5px) translateY(5px); }

	40%, 85% {
		-moz-transform: translateX(0px) translateY(0px); } }



@keyframes cssload-dot3 {
	0%, 20% {
		transform: translateX(5px) translateY(-5px); }

	30%, 60% {
		transform: translateX(0px) translateY(0px); } }



@-o-keyframes cssload-dot3 {
	0%, 20% {
		-o-transform: translateX(5px) translateY(-5px); }

	30%, 60% {
		-o-transform: translateX(0px) translateY(0px); } }



@-ms-keyframes cssload-dot3 {
	0%, 20% {
		-ms-transform: translateX(5px) translateY(-5px); }

	30%, 60% {
		-ms-transform: translateX(0px) translateY(0px); } }



@-webkit-keyframes cssload-dot3 {
	0%, 20% {
		-webkit-transform: translateX(5px) translateY(-5px); }

	30%, 60% {
		-webkit-transform: translateX(0px) translateY(0px); } }



@-moz-keyframes cssload-dot3 {
	0%, 20% {
		-moz-transform: translateX(5px) translateY(-5px); }

	30%, 60% {
		-moz-transform: translateX(0px) translateY(0px); } }



@keyframes cssload-dot7 {
	0%, 30% {
		transform: translateX(-5px) translateY(5px); }

	40%, 75% {
		transform: translateX(0px) translateY(0px); } }



@-o-keyframes cssload-dot7 {
	0%, 30% {
		-o-transform: translateX(-5px) translateY(5px); }

	40%, 75% {
		-o-transform: translateX(0px) translateY(0px); } }



@-ms-keyframes cssload-dot7 {
	0%, 30% {
		-ms-transform: translateX(-5px) translateY(5px); }

	40%, 75% {
		-ms-transform: translateX(0px) translateY(0px); } }



@-webkit-keyframes cssload-dot7 {
	0%, 30% {
		-webkit-transform: translateX(-5px) translateY(5px); }

	40%, 75% {
		-webkit-transform: translateX(0px) translateY(0px); } }



@-moz-keyframes cssload-dot7 {
	0%, 30% {
		-moz-transform: translateX(-5px) translateY(5px); }

	40%, 75% {
		-moz-transform: translateX(0px) translateY(0px); } }
