//Fonts
$greyText: rgba(0,0,0,.8);
$button-blue: #3898ce;
$button-orange: rgb(255, 122, 87);
$prime-color: #3898ce;

.build_number {
	z-index: 0; } // <<< BUILD_NUMBER

.build_number::before {
	content: "0"; } // <<< BUILD_NUMBER

html {
	font-size: 14px; }

@font-face {
	font-family: 'Montserrat';
	font-weight: 300;
	src: url('fonts/Montserrat-Light.woff2') format('woff2'),
	url('fonts/Montserrat-Light.woff') format('woff'),
	url('fonts/Montserrat-Light.ttf')  format('truetype'),
	url('fonts/Montserrat-Light.svg#svgFontName') format('svg') {} }

@font-face {
	font-family: 'Montserrat';
	font-weight: 400;
	src: url('fonts/Montserrat-Regular.woff2') format('woff2'),
	url('fonts/Montserrat-Regular.woff') format('woff'),
	url('fonts/Montserrat-Regular.ttf')  format('truetype'),
	url('fonts/Montserrat-Regular.svg#svgFontName') format('svg') {} }

@font-face {
	font-family: 'Montserrat';
	font-weight: 400;
	font-style: italic;
	src: url('fonts/Montserrat-Italic.woff2') format('woff2'),
	url('fonts/Montserrat-Italic.woff') format('woff'),
	url('fonts/Montserrat-Italic.ttf')  format('truetype'),
	url('fonts/Montserrat-Italic.svg#svgFontName') format('svg') {} }

@font-face {
	font-family: 'Montserrat';
	font-weight: 500;
	src: url('fonts/Montserrat-Medium.woff2') format('woff2'),
	url('fonts/Montserrat-Medium.woff') format('woff'),
	url('fonts/Montserrat-Medium.ttf')  format('truetype'),
	url('fonts/Montserrat-Medium.svg#svgFontName') format('svg') {} }

@font-face {
	font-family: 'Montserrat';
	font-weight: 600;
	src: url('fonts/Montserrat-SemiBold.woff2') format('woff2'),
	url('fonts/Montserrat-SemiBold.woff') format('woff'),
	url('fonts/Montserrat-SemiBold.ttf')  format('truetype'),
	url('fonts/Montserrat-SemiBold.svg#svgFontName') format('svg') {} }

@font-face {
	font-family: 'Montserrat';
	font-weight: 700;
	src: url('fonts/Montserrat-Bold.woff2') format('woff2'),
	url('fonts/Montserrat-Bold.woff') format('woff'),
	url('fonts/Montserrat-Bold.ttf')  format('truetype'),
	url('fonts/Montserrat-Bold.svg#svgFontName') format('svg') {} }

@font-face {
	font-family: 'Montserrat';
	font-weight: 800;
	src: url('fonts/Montserrat-ExtraBold.woff2') format('woff2'),
	url('fonts/Montserrat-ExtraBold.woff') format('woff'),
	url('fonts/Montserrat-ExtraBold.ttf')  format('truetype'),
	url('fonts/Montserrat-ExtraBold.svg#svgFontName') format('svg') {} }

@font-face {
	font-family: 'Montserrat';
	font-weight: 900;
	src: url('fonts/Montserrat-Black.woff2') format('woff2'),
	url('fonts/Montserrat-Black.woff') format('woff'),
	url('fonts/Montserrat-Black.ttf')  format('truetype'),
	url('fonts/Montserrat-Black.svg#svgFontName') format('svg') {} }


* {
	-webkit-margin-before: 0em;
	-webkit-margin-after: 0em;
	-webkit-margin-start: 0px;
	-webkit-margin-end: 0px;
	-webkit-padding-start: 0px;
	outline: none; }

body {
	font-family: Montserrat, Helvetica, Arial, sans-serif; }

a {
	text-decoration: none !important;
	cursor: pointer; }

ul {
	list-style: none; }

h1 {
	padding: 0;
	margin: 0; }

p {
	margin: 0;
	padding: 0; }

@mixin border-radius($radius) {
	-webkit-border-radius: $radius;
	-moz-border-radius: $radius;
	-ms-border-radius: $radius;
	border-radius: $radius; }

@mixin box-shadow($shadow) {
	-webkit-box-shadow: $shadow;
	-moz-box-shadow: $shadow;
	-ms-box-shadow: $shadow;
	box-shadow: $shadow; }

@mixin disabled-button {
	opacity: 0.5;
	cursor: default; }

.disabled-button {
	@include disabled-button; }

@mixin no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.no-select {
	@include no-select; }

.inactive-element {
	opacity: 0.5;
	cursor: url(./img/cursor-disabled.png), auto;
	&:hover {
		box-shadow: none !important; } }
/* Bootstrap Grid Rewrite */
.col-md-12 {
	@media (min-width: 992px) {
		width: 50%; } }
/* Sliders */
input[type=range] {
	-webkit-appearance: none;
	width: 100%;
	position: relative;
	z-index: 1;
	background-color: transparent; }

input[type=range]::-webkit-slider-thumb {
	-webkit-appearance: none;
	border: none;
	height: 16px;
	width: 16px;
	border-radius: 2px;
	background: #99C700; }

input[type=range]:focus {
	outline: none; }

input[type=range]::-moz-range-thumb {
	border: none;
	height: 16px;
	width: 16px;
	border-radius: 2px;
	background: #99C700; }


input[type=range]::-ms-thumb {
	border: none;
	height: 16px;
	width: 16px;
	border-radius: 2px;
	background: #99C700; }

input::-ms-clear {
  display: none; }

.slider {
	height: 6px;
	position: relative;
	width: 100%;
	background: #ccc;
	z-index: 0;
	margin-top: -10px;

	.slider-filled {
		position: absolute;
		left: 0;
		width: 0;
		height: 6px;
		background: #99C700; } }

/* Text inputs */

@mixin defaultInput($font-size) {
	// background: #ebecef
	background: #fafafa;
	border-radius: 2px;
	height: 64px;
	font-size: $font-size;
	color: #333333;
	padding-left: 20px;
	padding-right: 10px;
	box-shadow: none;
	letter-spacing: 1px;
	border: 1px solid rgba(0, 0, 0, 0.075); }

input[type="text"], input[type="password"], input[type="tel"] {
	@include defaultInput(18px); }

input.unvalidated, textarea.unvalidated {
	border: 1px solid #DD5656 !important; }

input::-webkit-input-placeholder {
	color: rgba(#000, 0.42) !important; }

input::-moz-placeholder {
	color: rgba(#000, 0.42) !important; }

input:-moz-placeholder {
	color: rgba(#000, 0.42) !important; }

input:-ms-input-placeholder {
	color: rgba(#000, 0.42) !important; }

input::-ms-input-placeholder {
	color: rgba(#000, 0.42) !important; }

.field-error {
	color: #DD5656 !important;
	font-size: 12px !important;
	margin-bottom: 5px !important;
	margin-top: 0 !important;
	padding-left: 8px;
	min-height: 26px;
	line-height: 26px; }

.navbar-toggle {
	transition: 0.3s background-color, 0.3s border-color; }

button {
	padding: 0;
	margin: 0;
	border: none;
	background: transparent; }

.btn {
	transition: 0.3s background-color, 0.3s border-color;
	border: none;
	font-size: 18px;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
	line-height: 1.5;
	padding: 5px 20px;
	transition: 0.3s background-color, 0.3s border-color, 0.3s box-shadow, 0.3s opacity;
	outline: none !important;

	&:hover,
	&:active,
	&:focus {
		color: white;
		outline: none !important; } }

.btn-large {
	padding: 17px 60px;
	line-height: 30px; }

.btn.disabled {
	opacity: 0.3; }

.btn-disabled {
	opacity: 0.5;
	cursor: pointer; }

.btn-outline {
	border: 1px solid white;
	color: white;

	&:hover:not(.disabled) {
		color: #333;
		background: white; } }

.btn-primary {
	background: $prime-color;
	color: white;
	border-color: $prime-color;
	text-shadow: none;
	box-shadow: none;
	&:hover, &:focus {
		background-position: center; }
	&[disabled] {
		background: $prime-color; }
	&:hover:not(.disabled):not([disabled]),
	&:focus:not(.disabled):not([disabled]),
	&:active:not(.disabled):not([disabled]) {
		color: white;
		background-color: saturate(darken($prime-color, 10%), 20%);
		border-color: saturate(darken($prime-color, 10%), 20%);
		box-shadow: 0 2px 5px rgba(28, 114, 162, 0.5); }

	&:active:not(.disabled) {
		background-color: darken(#3898ce, 30%); }

	&.disabled:hover, &[disabled]:hover, &.disabled:active, &[disabled]:active, &.disabled:focus, &[disabled]:focus {
		background-color: $prime-color;
		border-color: $prime-color; } }

.btn-orange {
	background-color: $button-orange;
	border-color: $button-orange !important;
	color: white;

	&:hover:not(.disabled):not([disabled]) {
		color: white;
		background-color: saturate(darken($button-orange, 10%), 20%);
		border-color: saturate(darken($button-orange, 10%), 20%);
		box-shadow: 0 2px 5px rgba(28, 114, 162, 0.5); }

	&:active:not(.disabled) {
		color: white;
		background-color: darken($button-orange, 30%);
		border-color: darken($button-orange, 30%); } }

.btn-refresh {
	display: block;
	margin: 10px auto;
	width: 70px;
	height: 70px;
	border-radius: 50%;
	background-image: url('img/icons/refresh.svg');
	background-position: center;
	background-size: 70%;
	background-repeat: no-repeat;

	&:active, &:focus, &:target {
		background-image: url('img/icons/refresh.svg'); } }

.navbar {
	margin: 0;
	border: none;
	transition: 0.4s height;
	min-height: 0 !important;
	z-index: 10;

	.navbar-toggle .icon-bar {
		background: white; }

	.logo {
		padding-top: 30px;
		margin-right: 1%; } }

.navbar-header {
	text-align: center;

	@media (max-width: 992px) {
		float: right; } }



.navbar.opened {
	// height: 650px
	transition: 0.4s height;
	// background: #fff !important
	height: 100vh;
	background: $prime-color;
	position: fixed;

	&~.section .mobile-menu__logo {
		position: fixed; }

	.navbar-toggle {
		background: white;
		box-shadow: 1px 1px 3px #555;

		&:active,
		&:visited,
		&:focus,
		&:hover {
			background: white; }

		.icon-bar {
			background: $prime-color;
			transition: 0.3s all;

			&:nth-of-type(2) {
				transform: translateY(3px) rotate(45deg);
				height: 2px; }

			&:nth-of-type(3) {
				transform: translateY(-3px) rotate(-45deg);
				height: 2px; }

			&:nth-of-type(4) {
				display: none; } } }

	.logo {
		@media screen and (max-width:992px) {
			display: none; } }

	.navbar-collapse {
		@media (max-width:992px) {
			display: block;
			padding-top: 50px; } } }

@media (max-width:992px) and (min-width:768px) {
	.navbar:not(.opened) {
		.collapse.navbar-collapse {
			display: none !important; } }
	.navbar.opened {
 }		// display: block !important
	.navbar-toggle {
		display: block; }

	.navbar-nav {
		float: none;
		& > li {
			float: none; } } }

.navbar-nav {
	width: 100%;

	// .navbar-nav__link:not(.navbar-nav__link--language)
	.navbar-nav__link {
		padding-top: 38px;
		padding-bottom: 38px;
		// color: #fff !important
		font-size: 12px;
		text-transform: uppercase;

		@media (max-width:1000px) {
			padding: 38px 10px; }

		@media (max-width:950px) {
			padding: 38px 5px; }

		@media (max-width:895px) {
			font-size: 11px; }

		@media (max-width:860px) {
			font-size: 10px; } }

	.navbar-nav__link.navbar-nav__link--logo {
		padding-top: 29px; }

	.language {
		float: right;
		padding-top: 24px;
		position: relative;
		margin-right: 15px;
		width: 82px;

		// @media (max-width: 768px)
		@media (max-width:992px) {
			float: none;
			width: unset;
			margin-right: auto;
			margin-lefT: auto; }

		ul {
			height: 48px;
			transition: 0.5s height;
			border-radius: 25px;
			border: 1px solid #ccd520;
			overflow: hidden;
			// position: absolute
			padding-left: 0;
			cursor: pointer;

			// @media (max-width: 768px)
			@media (max-width:992px) {
				position: relative;
				display: inline-block; }

			li {
				line-height: 40px;
				text-align: center;
				padding: 12px 20px;

				// @media (max-width: 768px)
				@media (max-width: 992px) {
					float: left; }

				a {
					display: block;
					cursor: pointer;
					background: url(img/flags.png) no-repeat center;
					width: 40px;
					border: 1px solid #000;
					height: 22px;
					padding: 0; } }

			&.opened {
				// @media (min-width: 768px)
				@media (min-width: 992px) {
					height: 94px;
					transition: 0.5s height; } } } } }

.navbar-toggle {
	margin: 16px auto 8px;
	float: none;
	background-color: #3898ce;
	width: 30px;
	height: 30px;
	border-radius: 30px;
	border: none;
	padding: 9px;
	box-shadow: 0 0 17px rgba(28, 114, 162, 0.27);
	position: absolute;
	right: 42px;
	z-index: 5;

	& .icon-bar {
		height: 1px;
		width: 13px; }

	@media (max-width: 400px) {
		right: 20px; } }

.navbar .navbar-toggle:hover,
.navbar .navbar-toggle:focus {
	background-color: lighten(#3898ce, 10%); }

.collapsing {
	-webkit-transition: none;
	transition: none; }

.container-fluid {
	padding: 0;
	position: relative; }


.px14-pale-text {
	font-size: 14px;
	opacity: 0.36;
	text-align: center; }


.px16-pale-text {
	font-size: 16px;
	opacity: 0.36;
	text-align: center; }

.px12-semipale-text {
	font-size: 12px;
	opacity: 0.42; }

.px14-semipale-text {
	font-size: 12px;
	opacity: 0.42; }

.px16-semipale-text {
	font-size: 16px;
	opacity: 0.42;
	color: #333333; }

.px20-semipale-text {
	font-size: 20px;
	opacity: 0.42;
	color: #333333; }

.btn-yellow {
	background: #ccd520;
	height: 64px;
	line-height: 64px;
	border-radius: 20px;
	color: #000;
	padding: 10px; }

.btn-big-dark {
	border: 1px solid #333333;
	@include border-radius(4px);
	font-size: 16px;
	color: #333333;
	display: block;
	width: 256px;
	text-align: center;
	height: 64px;
	line-height: 64px; }

.btn-big-stroke-green {
	border: 1px solid #99C700;
	@include border-radius(4px);
	font-size: 16px;
	color: #FFFFFF;
	display: block;
	width: 256px;
	text-align: center;
	height: 64px;
	line-height: 64px;
	&:hover {
		color: #99C700; } }


.btn-green {
	background: #99C700;
	@include border-radius(4px);
	font-size: 16px;
	display: block;
	text-align: center;
	height: 64px;
	color: #fff;
	line-height: 64px;

	&:hover {
		color: #fff; } }

.btn-big-fill-green {
	@extend .btn-green;
	width: 240px; }

.btn-middle-fill-green {
	@extend .btn-green;
	width: 128px;
	height: 48px;
	line-height: 48px; }

.active-btn {
	background: #99C700;
	color: #fff !important; }

.search {
	background: #fff;
	border-top: 1px solid #333333;
	border-bottom: 1px solid #333333;

	input {
		display: block;
		clear: both;
		height: 80px;
		line-height: 80px;
		width: 100%;
		margin: 0 auto;
		font-size: 16px;
		opacity: 0.42;
		border: none; } }

.content-header {
	background: #626C6F;
	padding-top: 84px;
	padding-bottom: 30px;

	color: #fff;
	& > div {
		position: relative; }

	h2 {
		font-size: 44px;
		margin-top: 0;
		min-height: 56px;
		line-height: 56px; }

	img {
		position: absolute;
		right: 0;
		top: 0;
		bottom: 0;
		margin: auto;

		@media screen and (max-width: 450px) {
			display: none; } } }

@import './styles/footer';
@import './styles/offices';
@import './styles/login-page';

.scrollarea {
	position: fixed;
	top: 0;
	right: 0;
	z-index: 1;
	width: 280px;
	overflow: auto;
	height: auto !important;
	width: auto !important;
	float: none !important;
	background: none !important; }

.right-menu.opened {
	height: 100vh !important;
	min-height: 900px !important;
	opacity: 1 !important;
	border: none !important;
	background: #464646 !important;
	width: 230px;

	@media screen and (min-width:951px) {
		width: 280px; }

	& > .scrollarea {
		height: 100vh !important;

		.scrollarea-content {
			min-height: 100%; } } }

.right-menu {
	height: 100vh;
	position: fixed;
	float: right;
	// background: #352ddb
	background: $prime-color;
	color: #fff;
	top: 0;
	right: 0;
	z-index: 2;
	padding-top: 20px;
	min-height: 900px;
	width: 280px;

	& > .scrollarea {
		height: 100vh !important; }

	@media screen and (max-width:950px) {
		width: 230px; }

	@media screen and (max-width:767px) {
		min-height: 0;
		height: 0;
		padding-top: 0;
		opacity: 0.5;
		border-right: 60px solid #464646;
		border-top: 10px solid transparent;
		border-bottom: 10px solid transparent;
		height: 50px;
		background: transparent;
		width: 60px;

		& > .scrollarea {
			position: fixed;
			height: 50px !important; } }
	button {
		span {
			background: #fff; } }

	div.big-menu {
		width: 280px;
		min-height: 900px;

		@media screen and (max-width:950px) {
			width: 230px !important; }

		& > div {
			padding: 0 24px; } }

	div.big-menu.opened {
		display: block !important; }

	div.small-menu {
		width: 75px;
		text-align: center;
		min-height: 900px; }



	.header-profile {
		text-align: center;
		border-bottom: 1px solid rgba(255, 255, 255, 0.16);
		padding-top: 40px;
		padding-bottom: 20px !important;

		.goals-scroll {
			max-height: 160px !important;
			width: 110% !important; }

		.balances-scroll {
			max-height: 65px;
			padding-bottom: 65px; }

		.btn-big-fill-green {
			margin-top: 20px;

			@media screen and (max-width:1100px) {
				width: 180px; } }

		p {
			clear: both;
			text-align: left;
			font-size: 14px;
			margin-top: 55px;
			color: rgba(255, 255, 255, 0.16);
			margin-top: 30px; }

		.sum {
			color: #fff;
			margin: 0;
			word-wrap: break-word;
			text-align: left;
			font-size: 30px !important; }

		@media screen and (max-width:1100px) {
			padding-left: 12px !important;
			padding-right: 12px !important; } }

	.goal {
		margin-top: 20px;
		text-align: left; }

	.status {
		height: 30px; }

	.goal p {
		font-size: 16px;
		text-align: left;
		margin-top: 0; }

	.goal-bg {
		border-radius: 100px;
		background: rgba(255, 255, 255, 0.1);
		border-radius: 100px;
		height: 12px;
		position: relative;
		margin-top: 13px;
		width: 185px;
		float: left;

		@media screen and (max-width: 1100px) {
			width: 135px; } }

	.goal-complete {
		background: #99C700;
		border-radius: 100px;
		height: 12px; }

	.goal-percent {
		font-size: 14px;
		float: left;
		margin-top: 9px;
		margin-left: 7px; }

	.section-name {
		height: 48px;
		line-height: 48px;
		font-size: 14px;
		text-transform: uppercase;
		position: relative;

		img {
			position: absolute;
			right: 10px;
			top: 18px;
			transform: rotate(0deg);
			transition: 1.5s transform cubic-bezier(0, 1, 0, 1); } }

	.accounts-scroll {
		height: 450px !important; }

	.accounts-list, .cards-list {
		max-height: 48px;
		overflow: hidden;
		transition: 1.5s max-height cubic-bezier(0, 1, 0, 1);
		cursor: pointer;
		padding: 0 !important;

		.section-name {
			padding: 0 24px;

			@media screen and (max-width:1100px) {
				padding: 0 12px; } }

		.accounts .cssload-loader {
			position: relative;
			top: 10px;
			z-index: 3;

			.cssload-dot {
				background: #fff; } } }

	.opened {
		max-height: 1000px;
		transition: 1.5s max-height cubic-bezier(0, 1, 0, 1);

		img {
			transform: rotate(90deg);
			transition: 1.5s transform cubic-bezier(0, 1, 0, 1); } }

	& .card p, & .account p {
		-ms-text-overflow: ellipsis;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		width: 180px;

		@media screen and (max-width:1100px) {
			width: 136px; } }

	.card {
		position: relative;
		border-bottom: 1px solid #000;
		padding-top: 16px;
		background: url(img/visa-icon.png) no-repeat right;
		height: 80px; }

	.card .card-type {
		font-size: 14px; }

	.card .card-number {
		font-size: 16px; }

	.card a {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0; }

	.account {
		position: relative;
		border-bottom: 1px solid rgba(255, 255, 255, 0.16);
		padding-top: 14px;
		height: 80px;
		width: 232px;
		margin: 0 auto;
		background-position: center right !important;
		background-repeat: no-repeat !important;

		@media screen and (max-width:1100px) {
			width: 206px; }

		a {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0; }

		.account-sum {
			font-size: 24px;
			float: left;
			max-width: 118px;
			-ms-text-overflow: ellipsis;
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;

			@media screen and (max-width:1100px) {
				max-width: 85px !important;
				font-size: 20px !important; } }

		.currency-code {
			font-size: 24px;
			margin-left: 5px;

			@media screen and (max-width:1100px) {
				font-size: 20px !important; } }

		.account-type {
			font-size: 14px;
			clear: both; } } }

.calculator-parameters {
	max-width: 960px;
	margin: 0 auto;
	background: #fff;
	margin-top: 64px;

	&>div:not(.header) {
		max-width: 640px !important;
		margin: 0 auto;

		.header {
			background: #303030;
			height: 96px;
			color: #fff;
			font-size: 20px;

			@media screen and (max-width:400px) {
				font-size: 17px;

				& > div {
					max-width: 640px;
					margin: 0 auto;
					line-height: 96px; }
				.name {
					float: left; }

				.steps {
					float: right; } }

			.parameters {
				padding-top: 64px;

				.switchers {

					p {
						font-size: 20px;

						@media screen and (max-width:820px) {
							text-align: center; } }

					.switchers-list {
						min-height: 64px;
						position: relative;
						margin-top: 32px;
						margin-bottom: 32px;

						a {
							height: 64px;
							line-height: 64px;
							border: 1px solid rgba(51,51,51,0.42);
							border-radius: 2px;
							font-size: 16px;
							color: #333333;
							width: 192px;
							display: block;
							padding-left: 24px;
							position: relative;

							@media screen and (max-width:820px) {
								float: none !important;
								margin: 15px auto; }

							.indicator-bg {
								background: #303030;
								border-radius: 2px;
								position: absolute;
								padding: 4px 16px;
								right: 16px;
								top: 15px;
								bottom: 15px;
								margin: auto;

								.indicator {
									background: #99C700;
									width: 12px;
									height: 12px;
									border-radius: 6px;
									position: absolute;
									top: 0;
									bottom: 0;
									left: 0;
									right: 0;
									margin: auto;
									display: none; } } }
						a.active {
							border: 1px solid #99C700;

							.indicator {
								display: block; } }

						.rub {
							float: left; }

						.usd {
							position: absolute;
							left: 0;
							right: 0;
							margin: auto;

							@media screen and (max-width:820px) {
								position: relative;
								margin: 15px auto; } }

						.eu {
							float: right; } } }

				.page2 {
					min-height: 1000px; }

				input[type="text"] {
					width: 100%;
					margin-bottom: 32px;

					@media screen and (max-width:590px) {
						float: none !important; } }

				.half-size {
					@media screen and (min-width:590px) {
						width: 48% !important; } }

				.left {
					float: left; }

				.right {
					float: right; }

				label {
					font-size: 20px;
					margin-top: 38px;
					margin-bottom: 38px;
					clear: both;
					display: block; }

				.slider1, .slider2, .per-month-payment, .credit-info {
					width: 48%;
					p {
						margin-bottom: 16px; }

					@media screen and (max-width:850px) {
						width: 100%;
						margin-top: 40px; }

					.value {
						font-size: 26px;
						margin-bottom: 12px;
						margin-top: 12px;
						word-wrap: break-word; }

					.check-box {
						border-radius: 4px;
						background: #99C700;
						padding: 8px 16px;
						margin-right: 10px; }

					.check-box.checked {
						background: #99C700 url(img/checked.png) no-repeat center; } }

				.slider1, .per-month-payment {
					float: left;

					@media screen and (max-width:850px) {
						float: none; } }

				.slider2, .credit-info {
					float: right;

					@media screen and (max-width:850px) {
						float: none; } }

				.per-month-payment {
					margin-top: 40px;

					.value {
						color: #99C700;
						word-wrap: break-word; } }

				.credit-info {
					margin-top: 40px;
					font-size: 14px; } }

			.buttons {
				min-height: 128px;
				padding-top: 32px;

				.forward, .send, .go-to-request {
					float: right;
					@media screen and (max-width:590px) {
						float: none;
						margin: 10px auto; } }

				.back, .show-calculators {
					float: left;
					@media screen and (max-width:590px) {
						float: none;
						margin: 10px auto; } } } } } }

.currencies {
	position: absolute;
	right: 0;
	top: 24px;
	height: 30px;
	transition: height 0.5s;
	overflow: hidden;
	padding-left: 0;
	border: 1px solid #E1E1E1;

	@media (max-width: 992px) {
		right: 15px; }

	li {
		height: 30px;
		width: 79px;
		background: #fff;
		line-height: 30px;
		padding: 0 10px;
		cursor: pointer;
		color: #9a9a9b;
		padding-right: 30px;
		transition: 0.3s all;

		&::after {
			content: '';
			position: absolute;
			width: 1px;
			top: 0;
			bottom: 0;
			right: 29px;
			background: #E1E1E1; }

		&:hover::before {
			border-bottom-color: $prime-color;
			border-right-color: $prime-color;
			opacity: 1; }

		&::before {
			content: '';
			position: absolute;
			top: 9px;
			right: 11px;
			transform: rotate(45deg);
			border: 4px solid #b8b8b8;
			border-top-color: transparent;
			border-left-color: transparent;
			opacity: 0.4; } }

	&.opened {
		height: auto;
		transition: height 0.5s;
		box-shadow: 1px 1px 5px #ddd;

		li {
			padding-right: 10px;
			text-align: center;

			&:hover {
				color: $prime-color; }

			&::after,
			&::before {
				display: none; } } } }

.result-table {
	padding-top: 64px;
	padding-bottom: 64px;

	.px20-semipale-text {
		text-align: center;
		margin-top: 16px;
		margin-bottom: 64px; }

	.head {
		background: #ECEFF1;
		padding-top: 18px;
		height: 56px; }

	.error {
		text-align: center; }
	.result-list {
		.row {
			padding-left: 24px;
			padding-right: 24px; }

		img {
			float: left;
			margin-right: 20px; }

		.item {
			padding-top: 24px;
			min-height: 100px;
			background: #fff;
			border: 1px solid #ECEFF1;

			@media screen and (max-width:1000px) {
				min-height: 120px;
				padding-top: 35px; }

			@media screen and (max-width:767px) {
				padding-bottom: 35px; }

			.percent, .limit {
				height: 48px;
				line-height: 48px; }

			.btn-middle-fill-green {
				float: right; }

			.limit {
				float: left; }

			& > div {
				p {
					clear: both;
					word-wrap: break-word; }
				@media screen and (max-width:767px) {
					margin-top: 15px;

					.btn-middle-fill-green, .limit {
						float: none;
						margin-top: 10px; }

					.title {
						font-size: 20px;
						margin-bottom: 10px; }

					p {
						height: auto !important;
						line-height: auto !important; } } } } } }

.main-container {
	padding: 0;
	position: relative;
	display: flex;
	flex-direction: column;
	min-height: 100vh;

	.row-content {
		flex-grow: 1; }

	.navbar-nav {

		margin: 0; }

	.navbar-collapse {
		padding: 0;

		.menu {
			margin-bottom: 0;
			margin-top: 0;
			float: left;
			width: 60%;
			min-height: 100px;
			padding-left: 40px;

			@media screen and (max-width:1215px) {
				width: 65%; }

			@media screen and (max-width:1150px) {
				float: none;
				width: 100%;
				padding-right: 40px; }

			@media screen and (max-width:1150px) {
				float: none;
				width: 100%;
				padding-left: 15px;
				padding-right: 15px; }

			@media (max-width:768px) {
				display: flex; }

			li:not(.language) {
				float: left;
				// @media screen and (max-width:768px)
 }				// 	float: right

			li.logo {
				@media (max-width:768px) {
					flex-grow: 1;
 } }					// float: left
			li > a, li > button {
				color: #000 !important;

				&:hover,
				&:active,
				&:visited:hover,
				&:visited:active {
					color: $button-blue !important; } }

			& > li:not(.language) > a, & > li:not(.language) > button {
				position: relative;
				display: block;
				padding: 38px 15px;

				@media screen and (max-width:1400px) {
					padding: 38px 10px !important; }


				@media screen and (max-width:1300px) {
					padding: 38px 5px !important;
 }					// font-size: 11px

				@media screen and (max-width:768px) {
					background: url(img/mobile-menu2.png) no-repeat;
					width: 50px; }
				@media screen and (max-width: 375px) {
					width: 45px; }
				@media screen and (max-width: 360px) {
					width: 40px; } }

			.wallets {
				background-position: 15px 35px !important; }

			.payments {
				background-position: -34px 35px !important; }

			.support {
				background-position: -80px 35px !important; }

			.exchange {
				background-position: -116px 35px !important; }

			.language {
				@media screen and (max-width:1350px) {
					margin-right: 10px;
					width: 62px; }

				@media screen and (max-width:1150px) {
					margin-right: 0; }

				ul {
					background: #fff; }

				li {
					@media screen and (max-width:1350px) {
						padding: 10px !important; } } } }

		.personal-info {
			float: right;
			// background: #4640ca
			background: $prime-color;
			padding-right: 40px;
			//width: 40%
			min-height: 100px;
			display: flex;
			justify-content: space-between;

			@media screen and (max-width:1200px) {
 }				//width: 42%

			@media screen and (max-width:1150px) {
				float: none;
				width: 100%;
				padding-left: 40px; }

			// @media screen and (max-width: 1024px)
			// 	flex-direction: row-reverse

			@media screen and (max-width: 768px) {
				padding: 5px 0; }

			@media (max-width: 450px) {
				flex-direction: column; }

			.balances {
				overflow: hidden;
				display: flex;
				float: left;
				min-height: 96px;
				line-height: 36px;
				padding-right: 52px;
				padding-left: 30px;
				border-right: 1px solid rgba(255, 255, 255, 0.1);

				.balance-list {
					align-self: center;
					margin: 0 auto;
					color: rgba(0,0,0,.4); }

				@media screen and (max-width:1400px) {
					padding-left: 20px;
					padding-right: 20px; }

				@media screen and (max-width:1150px) {
					padding-left: 0; }

				@media screen and (max-width:768px) {
					float: right;
					padding: 0 10px; }

				@media (max-width: 450px) {
					min-height: auto;
					text-align: center; }

				.sum {
					float: left;
					padding: 0 5px;
					color: #fff;
					font-size: 18px;

					@media screen and (max-width: 768px) {
						font-size: 16px;
						line-height: 33px; }

					@media (max-width: 500px) {
						float: none;
						font-size: 14px;
						display: inline-block; }

					@media (max-width: 400px) {
						font-size: 12px; } } }

			.user-account-menu {
				height: 96px;
				padding-left: 30px;
				display: flex;

				@media screen and (max-width:1350px) {
					padding-left: 20px; }

				@media screen and (max-width:768px) {
					float: left;
					//width: 50%
					padding: 0 10px; }

				.user-account {
					height: 96px;
					width: 96px;
					display: flex;
					width: 100%;

					.user-icon {
						display: inline-block;
						background: url(img/user-icon.png) no-repeat center;
						width: 50px;
						height: 50px;
						border-radius: 50%;
						align-self: center;
						flex-shrink: 0; }

					.user-name {
						color: #fff;
						margin-left: 10px;
						align-self: center; } }

				.exit {
					padding-top: 28px;
					margin-left: 40px;
					float: right;

					@media screen and (max-width:1350px) {
						margin-left: 20px; }

					button {
						font-size: 12px;
						color: #FFFFFF !important;
						letter-spacing: 1.2px;
						height: 42px;
						// line-height: 42px
						text-align: center;
						padding: 10px;
						display: block; } } } } }

	.navbar.opened {

		@media screen and (max-width:767px) {
			li > a {

				font-size: 17px !important; }

			.exit {
				padding-top: 0;
				a {

					height: 60px;
					line-height: 60px;
					width: auto !important;
					font-size: 17px;
					text-transform: uppercase; } }

			.exit {
				float: none !important; } } }

	.row {
		margin: 0; }

	.popup {
		width: 500px;
		background: #fff;
		border-radius: 10px;
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		padding: 50px;
		margin: auto;
		z-index: 16;
		box-shadow: 0 30px 40px 0 rgba(0,0,0,0.35);

		input {
			width: 100%;
			margin-bottom: 15px; }

		& > button {
			margin: 20px auto;

			@media screen and (max-width: 240px) {
				width: 100%; } }

		.close-popup {
			position: absolute;
			top: 15px;
			right: 15px;
			background: url(/img/close-icon.png) no-repeat center;
			display: block;
			height: 24px;
			width: 24px;
			margin: 0; }

		.success {
			text-align: center;
			font-size: 20px; }

		@media screen and (max-width: 500px) {
			width: 100%;
			padding: 10px;
			padding-top: 50px; } }

	.popup-v2 {
		width: 500px;
		background: #fff;
		padding: 50px;
		margin: auto;
		z-index: 4;
		border-radius: 10px;

		input {
			width: 100%;
			margin-bottom: 15px; }

		& > button {
			margin: 20px auto;

			@media screen and (max-width: 240px) {
				width: 100%; } }

		.close-popup {
			position: absolute;
			top: 15px;
			right: 15px;
			background: url(/img/close-icon.png) no-repeat center;
			display: block;
			height: 24px;
			width: 24px;
			margin: 0; }

		.success {
			text-align: center;
			font-size: 20px; }

		@media screen and (max-width: 500px) {
			width: 100%;
			padding: 10px;
			padding-top: 50px; } }

	.goal-popup {
		height: 410px;

		input {
			background: rgb(236, 239, 243) !important; }

		.account-list {
			border-radius: 2px;
			font-size: 16px;
			overflow: hidden;
			background: #eceff3 url(img/green-arrow-down.png) no-repeat 370px 25px;
			position: relative;
			min-height: 64px;

			ul {
				border-radius: 2px;
				padding: 0;

				li {
					height: 64px;
					line-height: 64px;
					color: rgba(51, 51, 51, 0.4);
					padding-left: 20px;
					width: 100%;
					cursor: pointer;
					text-overflow: ellipsis;
					white-space: nowrap;
					overflow: hidden; }

				li.account:hover {
					border: 1px solid #99C700; } } }

		.account-list.closed {
			max-height: 64px;
			transition: 1s max-height cubic-bezier(0, 1, 0, 1); }

		.account-list.opened {
			max-height: 1000px;
			transition: 1s max-height; }

		.btn-big-fill-green {
			position: absolute;
			left: 0;
			right: 0;
			bottom: 20px;
			z-index: -1; }

		.scrollarea {
			max-height: 300px; } }

	.goal-popup.success {
		height: 250px; }

	.content {
		color: #333333;
		background: #f2f3f8;
		// margin-top: 100px
		padding-bottom: 20px;
		min-height: calc(100vh - 220px);


		// @media screen and (max-width:1150px)
		// 	margin-top: 200px
		//
		// @media (max-width: 400px)
		// 	margin-top: 242px

		h1 {
			font-size: 36px;
			text-align: center; }

		.choices-list {
			margin: 0 auto;
			max-width: 960px;
			padding-top: 64px;
			padding-bottom: 64px;
			float: none;
			min-height: 368px;

			.no-operators-error {
				text-align: center; }

			.item {
				background: #fff;
				border: 1px solid #E1E1E1;
				max-height: 240px;
				position: relative;
				text-align: center;
				padding: 0;
				float: left;

				@media screen and (max-width:767px) {
					max-height: auto;
					height: 240px; } }

			.item:hover {
				box-shadow: 0 0 16px 0 rgba(0,0,0,0.16), 0 16px 20px 0 rgba(0,0,0,0.24); }

			.item a {
				display: block;
				height: 100%;
				width: 100%;
				margin: 0 auto;
				position: absolute;
				top: 0;
				left: 0;
				z-index: 1; }

			.item img {
				position: absolute;
				top: 20%;
				left: 0;
				right: 0;
				margin: auto; }

			.item p {
				text-align: center;
				color: #000;
				position: absolute;
				bottom: 40px;
				left: 0;
				right: 0;
				margin: auto;

				@media screen and (max-width:1100px) {
					bottom: 20px; } }

			@media screen and (min-width:767px) {
				.item:before {
					padding-top: 100%;
					content: "";
					display: block; } } }

		.banner {
			max-width: 960px;
			margin: 0 auto;

			img {
				width: 100%;
				height: auto; } }

		.help-component {
			padding-top: 64px;
			min-height: 584px;

			.px20-semipale-text {
				text-align: center;
				margin-top: 16px;
				margin-bottom: 64px; } }

		.popular-offers {
			padding-top: 64px;
			color: #333333;
			min-height: 728px;
			text-align: center;

			h1 {
				text-align: center;
				font-size: 36px; }

			& > div > p {
				padding-top: 16px;
				opacity: 0.42;
				font-size: 20px;
				text-align: center; }

			.row {
				position: relative;
				height: 600px;
				overflow: hidden;

				@media screen and (max-width:1257px) {
					width: 720px; }

				@media screen and (max-width:1017px) {
					width: 480px; }

				@media screen and (max-width:572px) {
					width: 240px;
					height: 645px; }

				.forward {
					margin-top: 500px;
					display: block; }

				.cards-list {
					padding-top: 64px;
					position: absolute;
					width: 1200px;

					.card {
						height: 384px;
						padding-top: 43px;
						background: #FFFFFF;
						border: 1px solid #ECEFF1;
						width: 240px;
						float: left;
						cursor: pointer;

						img {
							display: block;
							margin: 0 auto; }

						p {
							text-align: center; }

						.card-name {
							font-size: 20px;
							margin-top: 20px; }

						.bank-name {
							margin-top: 10px; }

						.description {
							font-size: 14px;
							margin-top: 72px; }

						a {
							display: block;
							width: 85%;
							height: 40px;
							margin: 24px auto 0;
							border: 1px solid #CCCCCC;
							border-radius: 4px;
							font-size: 12px;
							color: #464646;
							letter-spacing: 1.2px;
							text-transform: uppercase;
							line-height: 40px;
							text-align: center; }

						&:hover {
							border: 1px solid #99C700;
							box-shadow: 0 0 16px 0 rgba(0,0,0,0.08), 0 16px 16px 0 rgba(0,0,0,0.12);

							a {
								background: #99C700;
								color: #FFFFFF; } } } }

				.switchers {
					height: 20px;
					padding-top: 6px;
					margin: 470px auto 0 auto;
					display: inline-block;

					@media screen and (max-width:572px) {
						margin: 450px auto 0 auto; }

					.switcher {
						height: 8px;
						width: 8px;
						border: 1px solid #000;
						border-radius: 4px;
						display: block;
						float: left;
						margin-right: 5px; }

					.active {
						background: #000; } } } }

		.acc-card-menu {
			background: #FFFFFF;
			box-shadow: 0 1px 0 0 rgba(51,51,51,0.12);

			ul {
				margin: 0;
				min-height: 80px;
				line-height: 80px; }

			li {
				float: left;
				margin-right: 32px;
				font-size: 14px;
				text-transform: uppercase; }

			li a {
				color: rgba(51, 51, 51, 0.36); }

			li a.active {
				color: #333333; }

			@media screen and (max-width:767px) {
				li {
					float: none;
					text-align: center;
					margin-top: 10px; } } }

		& > div > div {
			max-width: 960px;
			margin: 0 auto; }

		.promo-block {
			@media screen and (max-width:767px) {
				margin-top: 50px;

				text-align: center; }

			.payment-example {
				margin-top: 88px;
				text-align: center;

				.name {
					font-size: 20px;
					margin-top: 24px;
					margin-bottom: 16px; }

				a {
					margin: 16px auto;

					@media screen and (max-width:1100px) and (min-width: 768px) {
						width: 100%; } } }

			& > img {
				@media screen and (max-width:1100px) and (min-width: 768px) {
					width: 100%;
					height: auto; } } }

		.check {
			width: 26px;
			height: 26px;
			display: block;
			float: left;
			border: 2px solid #303030;
			border-radius: 13px;
			position: absolute;
			top: 4px;
			left: -20px;

			span {
				background: #4a98ce;
				width: 12px;
				height: 12px;
				border-radius: 6px;
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				margin: auto;
				display: none; } }

		.check.active span {
			display: block; } }

	.main-page {
		.main-page-header {
			height: 144px;
			min-height: auto;
			padding-top: 44px;

			img {
				@media screen and (max-width:350px) {
					display: none; } } }

		h1 {
			margin-bottom: 60px;
			text-align: left;
			@media screen and (max-width: 768px) {
				margin: 20px 0; } }

		.accounts {
			padding: 64px 25px 64px 40px;
			position: relative;
			@media (max-width: 1200px) {
				width: 100%;
				max-width: 100%; }

			@media screen and (max-width: 768px) {
				padding: 20px 20px 0 20px !important; }
			&__hide-zero-balances {
				flex: 1;
				text-align: right;
				user-select: none;
				color: $prime-color;

				@media screen and (max-width: 540px) {
					margin: 10px 0; } }
			&__header {
				display: flex;
				& h1 {
					align-self: baseline; }
				&>div {
					align-self: baseline; }
				@media screen and (max-width: 540px) {
					flex-direction: column; } }
			.account {
				position: relative;
				padding-top: 16px;
				padding-left: 70px;
				width: 90%;
				margin: 15px 0;
				background: #fff;
				padding-bottom: 16px;
				min-height: 142px;
				padding-right: 25px;
				background-position: 10px 25px !important;
				background-repeat: no-repeat !important;

				@media screen and (max-width:1200px) {
					width: 100%; }

				.acc-info {
					p {
						color: #333333; }

					.title {
						color: #cccc33;
						font-size: 18px; }

					.middle {
						height: 40px;
						line-height: 40px;

						.sum {
							font-size: 24px;
							display: inline-block;
							line-height: 20px;

							@media screen and (max-width:1500px) {
								font-size: 20px; }

							@media screen and (max-width:1400px) {
								font-size: 18px; }

							@media screen and (max-width:1300px) {
								font-size: 16px;
								height: 26px; } } }

					.currency-price {
						position: relative;
						margin-top: 30px;
						margin-left: -55px;

						@media (max-width: 499px) {
							padding-left: 26px;
 }							// margin-left: -60px

						span {
							margin-left: 25px;
							top: -2.5em;

							@media (max-width: 499px) {
								margin-left: 0; } }

						.green {
							color: #ccd520;
							@media (max-width: 499px) {
								margin-left: 10px; } }

						.red {
							color: #ff7049;
							@media (max-width: 499px) {
								margin-left: 10px; } }

						img {
							position: absolute;
							top: 0;
							left: 0;
							bottom: 0;
							margin: auto; }

						.value {
							height: 36px;
							font-size: 16px;
							// margin-left: 25px

							@media screen and (max-width:1500px) {
								font-size: 20px; }

							@media screen and (max-width:1400px) {
								font-size: 18px; }

							@media screen and (max-width:1300px) {
								font-size: 16px; } } } } } }

		.wallet-events {
			&--loading {
				margin: 20px 0;
				min-height: 640px;
				background: linear-gradient(white, white 200px, transparent 200px, transparent 220px, white 220px, white 420px, transparent 420px, transparent 440px, white 440px, white 640px);
				opacity: 0.7;
 }				// animation: 1.5s blinking ease-out infinite

			& > div {
				background: #fff;
				width: 99%;
				// min-height: 106px
				margin-bottom: 10px;
				// padding: 26px 35px

				.wallet-events-date {
					transform: rotate(-90deg);
					width: 86px;
					float: left;
					position: absolute;
					left: -12px; }

				.wallet-events-type {
					float: left;
					font-weight: 900;
					font-size: 16px; }

				.income {
					color: #e3d55c; }

				.outcome {
					color: #ff7044; }

				.wallet-events-value {
					float: right; }

				.wallet-events-source {
					clear: both;
					float: left;
					margin-top: 10px;
					opacity: 0.5; }

				.wallet-events-sum {
					float: right;
					margin-top: 10px;
					opacity: 0.5; }

				@media screen and (max-width:1200px) {
					width: 100%; } }
			&__payments {
				padding-top: 20px;
				flex: none; } }

		.jetiks {
			float: right;
			width: 30%;
			padding-top: 233px;
			position: relative;
			height: 800px;

			@media screen and (max-width:1500px) {
				width: 33%; }

			@media screen and (max-width:1400px) {
				width: 38%; }

			@media screen and (max-width:1200px) {
				width: 45%; }

			@media screen and (max-width:1100px) {
				display: none; }

			p {
				background: url(img/jetiks-text-bg.png) no-repeat center;
				padding: 60px 35px;
				font-size: 18px;
				width: 260px;
				text-align: center;
				background-size: contain; }

			img {
				position: absolute;
				top: 450px;
				left: 140px; } }

		.choices-list {
			min-height: 368px; }


		.functions {
			min-height: 664px;
			padding-top: 64px;
			color: #333333;

			h1 {
				text-align: center;
				font-size: 36px; }

			&>div>p {
				text-align: center;
				opacity: 0.42;
				font-size: 20px;
				margin-top: 16px; }

			.functions-list {
				margin-top: 64px;

				.item {
					background: url(img/gibdd-fine.png) no-repeat left top;
					padding-top: 12px;
					padding-bottom: 12px;
					margin-bottom: 40px;
					padding-left: 100px;
					min-height: 120px;

					@media screen and (max-width:900px) {
						min-height: 150px; }

					.title {
						font-size: 20px; }

					.description {
						text-align: left;
						margin-top: 8px; } } } } }

	.payments-page {
		color: #333333;
		padding-bottom: 100px;

		.buttons {
			text-align: center; }

		.popup {
			display: flex;
			flex-direction: column;
			justify-content: space-around; }

		.popup p {
			font-size: 22px; }

		.left-column {
			float: left;
			padding: 40px;
			width: 100%;

			@media screen and (max-width:1100px) {
				width: 100%; }
			@media screen and (max-width:768px) {
				padding: 0 15px; } }

		.most-popular-payments {
			min-height: 440px;
			padding-top: 25px;
			padding-bottom: 25px;
			background: #fff;
			padding-left: 35px;
			margin-top: 45px;

			h1 {
				font-size: 23px;
				margin-bottom: 64px;
				text-align: left; }

			.item {
				padding-top: 25px;
				padding-bottom: 12px;
				margin-bottom: 40px;
				padding-left: 100px;
				height: 130px;
				position: relative;
				background: #f9fafc;

				.name {
					font-size: 18px;
					color: #cccc33; }

				.description {
					margin-top: 8px;
					text-align: left;
					font-size: 30px; }

				.image {
					position: absolute;
					left: 0;
					width: 80px;
					height: 80px;
					border-radius: 20px;
					overflow: hidden;

					img {
						width: 100%;
						height: 100%; } }

				.open-auto-payment {
					position: absolute;
					left: 0;
					width: 100%;
					height: 100%;
					top: 0; }

				.remove-auto-payment {
					position: absolute;
					bottom: 15px;
					left: 100px;
					display: none;
					z-index: 2;

					span {
						border-bottom: 1px dotted #428bca;

						&:hover {
							color: #EF3124;
							border-bottom: 1px dotted #EF3124; } } }

				&:hover {
					.remove-auto-payment {
						display: block; } } }

			.scrollarea {
				max-height: 360px;
				padding-right: 35px; }

			.add-payment {
				background: url(img/add-auto-payment.png) no-repeat left center;
				height: 120px;
				line-height: 120px;
				padding-left: 100px;
				position: relative;

				p {
					font-size: 20px; }

				a {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%; } } }

		.payments-title {
			text-align: left;
			font-size: 23px;
			height: 78px;
			line-height: 78px;
			padding-left: 35px;
			border-bottom: 1px solid #ededed;

			@media screen and (max-width: 450px) {
				font-size: 19px; } }

		.payment-templates {
			min-height: 288px;
			background: #fff;
			margin-top: 50px;

			.item {
				min-height: 288px;
				width: 48%;
				background: #fff;
				padding: 32px 35px 24px 35px;
				position: relative;
				padding-bottom: 60px;

				@media screen and (max-width:850px) {
					float: none;
					width: 100%; }

				.name {
					font-size: 26px; }

				.description {
					font-size: 16px;
					margin-top: 16px;
					margin-bottom: 48px;
					width: 50%; }

				input {
					background: #ECEFF1 url(img/payment-template-arrow.png) no-repeat right center;
					border-radius: 2px;
					font-size: 16px;
					color: rgba(51, 51, 51, 0.42);
					line-height: 55px;
					height: 55px;
					padding-left: 20px;
					border: none;
					width: 100%;
					padding-right: 77px; }
				img {
					position: absolute;
					top: 32px;
					right: 24px; } }

			.item1 {
				float: left;
				border-right: 1px solid #ededed;

				@media screen and (max-width:1300px) {
					float: none;
					width: 100%;
					border-right: none;
					border-bottom: 1px solid #ededed; } }

			.item2 {
				float: right;

				@media screen and (max-width:1300px) {
					float: none;
					width: 100%; } } }

		.choices-list {

			.row.extra-payment-types {
				max-height: 200000px;
				transition: max-height .3s;
				overflow: hidden; }

			.row.extra-payment-types.hidden-element {
				max-height: 0px;
				transition: max-height .3s; }

			.show-all-payment-types {
				border: 1px solid #333333;
				border-radius: 4px;
				font-size: 14px;
				color: #333333;
				display: block;
				height: 48px;
				line-height: 48px;
				margin: 40px auto;
				clear: both;
				width: 160px;
				text-align: center;

				&:hover {
					color: #99C700;
					border-color: #99C700; } } }

		.transfer {
			padding-top: 26px;
			padding-bottom: 26px;
			background: #fff;
			margin-top: 50px;
			position: relative;

			$padding: 0 35px;

			.blur::before {
				content: "";
				background: rgba(255,255,255,0.8);
				position: fixed;
				width: 100%;
				height: 100%;
				z-index: 50; }

			.transfer__title {
				font-size: 23px;
				text-align: left;
				margin-bottom: 25px;
				padding: $padding;
				font-weight: 700; }

			.account-container {
				display: inline-block;
				vertical-align: top;
				width: 50%;
				padding: $padding;
				@media screen and (max-width: 768px) {
					width: 100%; }

				@media (max-width: 400px) {
					padding: 0 10px; }

				&:first-of-type {
					border-right: 1px solid #f7f7f7;
					width: calc(50% - 1px);
					@media screen and (max-width: 768px) {
						width: 100%; } } }

			.account-container__field-descripion {
				display: flex;
				justify-content: space-between;
				margin-bottom: 10px;
				color: #9a9a9b; }

			.account-container__available {
				white-space: nowrap;
				margin-left: 18px;

				&.overflow {
					color: rgb(221, 86, 86); } }

			.account-container__amount-container {
				position: relative; }

			.account-container__max-button {
				position: absolute;
				top: 50%;
				right: 16px;
				transform: translateY(-50%);
				outline: none;
				border: none;
				border-bottom: 1px dotted #030304;
				background: none;
				font-size: 18px;
				padding: 0;
				color: #030304;
				opacity: 0.4;

				&:hover {
					color: $button-orange;
					opacity: 1;
					border-color: $button-orange; } }

			.account-list {
				border-radius: 2px;
				font-size: 16px;
				background: #f9fafc;
				position: relative;
				min-height: 64px;
				z-index: 1;

				input {
					width: 100%;
					cursor: pointer;
					background: #f9fafc;
					&:focus, &:active {
						border-color: rgba(0, 0, 0, 0.2); } }



				.current-sender-account, .current-receiver-account, input[type="text"] {
					height: 64px;
					line-height: 64px;
					padding-left: 20px;
					position: relative; }

				.clear-current-acc {
					transform: rotate(45deg);
					position: absolute;
					display: block;
					top: 0;
					bottom: 0;
					right: 20px;
					color: rgba(51, 51, 51, 0.4);
					font-size: 36px; }

				&.opened ul {
					display: block;
					position: absolute;
					z-index: 10; }

				ul {
					display: none;
					border-radius: 2px;
					width: 100%;
					margin-bottom: 0;
					background: #f9fafc;
					padding: 0;
					box-shadow: 2px 2px 6px #aaa;


					li {
						min-height: 64px;
						color: rgba(51, 51, 51, 0.4);
						padding-left: 20px;
						width: 100%;
						cursor: pointer;
						display: flex;
						text-overflow: ellipsis;
						align-items: center;
						padding: 10px 20px; }

					li.account:hover {
						background: lighten($prime-color, 20%);
						border: 1px solid lighten($prime-color, 20%);
						color: white; } } }

			.closed {
				max-height: 64px;
				transition: 1s max-height cubic-bezier(0, 1, 0, 1); }

			.opened {
				max-height: 1000px;
				transition: 1s max-height; }

			.sender-account, .receiver-account {
				position: relative;
				min-height: 64px;
				clear: both; }
			.receiver-account {
				@media screen and (max-width: 768px) {
					padding-top: 20px;
					border-top: 1px solid rgba(0,0,0,.1); } }
			.sender-account {
				z-index: 2; }

			.account-payment-amount {
				width: 100%;
				background: #f9fafc;
				&:focus, &:active {
					border-color: rgba(0, 0, 0, 0.2); }
				&.overflow {
					background: rgba(221, 86, 86, 0.4);
					transition: 0.4s all; } }

			.write-off-amount {
				height: 20px;
				font-size: 12px;
				margin-top: 5px !important;
				padding-left: 20px;
				margin-bottom: 10px !important; }

			.confirmation {
				min-height: 64px;
				clear: both;
				padding: $padding;
				padding-top: 10px;
				border-top: 1px solid #ebebeb;

				label {
					font-weight: 400; }

				.checkbox-area {
					position: relative;
					width: 24px;
					height: 24px;
					margin-top: 4px;
					float: left;

					input[type="checkbox"] {
						width: 24px;
						height: 24px;
						position: absolute;
						left: 0;
						margin: 0;
						opacity: 0;
						cursor: pointer; }

					input[type="checkbox"] + label {
						background: #f1f3f9;
						border-radius: 4px;
						height: 24px;
						width: 24px; }


					input[type="checkbox"]:checked + label {
						background: $prime-color url(img/checked.png) no-repeat center; } }

				.confirmation-text {
					float: left;
					font-size: 16px;
					margin-left: 16px;
					// height: 32px
					line-height: 32px;
					width: calc(100% - 40px); }


				.field-error {
					float: none;
					clear: both;
					padding-left: 60px;
					text-align: left; } }

			.btn {
				// background: #352ddb
				// background: $prime-color
				width: 150px;
				height: 44px;
				// line-height: 44px
				line-height: 34px;
				display: block;
				// text-align: center
				// color: #fff
				// font-size: 18px
				border-radius: 20px;
				margin-left: 35px; }

			// .cssload-loader
			// 	bottom: 64px
 }			// 	top: unset

		.remove-auto-payment-popup {
			height: 270px;

			a {
				width: 40%;
				margin: 5px;
				padding: 10px; }

			.apply {
				float: left; }


			.cancel {
				float: right; }

			.buttons {
				text-align: center; }

			p {
				text-align: center;
				margin-bottom: 35px; } }

		.remove-auto-payment-success-popup {
			height: 300px;

			p {
				text-align: center;
				margin-bottom: 35px; }

			a {
				margin: auto; } }

		.confirm-payment-popup {
			height: 300px;
			justify-content: space-between;

			p {
				text-align: center;
				margin-bottom: 20px;
				font-size: 30px;
				margin-top: 20px; }

			.btn {
				margin-bottom: 0; } }

		.jetiks {
			float: right;
			width: 45%;
			padding-left: 100px;
			position: relative;
			height: 800px;

			@media screen and (max-width:1220px) {
				padding-left: 10px; }

			@media screen and (max-width:1100px) {
				display: none; }

			p {
				background: url(img/jetiks-text-bg.png) no-repeat center;
				padding: 60px 50px 60px 50px;
				font-size: 18px;
				width: 260px;
				text-align: center;
				background-size: contain;
				margin-top: 50px; }

			img {
				position: absolute;
				top: 250px;
				left: 300px;

				@media screen and (max-width:1220px) {
					left: 110px; } } } }

	.add-auto-payment {
		.content-header {
			margin-bottom: 64px;

			@media screen and (max-width:600px) {

				min-height: 220px;

				h1 {
					text-align: center !important;
					font-size: 36px !important;
					width: 100%; }

				img {
					display: none; } }

			@media screen and (max-width:960px) {
				img {
					position: auto; } } } }

	.choose-page {

		.calculator {
			padding-top: 64px;

			h1 {
				margin-bottom: 16px; } }

		.content-header img {
			@media screen and (max-width:550px) {
				display: none; } } }

	.providers {

		.content-header img {
			@media screen and (max-width:550px) {
				display: none; } }

		.search {
			margin-bottom: 64px; }

		.no-operators-error {
			text-align: center; } }

	.card-info {
		.head {
			padding-top: 100px;
			min-height: 244px;
			background: #fff;

			.card-name {
				margin-bottom: 16px;
				font-size: 56px; } }

		.banner {
			background: url(img/banner3.png) no-repeat center;
			background-size: cover;
			padding-top: 186px;
			height: 620px;
			max-width: none;

			p {
				font-size: 36px;
				width: 30%; } }

		.investment-conditions {
			min-height: 1112px;

			&>div {
				background: #fff;
				padding-top: 64px;
				min-height: 1048px; }

			.title {
				font-size: 26px;
				margin-bottom: 40px; }

			.data {
				.item {
					height: 48px;
					line-height: 48px;
					clear: both;

					.px16-semipale-text {
						float: left; }
					.value {
						font-size: 16px;
						float: right; } } }

			.description {
				margin-top: 136px;

				p:not(.title) {
					margin-top: 40px;
					font-size: 14px; }

				@media screen and (max-width:767px) {
					margin-bottom: 30px; } } }

		.receive-credit {
			padding-top: 64px;
			min-height: 1112px;

			.px20-semipale-text {
				margin-top: 16px;
				margin-bottom: 64px;
				text-align: center; } } }

	.provider-page {
		.payment-parameters {
			padding-top: 64px;

			.parameters-container {

				.fields {
					margin-bottom: 30px;

					input[type="text"] {
						width: 100%; }

					.card-input {
						background: #ECEFF1 url(img/card-icon.png) no-repeat 96% center; }

					.field-error {
						float: none;
						margin-left: 0; } }

				.confirmation {
					min-height: 64px;
					clear: both;

					.checkbox-area {
						position: relative;
						width: 32px;
						height: 32px;
						float: left;

						input[type="checkbox"] {
							width: 32px;
							height: 32px;
							position: absolute;
							left: 0;
							margin: 0;
							opacity: 0;
							cursor: pointer; }

						input[type="checkbox"] + label {
							background: #99C700;
							border-radius: 4px;
							height: 32px;
							width: 32px; }

						input[type="checkbox"]:checked + label {
							background: #99C700 url(img/checked.png) no-repeat center; } }

					.field-error {
						float: none;
						clear: both;
						padding-left: 30px; } }

				p {
					font-size: 12px;
					float: left;
					min-height: 32px;
					line-height: 32px;
					margin-left: 16px;
					width: 80%; }

				a {
					margin-top: 60px;
					margin-bottom: 60px; } }

			.payment-ways {
				position: relative;
				min-height: 100px;
				margin-bottom: 15px;
				p {
					float: none; } }

			.account-list, .card-list {
				border-radius: 2px;
				font-size: 16px;
				overflow: hidden;
				background: #eceff3 url(img/green-arrow-down.png) no-repeat 280px 25px;
				position: relative;
				min-height: 64px;
				width: 300px;
				position: absolute;
				z-index: 1;

				ul {
					border-radius: 2px;
					width: 300px;
					margin-bottom: 0;

					li {
						height: 64px;
						line-height: 64px;
						color: rgba(51, 51, 51, 0.4);
						padding-left: 20px;
						width: 100%;
						cursor: pointer;
						text-overflow: ellipsis;
						white-space: nowrap;
						overflow: hidden; }

					li.account:hover, li.card:hover {
						border: 1px solid #99C700; } } }

			.closed {
				max-height: 64px;
				transition: 1s max-height cubic-bezier(0, 1, 0, 1); }

			.opened {
				max-height: 1000px;
				transition: 1s max-height; }

			.sender-card, .receiver-card {
				position: relative;
				min-height: 100px;
				clear: both; }

			.sender-card {
				z-index: 2; }

			.card-payment-amount {
				width: 300px;
				margin-top: 10px; }

			.loader {
				height: 55px;

				.cssload-loader {
					position: relative;
					float: left; } } }

		.attention {
			width: 296px;
			height: 448px;
			margin: 0 auto;
			text-align: center;
			background: #fff;
			padding-top: 32px;
			padding-left: 24px;
			padding-right: 24px;

			@media screen and (max-width:1420px) {
				width: 100%; }

			img {
				margin-bottom: 24px; }

			p:not(.px12-semipale-text) {
				font-size: 16px;
				margin: 0 auto; }

			.px12-semipale-text {
				margin-top: 24px;
				text-align: left;

				a {
					color: #4990E2; } } }

		.description {
			border-top: 1px solid #E1E1E1;
			padding-top: 40px;
			padding-bottom: 56px;

			ul {
				margin-top: 24px;
				li {
					float: left; } }

			@media screen and (max-width:991px) {
				&>div>div:last-child {
					margin-top: 30px; } } }

		.popup {
			height: 200px;

			p {
				text-align: center; } } }

	.payment-result {
		padding-top: 64px;
		padding-bottom: 224px;
		text-align: center;
		position: relative;

		h1 {
			font-size: 44px;
			margin-top: 40px; }

		.px16-pale-text {
			margin-top: 16px;
			margin-bottom: 64px; }

		.buttons {
			height: 64px;
			width: 552px;
			margin: 0 auto;

			@media screen and (max-width:900px) {
				width: 490px; }

			.btn-big-dark {
				float: left;

				&:hover {
					color: #99C700;
					border-color: #99C700; } }

			.btn-big-fill-green {
				float: right; }

			button {
				@media screen and (max-width:900px) {
					width: 220px; } }

			@media screen and (max-width:600px) {
				width: auto;
				button {
					float: none !important;
					margin: 10px auto; } } }

		.popup {
			height: 200px;

			p {
				text-align: center; } }

		.cssload-loader {
			bottom: 20px;
			top: auto; } }

	.support {
		.support-header {
			min-height: 144px;
			padding-top: 44px;
			padding-bottom: 44px;

			@media screen and (max-width:600px) {
				img {
					display: none; } } }

		.faq {
			padding-top: 64px;

			@media screen and (max-width: 768px) {
				padding: 15px; }

			.px20-semipale-text {
				margin-top: 16px;
				margin-bottom: 64px;
				text-align: center; }

			.question-list {
				margin-bottom: 64px;
				h3 {
					text-transform: uppercase;
					color: #acacaf;
					margin-bottom: 20px;
					margin-top: 40px; }
				.question {
					min-height: 96px;
					background: #fff;
					padding: 24px 24px 12px 24px;
					border: 1px solid #E1E1E1;

					&__title {
						display: flex;
						flex-direction: row;
						justify-content: space-between;
						span {
							line-height: 24px;
							// color: #99c802
							color: $prime-color;
							align-self: center;
							font-size: 24px; }
						button {
							display: inline-block;
							height: 48px;
							width: 48px;
							min-width: 48px;
							border: 1px solid #E1E1E1;
							// color: #99C700
							color: $prime-color;
							border-radius: 24px;
							line-height: 48px;
							text-align: center;
							font-size: 36px;
							user-select: none;
							cursor: pointer; } }
					.support_answer {
						margin-top: 0px;
						color: rgba(0,0,0,1);
						ul {
							color: $greyText;
							margin-left: 30px;
							margin-bottom: 15px;
							list-style-type: disc; }
						ol {
							color: $greyText;
							margin-left: 15px;
							margin-bottom: 15px; } }
					p {
						clear: both;
						transition: max-height .3s;
						overflow: hidden;
						width: 92%;
						margin: 10px 0;
						color: $greyText; }

					p.hidden-element {
						max-height: 0;
						transition: max-height .3s; } } } }

		.question-hint {
			text-align: center;
			font-size: 24px; }

		input, textarea {
			// color:  $prime-color
			border: 1px solid #aaa;

			&:hover {
				border-color: darken($prime-color, 10%); }

			&:focus, &:active {
				border-color: $prime-color; } }

		input.question-title {
			display: block;
			width: 600px;
			margin: 15px auto;
			height: 64px;
			line-height: 64px;
			padding-left: 20px;

			@media screen and (max-width:900px) {
				width: 90%; } }

		.send-success-message {
			height: 20px;
			text-align: center;
			margin-top: 15px; }

		textarea {
			width: 600px;
			height: 200px;
			display: block;
			margin: 20px auto;
			resize: none;
			padding: 20px;

			@media screen and (max-width:900px) {
				width: 90%; } }

		.send-question {
			margin: auto;
			display: block;
			width: 256px;
			padding: 17px;
			line-height: 30px; }
		//
		// .cssload-loader
 }		// 	position: relative
	.faq-form {
		position: relative;

		& .blur::before {
			content: "";
			width: 100%;
			height: 100%;
			position: absolute; } }

	.credit-request {
		.content-header {
			min-height: 144px !important;
			padding-top: 44px !important;
			h1 {
				font-size: 44px !important; } }

		&>h1 {
			margin-top: 64px;
			margin-bottom: 16px; }

		&>p {
			text-align: center;
			margin-bottom: 64px; } }

	.request-result {
		.content-header {
			min-height: 144px !important;
			padding-top: 44px !important;
			padding-bottom: 44px;

			h2 {
				font-size: 44px !important;
				width: 80%; } }

		.bank-description {
			min-height: 500px;

			.left-col {
				float: left;
				width: 50%;
				text-align: left;
				padding: 0 15px;

				&>h1 {
					margin-top: 64px;
					margin-bottom: 16px;
					text-align: left; }

				&>p {
					text-align: center;
					margin-bottom: 64px;
					text-align: left; }

				a {
					margin: 0 auto; }

				@media screen and (max-width:1200px) {
					float: none;
					width: 100%; } }

			.right-col {
				float: right;
				width: 50%;
				text-align: center;
				padding-top: 64px;

				@media screen and (max-width:1200px) {
					float: none;
					width: 100%; }

				img {
					@media screen and (min-width:1201px) {
						width: 95%;
						height: auto; } } } } }

	.office-page {
		.office-page-header {
			height: 144px;
			min-height: auto;
			padding-top: 44px; }

		.filter-params {

			@media screen and (max-width:1200px) {
				min-height: 230px; }

			.select-elements {
				@media screen and (max-width:820px) {
					float: none;
					margin: 0 auto;
					width: 288px; }

				& > div {
					@media screen and (max-width:1200px) {
						float: none; } } }

			.show-variants {
				@media screen and (max-width:820px) {
					float: none;
					margin: 0 auto;
					width: 256px; } } } }

	.personal-page {
		position: relative;

		.content-header {
			padding-top: 30px;
			position: relative;
			overflow: hidden;
			background: #4a98ce;

			@media screen and (max-width:900px) {
				text-align: center !important;
				img {
					position: relative; } }

			input[type="file"] {
					display: none; }

			.blur-bg {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				opacity: 0.7;
				max-width: 100%;
				-webkit-filter: blur(80px);
				filter: blur(80px);

				&>div {
					position: absolute;
					top: -80px;
					bottom: -80px;
					left: -80px;
					right: -80px;
					background-repeat: no-repeat;
					background-size: cover;
					background-position: center; } } }

		.navigation-menu {
			background: #fff;

			.navigation-menu__tabs {
				min-height: 80px;
				padding: 0;
				margin: 0;
				position: relative;

				.navigation-menu__tab {
					display: inline-block;
					font-size: 16px;
					border-left: 1px solid #E1E1E1;

					@media (max-width: 699px) {
						display: block;
						border-left: none; } }

				&:first-of-type {
					border: none; }

				button {
					color: #333333;
					padding: 30px 40px;
					display: block;
					padding-left: 40px;

					&:hover {
						border-bottom: 2px solid lighten($button-orange, 10%); }

					@media (max-width: 699px) {
						padding-top: 30px;
						padding-bottom: 30px;
						padding-right: 100px;

						&:hover {
							border-bottom: none; } } }

				button.active {
					border-bottom: 2px solid $button-orange;

					@media (max-width: 699px) {
						padding-left: 30px;
						border-bottom: none;
						border-left: 10px solid $button-orange;
						background: rgba($button-orange, .1); } } } }

		.parameters-container {
			background: #fff;
			border: 1px solid #E1E1E1;
			margin-bottom: 64px;
			position: relative;

			.change-password-popup {
				height: 450px; }

			.change-password-popup-success {
				height: 200px;

				p {
					text-align: center; } }

			.change-email-popup, .change-address-popup, .change-subscriptions-popup, .success-popup {
				height: 250px; }

			.change-full-name-popup, .change-address-popup {
				height: 400px; }

			.react-datepicker-wrapper, .react-datepicker__input-container {
				display: block; }

			& > .head, & > .parameter, .parameters-container__row {
				padding-left: 40px;
				padding-right: 40px;
				border-bottom: 1px solid #E1E1E1;

				@media screen and (max-width:900px) {
					padding-left: 20px;
					padding-right: 20px; } }

			.title {
				font-size: 26px;
				float: left;

				@media screen and (max-width:767px) {
					float: none;
					text-align: center; } }

			.head {
				padding: 20px;
				display: flex;
				align-items: center;
				justify-content: space-between;
				@media screen and (max-width:767px) {
					display: block; }
				.px16-semipale-text {
					float: right;
					width: 50%;
					&.verified_badge {
						float: left;
						width: 100%;
						margin: 10px 0; }
					@media screen and (max-width:767px) {
						float: none;
						margin-top: 10px;
						margin-bottom: 10px;
						text-align: center;
						width: 100%; } } }

			.parameter {
				min-height: 96px;
				padding-top: 37px;

				&.parameter--button {
					display: flex;
					padding: 0;

					a {
						padding-top: 37px;
						min-height: 96px;
						margin: auto;

						&:hover {
							color: $prime-color; } }

					button {
						display: block;
						align-self: center;
						text-align: center;
						margin: auto; } }

				.px16-semipale-text {
					float: left;

					@media screen and (max-width:650px) {
						float: none;
						text-align: center; } }

				.value {
					float: right;
					font-size: 16px;
					max-width: 70%;
					overflow: hidden;

					@media screen and (max-width:650px) {
						float: none;
						text-align: center;
						max-width: 100%; } }

				a {
					display: block;
					text-align: center;
					color: #333333; } } }

		//TODO all to flexbox
		.personal-settings {
			padding-top: 64px;
			padding-bottom: 120px;

			.geo {
				.px16-semipale-text {
					@media (min-width: 768px) {
						padding-top: 7px; } } }

			.subscriptions {
				&__wrapper {
					padding-left: 0;
					padding-right: 0;
					border-bottom: 1px solid #E1E1E1; }
				.cssload-loader {
					border-bottom: none; }
				.parameter {
					line-height: auto;
					padding-top: 35px;
					position: relative;
					padding-left: 40px;
					padding-right: 40px;
					border-bottom: 1px solid #E1E1E1;
					&--button {
						padding: 0; }
					p {
						width: 85%;
						margin: 0 auto;
						text-align: center; }

					.check {
						top: 35px;
						left: 15px; } } }

			.social-networks {
				min-height: 296px;
				margin-bottom: 120px;
				@media screen and (max-width:650px) {
					min-height: 322px; }

				.parameters {
					min-height: 96px;
					padding: 0;

					.parameter {
						width: 50%;
						float: left;
						padding-top: 24px;
						line-height: auto !important;
						border-right: 1px solid #E1E1E1;
						padding-left: 40px;
						padding-right: 40px;

						@media screen and (max-width:900px) {
							padding-left: 20px;
							padding-right: 20px; }

						@media screen and (max-width:767px) {
							float: none;
							width: 100%;
							border-bottom: 1px solid #E1E1E1;
							text-align: center;
							padding-bottom: 24px; }

						p {
							height: 48px;
							line-height: 48px; }

						img {
							float: right;

							@media screen and (max-width:650px) {
								float: none; } } } } } } }

	.card-page {
		.card-info {
			padding-top: 40px;
			background: #626C6F;

			.card-title {
				font-size: 56px;
				border-bottom: 1px solid #E1E1E1;
				padding-bottom: 36px;
				color: #fff; }


			.main-card-info {
				padding-top: 47px;
				position: relative;
				padding-bottom: 72px;

				.left-column {
					float: left;

					.card-image {
						height: 208px;
						margin-bottom: 48px;

						@media screen and (min-width:421px) {
							width: 320px;
							box-shadow: 0 0 32px 0 rgba(0,0,0,0.08), 0 16px 32px 0 rgba(0,0,0,0.24);
							border-radius: 16px; }

						@media screen and (max-width:1100px) {
							margin: 0 auto; }

						img {

							@media screen and (max-width:420px) {
								width: 100%;
								height: auto; } } }

					.buttons {
						height: 50px;
						width: 320px;

						@media screen and (max-width:420px) {
							width: 275px; }

						.card-replenishment, .card-payment {
							border-top: 1px solid rgba(255, 255, 255, 0.6);
							border-bottom: 1px solid rgba(255, 255, 255, 0.6);
							border-left: 1px solid rgba(255, 255, 255, 0.6);
							height: 64px;
							line-height: 64px;
							text-align: center;
							color: #fff;
							font-size: 14px;
							border-radius: 4px;
							display: block;
							float: left;
							width: 160px;

							@media screen and (max-width:420px) {
								width: 135px; } }

						.card-payment {
							border-right: 1px solid #ccc;
							border-radius: 0 4px 4px 0; }

						.card-replenishment {
							border-right: 1px solid #ccc;
							border-radius: 4px 0 0 4px; }

						@media screen and (max-width:1100px) {
							margin: 40px auto; } }

					@media screen and (max-width:1100px) {
						float: none;
						text-align: center; } }

				.center-column {
					margin-left: 15%;
					width: 200px;
					float: left;

					@media screen and (max-width: 1200px) {
						margin-left: 45px; }


					@media screen and (max-width:1100px) {
						text-align: center;
						margin: auto;
						float: none; } }



				.right-column {
					float: right;
					width: 250px;

					.block-card {
						color: #fff;
						height: 64px;
						line-height: 64px;
						text-align: center;
						display: block;
						margin-top: 104px;
						border-radius: 2px;
						font-size: 16px;
						width: 256px;
						border: 1px solid rgba(255, 255, 255, 0.6);
						margin-left: auto;
						margin-right: auto;
						@media screen and (max-width:1100px) {
							margin-top: 40px; } }

					@media screen and (max-width:1100px) {
						float: none;
						text-align: center;
						margin: 0 auto; } }


				.title {
					font-size: 15px;
					color: rgba(255, 255, 255, 0.5);
					text-transform: uppercase; }

				.value {
					font-size: 25px;
					color: #fff;
					margin-bottom: 40px;
					word-wrap: break-word; } } } }
	.events-block {
		padding-top: 64px;

		& > div > div {
			padding-left: 0;
			padding-right: 0; }

		.events {
			color: #000;
			padding-left: 15px;
			padding-right: 15px;

			h3 {
				color: #333333;
				font-size: 26px;
				margin-top: 0;
				margin-bottom: 48px; }

			.event {
				border-bottom: 1px solid #ccc;
				min-height: 96px;
				background: #fff url(img/event-icon.png) no-repeat 24px center;
				padding: 24px 24px 24px 96px;
				box-shadow: 0 1px 0 0 rgba(70,70,70,0.12);

				@media screen and (max-width:1000px) and (min-width: 768px) {
					p {
						float: none !important;
						width: 100% !important; } }

				@media screen and (max-width:550px) {
					p {
						float: none !important;
						width: 100% !important; } }


				.sum {
					float: right;
					font-size: 16px;
					color: #333333; }

				.event-type {
					clear: both;
					float: left;
					opacity: 0.36;
					font-size: 14px;
					color: #333333; }

				.event-description {
					clear: both;
					float: left;
					font-size: 16px;
					width: 70%;
					color: #333333;

					@media screen and (min-width:551px) {
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap; } }

				.event-category {
					float: right;
					opacity: 0.36;
					font-size: 14px;
					color: #333333; } }

			.day {
				background: #f2f2f2;
				height: 40px;
				line-height: 40px;
				margin-bottom: 0;
				padding-left: 24px;
				box-shadow: 0 1px 0 0 rgba(70,70,70,0.12);
				border-left: 4px solid #99C700;
				color: rgba(51, 51, 51, 0.36);
				text-transform: uppercase;
				font-size: 14px; } } }

	.account-page {
		.account-info {
			padding-top: 40px;
			background: #626C6F; }

		.account-title {
			font-size: 56px;
			border-bottom: 1px solid #E1E1E1;
			padding-bottom: 36px;
			color: #fff; }

		.main-account-info {
			padding-top: 47px;
			position: relative;
			padding-bottom: 72px;
			text-align: center;

			.parameter {
				margin: 0 auto 20px auto;
				min-width: 325px;

				.title {
					font-size: 15px;
					color: rgba(255, 255, 255, 0.5);
					text-transform: uppercase; }

				.value {
					font-size: 25px;
					color: #fff;
					max-width: 60%;
					margin: 0 auto;
					word-wrap: break-word; } }

			.buttons {
				height: 50px;
				width: 320px;
				margin: 15px auto;
				clear: both;

				a {
					border-top: 1px solid rgba(255, 255, 255, 0.6);
					border-bottom: 1px solid rgba(255, 255, 255, 0.6);
					border-left: 1px solid rgba(255, 255, 255, 0.6);
					height: 64px;
					line-height: 64px;
					text-align: center;
					color: #fff;
					font-size: 14px;
					border-radius: 4px;
					display: block;
					float: left;
					width: 160px; }

				.account-payment {
					border-right: 1px solid rgba(255, 255, 255, 0.6);
					border-radius: 0 4px 4px 0; }

				.account-replenishment {
					border-right: 1px solid #ccc;
					border-radius: 4px 0 0 4px; } } } }

	@import './styles/exchange/exchange-page';
	@import './styles/exchange/exchange-chart'; }

.popup-bg {
	background: #303030;
	opacity: 0.8;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 15; }

.blur {
	-webkit-filter: url(#blur);
	filter: url(#blur);
	-webkit-filter: blur(3px);
	filter: blur(3px);
	/*filter: progid: DXImageTransform.Microsoft.Blur(PixelRadius='3') */
	-webkit-transition: 1s -webkit-filter linear;
	transition: 0.1s filter linear;
 }	//position: relative

@import './styles/cssload-loaders';

/* Flags and country select */
.flag::before, .flag-small::before {
	content: "";
	float: left;
	background: white;
	width: 40px;
	height: 28px; }

.flag::before {
	outline: 1px solid #aaa; }

.flag-::before, .flag-small-::before {
	display: none; }

@import './styles/flags-sprite';
@include sprites($spritesheet-sprites);

@import './styles/flags-sprite-small';
@include sprites-small($spritesheet-sprites-small);

.popup.change-phone-popup {
	top: 50%;
	bottom: auto;
	height: auto;
	transform: translateY(-50%); }

@import './styles/phone-codes-selector';

/* Wallets */
@import './styles/accounts';

@import './styles/animations';

.support_answer ol {
	list-style-type: decimal;
	padding-left: 15px;

	& li {
		margin-bottom: 10px; } }

.main-container {
	.change-login-popup-success {
		top: 50%;
		left: 50%;
		bottom: auto;
		right: auto;
		transform: translate(-50%, -50%);
		margin: 0; }
	.personal-page {
		.navigation-internal {

			.language {
				float: right;
				position: relative;
				width: 82px;
				border-left: none;



				@media (max-width: 768px) {
					float: right;
					width: unset;
					margin-right: auto;
					margin-lefT: auto; }
				@media (max-width: 767px) {
					float: none;
					margin-top: 30px;
					padding-bottom: 70px; }
				ul {
					height: 42px;
					transition: 0.5s height;
					border-radius: 10px;
					border: 1px solid #99c700;
					overflow: hidden;
					position: absolute;
					padding-left: 0;
					padding-top: 0;
					top: -6px;
					right: 0;
					min-height: auto;
					background-color: #fff;

					@media (max-width: 768px) {
						position: relative;
						display: inline-block;

						li:last-child {
							opacity: .5; } }

					@media (max-width: 767px) {
						margin-bottom: 15px; }

					li {
						line-height: 40px;
						height: 42px;
						text-align: center;
						padding: 10px 20px;

						a {
							display: block;
							cursor: pointer;
							background: url(img/flags.png) no-repeat center;
							width: 40px;
							border: 1px solid #000;
							height: 22px;
							padding: 0; }

						@media (max-width: 767px) {
							padding-top: 0 !important;
							padding-bottom: 0 !important;
							border-left: none;
							float: left; } }

					&.opened {
						height: 84px;

						@media (max-width: 768px) {
							height: 42px;
							transition: 0.5s height; } } } } } } }
.top-menu {
	&__item {
		&--active span {
			color: $button-blue !important; } }
	&__icon-text {
		@media screen and (max-width: 768px) {
			display: none; } }
	&__hamburger {
		display: none;
		align-items: center;
		@media screen and (max-width: 768px) {
			display: flex; }
		& img {
			margin-bottom: 10px; } }
	&__hamburger-container {
		position: fixed;
		width: 100vw;
		height: 100vh;
		top: 0;
		left: 0;
		background-color: #fff;
		z-index: 200;
		position: relative;
		padding-top: 100px; }
	&__hamburger-close-ico {
		width: 32px;
		height: 32px;
		position: absolute;
		top: 30px;
		right: 10px; }
	&__hamburger-item-container {
		border-bottom: 1px solid rgba(100,100,100,.1);
		&:active {
			background-color: rgba(100,100,100,.1); }
		&--first {
			border-top: 1px solid rgba(100,100,100,.1); } }
	&__hamburger-item {
		padding: 15px 35px;
		display: block;
		color: #000;
		font-size: 1.4rem;
		&--hidden {
			@media screen and (max-width: 768px) {
				display: none; } } }
	&__hamburger-logo {
		position: absolute;
		top: 30px;
		left: 15px;
		padding-top: 0 !important;
		height: 100px; } }


.personal-page-header {
	background: #14384c url("/img/first-screen-mobile-bg.jpg") center top no-repeat;
	background-size: cover;

	@media (max-width: 760px) {
		display: none; }

	// @media screen and (max-width:768px)
 }	///display: none

// .main-container
// 	.navbar-collapse
// 		.personal-info
// 			@media screen and (max-width: 768px)
// 				padding: 0 0
// 			.balances
// 				overflow: hidden

.personal-page-header__content {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-wrap: wrap;
	overflow: hidden;

	@media (max-width: 992px) {
		justify-content: center; }

	@media (max-width: 768px) {
		min-width: 120px;
		min-height: 120px; } }

.personal-page-header__image-link {
	width: 100px;
	height: 100px;
	background-repeat: no-repeat;
	background-size: cover;
	min-height: 100px;
	min-width: 100px;
	overflow: hidden;
	border-radius: 98px;
	margin-right: 15px;

	&:hover {
		cursor: pointer;
		filter: sepia(80%); }
	@media (max-width: 992px) {
		margin-right: 0; }

	img {
		min-width: 100%;
		max-width: 100%;
		position: static;
		top: auto;
		left: auto;
		right: auto;
		bottom: auto; } }

.personal-page-header__title {
	align-self: center;
	padding: 15px;
	font-size: 30px !important;
	font-weight: 700;

	@media (max-width: 1024px) {
		font-size: 34px !important; }

	@media (max-width: 992px) {
		text-align: center !important;
		width: 100%; }

	@media (max-width: 768px) {
		font-size: 28px !important; } }

.personal-page-header__verification-status {
	text-align: right;
	padding: 0 15px; }

$walletBorderColor: #efefef;

.account-wallet {
	display: flex;
	flex-direction: row;
	background-color: rgba(255,255,255,1);
	// width: 90%
	margin-bottom: 20px;
	border-radius: 4px;
	@media screen and (max-width: 1200px) {
		width: 100%; }
	@media screen and (max-width: 520px) {
		width: 100%;
		flex-wrap: wrap; }
	&__title {
		align-self: center;
		padding: 10px 15px;
		justify-content: center;
		text-align: center;
		min-width: 80px;
		max-width: 80px;
		@media screen and (max-width: 520px) {
			padding: 10px 10px; } }
	&__currency-ico {}
	&__currency-fullname {
		font-size: .85rem;
		color: rgba(0,0,0,.4);
		text-align: center;
		margin-top: 10px; }
	&__sum {
		font-size: 1.6rem;
		color: rgba(0,0,0,1);
		font-weight: bold;
		@media screen and (max-width: 520px) {
			font-size: 1.4rem; } }
	&__currency-rate {
		font-size: 1rem;
		color: rgba(0,0,0,.4);
		margin-top: 3px; }
	&__body {
		flex: 1;
		//align-self: center
		padding: 15px 15px;
		border-left: 1px solid $walletBorderColor;
		border-right: 1px solid $walletBorderColor;
		@media screen and (max-width: 520px) {
			padding: 10px 10px; } }
	&__currency-price {
		margin-top: 10px;
		border-top: 1px solid $walletBorderColor;
		padding: 15px 0 0 0;
		@media screen and (max-width: 520px) {
			font-size: .9rem;
			padding: 10px 0 0 0; }
		&-value {
			margin-left: 15px;
			@media screen and (max-width: 520px) {
				margin-left: 10px; } }
		&--positive {
			color: #99c700;
			white-space: nowrap; }
		&--negative {
			color: #ff7049;
			white-space: nowrap; } }
	&__currency-period {
		color: rgba(0,0,0,0.4);
		font-weight: 300;
		margin-left: 5px; }

	&__controls {
		align-self: center;
		padding: 10px 20px;
		display: flex;
		flex-direction: column;
		@media screen and (max-width: 520px) {
			width: 100%;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: center;
			border-top: 1px solid $walletBorderColor;
			padding: 10px 10px; } }
	&__btn {
		padding: 5px 20px;
		border-radius: 90px;
		flex-shrink: 0;
		@media screen and (max-width: 520px) {
			max-width: none;
			padding: 12px 20px; }
		&--modal {
			width: auto;
			line-height: 46px;
			margin: 30px auto 25px auto; } }
	&__reserved {
		color: rgba(0,0,0,.6);
		font-size: .8em;
		font-weight: normal;
		display: inline-block;
		margin-left: 20px;
		@media (max-width: 1650px) {
			display: block;
			margin: 0; }
		@media screen and (max-width: 768px) {
			margin-left: 0;
			margin-bottom: 5px; } } }

.logo-svg {
	width: 190px;
	@media screen and (max-width: 420px) {
		width: 160px; }
	@media screen and (max-width: 360px) {
		width: 136px; } }

.blur .reg-window::before,
.blur.password-restore-popup::before {
	content: '';
	width: 100%;
	height: 100%;
	z-index: 10;
	position: absolute;
	top: 0;
	left: 0;
	background: rgba(250,250,250,.9); }

.wallet-events {
	margin: 20px 0;

	@media screen and (max-width: 768px) {
		padding: 0 0px !important; }
	& > div {
		padding: 0 0 !important; }
	&__item {
		border-radius: 4px;
		display: flex;
		padding: 0px 0px;
		align-items: stretch;
		align-content: center;
		&:hover {
			.wallet-events__item-source-copy {
				opacity: 1; } }
		&--top {
			margin-bottom: 0 !important;
			border-radius: 4px 4px 0 0;
			& .wallet-events__item-date {
				transform: translateY(260%) rotate(-90deg); }
			& .wallet-events__item-content-container {
				border-bottom: 1px solid #efefef;
 } }				//background-color: rgba(255,189,189,.2)
		&--bottom {
			border-radius: 0 0 4px 4px;
			& .wallet-events__item-date {
				visibility: hidden; }
			& .wallet-events__item-content-container {
 } }				//background-color: rgba(189,255,189,.2)
		&-date-container {
			display: flex;
			flex-flow: column-reverse nowrap;
			align-items: center;
			justify-content: center;
			align-self: stretch;
			padding: 7px;
			color: #9a9a9a;
			max-width: 56px; }
		&-date {
			transform: rotate(-90deg);
			align-self: center;
			white-space: nowrap;
			text-transform: uppercase; }
		&-content-container {
			display: flex;
			flex: 1;
			border-left: 1px solid #efefef;
			padding-top: 25px;
			padding-bottom: 25px;
			@media screen and (max-width: 767px) {
				flex-direction: column; }
			@media screen and (max-width: 414px) {
				padding: 5px 0; } }

		&-operation-container {
			display: flex;
			flex: 1;
			@media screen and (max-width: 767px) {
				//border-bottom: 1px solid #efefef
				padding: 10px 0; } }

		&-icon {
			align-self: flex-start;

			padding: 25px 15px 0;
			display: flex;
			&-img {
				align-self: center; } }
		&-action-container {
			flex: 1;
			display: flex;
			flex-direction: column;
			justify-content: center; }
		&-action {
			color: #030304;
			font-size: 24px;
			font-weight: 700;
			@media screen and (max-width: 1024px) {
				font-size: 18px; } }
		&-source {
			opacity: .6;
			color: #030304;
			font-size: 16px;
			font-weight: 400;
			position: relative;
			display: flex;
			&-input-container {
				flex: 1; }
			& input {
				height: 32px;
				font-size: .8rem;
				width: 100%; }
			&-copy {
				border: none;
				width: 32px;
				height: 32px;
				padding: 4px;
				opacity: .1;
				transition: all .2s linear; } }
		&-amount-container {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			padding: 25px 20px 5px;
			text-align: right; }
		&-cryptoamount {
			//font-family: "Cera Web", "SFUI-Regular"
			font-size: 18px;
			font-weight: 600;
			text-transform: uppercase;
			&--increment {
				color: #ccd520; }
			&--decrement {
				color: #FF7049; }
			&--pending {
				color: #7baefc; } }
		&-currencyamount {
			opacity: 0.4;
			color: #030304;
			//font-family: "Cera Web", "SFUI-Regular"
			font-size: 16px;
			font-weight: 400;
			text-transform: uppercase; }
		&-status {
			margin-top: 5px; } }

	&__older-activity {
		margin-bottom: 20px;
		padding: 20px 0;
		cursor: pointer;
		display: inline-block;
		font-weight: 700;
		color: #000;
		opacity: 0.4;
		position: relative;
		padding-right: 20px;

		&::after {
			content: '';
			width: 6px;
			height: 6px;
			position: absolute;
			top: 50%;
			right: 5px;
			border-bottom: 2px solid #000;
			border-left: 2px solid #000;
			transform: translateY(-50%) rotate(-45deg); }

		&:hover {
			color: $prime-color;
			opacity: 1;

			&::after {
				border-color: $prime-color; } } }

	&__transaction-plate {
		white-space: nowrap;
		display: inline-block;

		@media screen and (max-width: 550px) {
			display: block; }

		&__icon {
			padding-right: 10px; } }

	&__transaction-arrow {
		display: inline-block;
		width: 30px;
		position: relative;
		top: 10px;
		@media screen and (max-width: 550px) {
			transform: rotate(0.25turn);
			margin-bottom: 6px;
			position: relative; } } }

.reg-window__captcha-container {
	@media (min-width: 480px) {
		#rc-imageselect {
			transform: none;
			-webkit-transform: none; }

		.g-recaptcha iframe {
			max-width: none;
			transform: none;
			-webkit-transform: none; }

		& > div > div > div {
			margin: 4px auto !important;
			text-align: center;
			width: auto !important;
			height: auto !important; } } }

.complete-popup {
	margin: 10px;
	&__title {
		font-size: 1.2rem;
		color: #000000;
		margin: 15px;
		text-align: center; }
	&__controls {
		padding: 10px;
		text-align: center; }
	&__controls-btn {
		padding-left: 40px;
		padding-right: 40px; } }

.wallet-events-container {
	padding: 64px 25px 64px 40px;
	@media (max-width: 1200px) {
		width: 100%;
		max-width: 100% !important; }
	@media screen and (max-width: 768px) {
		padding: 20px; } }

.balance-list {
	&__title {
		font-size: 14px;
		color: rgba(0,0,0,.4);
		margin-left: 15px;
		@media screen and (max-width: 540px) {
			margin-left: 0; } }
	& .sum {
		align-self: center;
		margin: 0 auto;
		color: rgba(0,0,0,.4);
		font-size: 14px; } }

.loader {
  margin: 100px auto;
  font-size: 25px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: load5 1.1s infinite ease;
  animation: load5 1.1s infinite ease;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0); }

@-webkit-keyframes load5 {
  0%,
  100% {}
		box-shadow: 0em -2.6em 0em 0em #4a98ce, 1.8em -1.8em 0 0em rgba(74, 152, 206, 0.2), 2.5em 0em 0 0em rgba(74, 152, 206, 0.2), 1.75em 1.75em 0 0em rgba(74, 152, 206, 0.2), 0em 2.5em 0 0em rgba(74, 152, 206, 0.2), -1.8em 1.8em 0 0em rgba(74, 152, 206, 0.2), -2.6em 0em 0 0em rgba(74, 152, 206, 0.5), -1.8em -1.8em 0 0em rgba(74, 152, 206, 0.7);

  12.5% {}
		box-shadow: 0em -2.6em 0em 0em rgba(74, 152, 206, 0.7), 1.8em -1.8em 0 0em #4a98ce, 2.5em 0em 0 0em rgba(74, 152, 206, 0.2), 1.75em 1.75em 0 0em rgba(74, 152, 206, 0.2), 0em 2.5em 0 0em rgba(74, 152, 206, 0.2), -1.8em 1.8em 0 0em rgba(74, 152, 206, 0.2), -2.6em 0em 0 0em rgba(74, 152, 206, 0.2), -1.8em -1.8em 0 0em rgba(74, 152, 206, 0.5);

  25% {}
		box-shadow: 0em -2.6em 0em 0em rgba(74, 152, 206, 0.5), 1.8em -1.8em 0 0em rgba(74, 152, 206, 0.7), 2.5em 0em 0 0em #4a98ce, 1.75em 1.75em 0 0em rgba(74, 152, 206, 0.2), 0em 2.5em 0 0em rgba(74, 152, 206, 0.2), -1.8em 1.8em 0 0em rgba(74, 152, 206, 0.2), -2.6em 0em 0 0em rgba(74, 152, 206, 0.2), -1.8em -1.8em 0 0em rgba(74, 152, 206, 0.2);

  37.5% {}
		box-shadow: 0em -2.6em 0em 0em rgba(74, 152, 206, 0.2), 1.8em -1.8em 0 0em rgba(74, 152, 206, 0.5), 2.5em 0em 0 0em rgba(74, 152, 206, 0.7), 1.75em 1.75em 0 0em #4a98ce, 0em 2.5em 0 0em rgba(74, 152, 206, 0.2), -1.8em 1.8em 0 0em rgba(74, 152, 206, 0.2), -2.6em 0em 0 0em rgba(74, 152, 206, 0.2), -1.8em -1.8em 0 0em rgba(74, 152, 206, 0.2);

  50% {}
		box-shadow: 0em -2.6em 0em 0em rgba(74, 152, 206, 0.2), 1.8em -1.8em 0 0em rgba(74, 152, 206, 0.2), 2.5em 0em 0 0em rgba(74, 152, 206, 0.5), 1.75em 1.75em 0 0em rgba(74, 152, 206, 0.7), 0em 2.5em 0 0em #4a98ce, -1.8em 1.8em 0 0em rgba(74, 152, 206, 0.2), -2.6em 0em 0 0em rgba(74, 152, 206, 0.2), -1.8em -1.8em 0 0em rgba(74, 152, 206, 0.2);

  62.5% {}
		box-shadow: 0em -2.6em 0em 0em rgba(74, 152, 206, 0.2), 1.8em -1.8em 0 0em rgba(74, 152, 206, 0.2), 2.5em 0em 0 0em rgba(74, 152, 206, 0.2), 1.75em 1.75em 0 0em rgba(74, 152, 206, 0.5), 0em 2.5em 0 0em rgba(74, 152, 206, 0.7), -1.8em 1.8em 0 0em #4a98ce, -2.6em 0em 0 0em rgba(74, 152, 206, 0.2), -1.8em -1.8em 0 0em rgba(74, 152, 206, 0.2);

  75% {}
		box-shadow: 0em -2.6em 0em 0em rgba(74, 152, 206, 0.2), 1.8em -1.8em 0 0em rgba(74, 152, 206, 0.2), 2.5em 0em 0 0em rgba(74, 152, 206, 0.2), 1.75em 1.75em 0 0em rgba(74, 152, 206, 0.2), 0em 2.5em 0 0em rgba(74, 152, 206, 0.5), -1.8em 1.8em 0 0em rgba(74, 152, 206, 0.7), -2.6em 0em 0 0em #4a98ce, -1.8em -1.8em 0 0em rgba(74, 152, 206, 0.2);

  87.5% {}
		box-shadow: 0em -2.6em 0em 0em rgba(74, 152, 206, 0.2), 1.8em -1.8em 0 0em rgba(74, 152, 206, 0.2), 2.5em 0em 0 0em rgba(74, 152, 206, 0.2), 1.75em 1.75em 0 0em rgba(74, 152, 206, 0.2), 0em 2.5em 0 0em rgba(74, 152, 206, 0.2), -1.8em 1.8em 0 0em rgba(74, 152, 206, 0.5), -2.6em 0em 0 0em rgba(74, 152, 206, 0.7), -1.8em -1.8em 0 0em #4a98ce; }


@keyframes load5 {
  0%,
  100% {}
		box-shadow: 0em -2.6em 0em 0em #4a98ce, 1.8em -1.8em 0 0em rgba(74, 152, 206, 0.2), 2.5em 0em 0 0em rgba(74, 152, 206, 0.2), 1.75em 1.75em 0 0em rgba(74, 152, 206, 0.2), 0em 2.5em 0 0em rgba(74, 152, 206, 0.2), -1.8em 1.8em 0 0em rgba(74, 152, 206, 0.2), -2.6em 0em 0 0em rgba(74, 152, 206, 0.5), -1.8em -1.8em 0 0em rgba(74, 152, 206, 0.7);

  12.5% {}
		box-shadow: 0em -2.6em 0em 0em rgba(74, 152, 206, 0.7), 1.8em -1.8em 0 0em #4a98ce, 2.5em 0em 0 0em rgba(74, 152, 206, 0.2), 1.75em 1.75em 0 0em rgba(74, 152, 206, 0.2), 0em 2.5em 0 0em rgba(74, 152, 206, 0.2), -1.8em 1.8em 0 0em rgba(74, 152, 206, 0.2), -2.6em 0em 0 0em rgba(74, 152, 206, 0.2), -1.8em -1.8em 0 0em rgba(74, 152, 206, 0.5);

  25% {}
		box-shadow: 0em -2.6em 0em 0em rgba(74, 152, 206, 0.5), 1.8em -1.8em 0 0em rgba(74, 152, 206, 0.7), 2.5em 0em 0 0em #4a98ce, 1.75em 1.75em 0 0em rgba(74, 152, 206, 0.2), 0em 2.5em 0 0em rgba(74, 152, 206, 0.2), -1.8em 1.8em 0 0em rgba(74, 152, 206, 0.2), -2.6em 0em 0 0em rgba(74, 152, 206, 0.2), -1.8em -1.8em 0 0em rgba(74, 152, 206, 0.2);

  37.5% {}
		box-shadow: 0em -2.6em 0em 0em rgba(74, 152, 206, 0.2), 1.8em -1.8em 0 0em rgba(74, 152, 206, 0.5), 2.5em 0em 0 0em rgba(74, 152, 206, 0.7), 1.75em 1.75em 0 0em #4a98ce, 0em 2.5em 0 0em rgba(74, 152, 206, 0.2), -1.8em 1.8em 0 0em rgba(74, 152, 206, 0.2), -2.6em 0em 0 0em rgba(74, 152, 206, 0.2), -1.8em -1.8em 0 0em rgba(74, 152, 206, 0.2);

  50% {}
		box-shadow: 0em -2.6em 0em 0em rgba(74, 152, 206, 0.2), 1.8em -1.8em 0 0em rgba(74, 152, 206, 0.2), 2.5em 0em 0 0em rgba(74, 152, 206, 0.5), 1.75em 1.75em 0 0em rgba(74, 152, 206, 0.7), 0em 2.5em 0 0em #4a98ce, -1.8em 1.8em 0 0em rgba(74, 152, 206, 0.2), -2.6em 0em 0 0em rgba(74, 152, 206, 0.2), -1.8em -1.8em 0 0em rgba(74, 152, 206, 0.2);

  62.5% {}
		box-shadow: 0em -2.6em 0em 0em rgba(74, 152, 206, 0.2), 1.8em -1.8em 0 0em rgba(74, 152, 206, 0.2), 2.5em 0em 0 0em rgba(74, 152, 206, 0.2), 1.75em 1.75em 0 0em rgba(74, 152, 206, 0.5), 0em 2.5em 0 0em rgba(74, 152, 206, 0.7), -1.8em 1.8em 0 0em #4a98ce, -2.6em 0em 0 0em rgba(74, 152, 206, 0.2), -1.8em -1.8em 0 0em rgba(74, 152, 206, 0.2);

  75% {}
		box-shadow: 0em -2.6em 0em 0em rgba(74, 152, 206, 0.2), 1.8em -1.8em 0 0em rgba(74, 152, 206, 0.2), 2.5em 0em 0 0em rgba(74, 152, 206, 0.2), 1.75em 1.75em 0 0em rgba(74, 152, 206, 0.2), 0em 2.5em 0 0em rgba(74, 152, 206, 0.5), -1.8em 1.8em 0 0em rgba(74, 152, 206, 0.7), -2.6em 0em 0 0em #4a98ce, -1.8em -1.8em 0 0em rgba(74, 152, 206, 0.2);

  87.5% {}
		box-shadow: 0em -2.6em 0em 0em rgba(74, 152, 206, 0.2), 1.8em -1.8em 0 0em rgba(74, 152, 206, 0.2), 2.5em 0em 0 0em rgba(74, 152, 206, 0.2), 1.75em 1.75em 0 0em rgba(74, 152, 206, 0.2), 0em 2.5em 0 0em rgba(74, 152, 206, 0.2), -1.8em 1.8em 0 0em rgba(74, 152, 206, 0.5), -2.6em 0em 0 0em rgba(74, 152, 206, 0.7), -1.8em -1.8em 0 0em #4a98ce; }


.wallet-events__item-source-copy--copied {
	display: none; }
.wallet-events__item-source-copy--pending .wallet-events__item-source-copy--copied {
	display: block; }
.wallet-events__item-source-copy--pending .wallet-events__item-source-copy--default {
	display: none; }
._mw375 {
	display: flex;
	@media screen and (max-width: 375px) {
		display: none;
		padding: 0 5px; } }
._mw320 {
	display: none;
	@media screen and (max-width: 375px) {
		display: flex;
		padding: 0 15px; } }
.test-class {
	font-weight: normal; }

.noBoxShadow {
	box-shadow: none !important; }
.whiteBg {
	background-color: #fff !important; }

.app-info {
	&__popup {
		position: fixed;
		background-color: rgba(255,255,255,1);
		top: 10px;
		right: 10px;
		padding: 30px;
		border-radius: 4px;
		color: #333;
		font-size: 1.2rem;
		z-index: 10000;
		box-shadow: 2px 2px 10px rgba(0,0,0,.4);
		text-align: center;
		animation: appInfoBlink infinite 5s;
		max-width: 300px; }
	&__btn-refresh {
		margin: 30px 0 0 0; }
	&__close {
		position: absolute;
		top: 5px;
		right: 5px;
		background: url(/img/close-icon.png) no-repeat center;
		display: block;
		height: 24px;
		width: 24px;
		margin: 0;
		cursor: pointer; } }

@keyframes appInfoBlink {
	0%,
	100% {
		background-color: #ffffff; }
	50% {
		background-color: rgba(216,241,255, 1); } }

.modal-fullscreen {
	position: fixed;
	z-index: 202;
	width: 100vw;
	height: 100vh;
	background-color: #fff;
	top: 0;
	left: 0;
	&__header {
		padding: 15px;
		border-bottom: 1px solid rgba(0,0,0,.2);
		font-size: 1.2rem;
		display: flex;
		&-logo {}
		&-title {
			flex: 1;
			margin-left: 20px;
			text-align: center; } }
	&__body {
		padding: 15px;
		display: flex;
		justify-content: center;
		&-wrapper {
			width: 60vw;
			@media screen and (max-width: 768px) {
				width: 80vw; }
			@media screen and (max-width: 540px) {
				width: 90vw; } } }
	&__controls {
		text-align: center;
		padding: 20px 0; }
	&__close {
		position: absolute;
		top: 15px;
		right: 15px;
		background: url(/img/close-icon.png) no-repeat center;
		display: block;
		height: 24px;
		width: 24px;
		margin: 0;
		cursor: pointer; }
	&__form {
		margin: 20px 0;
		input.valid {
			background-color: #b8f4b8;
			transition: 0.5s all; } }
	&__success {
		text-align: center;
		&-ico {
			margin: 0px 10px;
			& img {
				max-width: 160px; } }
		&-controls {
			margin: 20px 10px; }
		&-text {
			font-size: 1.3rem; } }
	&__input-error {
		background-image: url(./img/modal-ico/modal-warning-ico.png) !important;
		background-repeat: no-repeat !important;
		background-size: 48px 48px !important;
		background-position: 99% 50% !important;
		box-shadow: 0px 0px 10px rgba(255,0,0,.8); }
	&__input-error-text {
		color: rgba(255,0,0,.9);
		margin: 10px 0; } }

.security-data-popup {
	padding: 15px 0 30px;

	.field-error {
		padding: 0;
		text-align: center; } }

.security-data-popup input {
	margin: 15px auto;
	display: block;
	width: 75%;

	@media (max-width: 450px) {
		width: 90%;
		font-size: 16px; }

	@media (max-width: 380px) {
		font-size: 14px; } }

.security-data-popup__text--completed {
	font-size: 22px;
	text-align: center;
	margin: 40px; }

.security-data-popup__button {
	margin: 15px auto 0;
	display: block;
	width: 200px; }

.verification-block {
	.verification-block__title {
		line-height: 2;
		vertical-align: middle; }

	.verification-block__input {
		margin: 0 auto; } }

.user-settings {
	&__content-container {
		margin: 15px;
		text-align: center;
		&--mb {
			margin: 0 0 20px 0;
			text-align: center; } }
	&__code {
		background-color: #eee;
		border-radius: 4px;
		padding: 6px 16px; }
	&__link {
		color: orange !important;
		display: inline !important; } }

.jc-checkbox {
	position: relative;
	display: flex;
	margin-right: 10px;
	cursor: pointer;
	& input[type=checkbox] {
		height: 24px;
		position: absolute;
		left: 0;
		margin: 0;
		cursor: pointer;
		opacity: 0;
		width: 24px; }
	& input[type=checkbox] + label {
		border-radius: 4px;
		height: 24px;
		width: 24px;
		background-color: #f1f3f9; }
	& input[type=checkbox]:checked + label {
		background: #3898ce url(img/checked.png) no-repeat center; }
	&__description {
		height: 24px;
		display: inline-block;
		line-height: 24px;
		margin-left: 15px; } }

.support-send-message-popup {
	padding: 0 0 20px;

	.support-send-message-popup__title {
		padding: 20px 50px;
		text-align: center;
		line-height: 1.5; }

	.btn {
		margin: 0 auto 20px;
		display: block;
		width: 256px;
		padding: 17px;
		line-height: 30px; } }

.Toastify__toast-body {
	margin: auto 0;
	-ms-flex: 1;
	flex: 1 1;
	padding: 15px;
	line-height: 1.5 !important;
	font-family: Montserrat, Helvetica, Arial, sans-serif !important;
	text-align: center; }

.notify {
	display: flex;
	flex-direction: row;
	justify-content: center;
	background-color: rgb(242, 243, 248);
	&__wrapper {
		margin: 20px 0;
		max-width: 960px;
		flex: 1;
		padding: 25px; }
	&__header {
		font-size: 1.6rem; }
	&__content {
		background-color: rgba(255,255,255,1);
		margin: 15px 0;
		padding: 15px; }
	&__label {
		display: block;
		font-size: 20px;
		font-weight: 300;
		text-align: left;
		width: 100%; }
	&__input {
		margin-left: 0; }
	&__textarea {
		width: 100%;
		resize: vertical;
		background: #fafafa;
		border-radius: 2px;
		height: 64px;
		font-size: 18px;
		color: #333333;
		padding-left: 20px;
		padding-right: 10px;
		box-shadow: none;
		letter-spacing: 1px;
		border: 1px solid rgba(0, 0, 0, 0.075);
		min-height: 200px; } }

.forbidden-page {
	position: fixed;
	width: 100vw;
	height: 100vh;
	left: 0;
	top: 0;
	background: $prime-color;
	text-align: center;
	padding: 20vh 0;
	color: white;

	.forbidden-page__title {
		margin: 50px auto 30px; }

	.forbidden-page__subtitle {
		margin-bottom: 40px; } }

.parameters-container {
	.modal__area {
		padding-top: 1px; } }

@keyframes blinking {
	from {
		opacity: 0.8; }
	10% {
		opacity: 0; }
	to {
		opacity: 0.8; } }
.landing__map {
	height: 500px;
	display: flex;
	justify-content: center;
	margin: 30px 0; }
.leaflet-container {
	height: 500px;
	width: 50%;
	@media screen and (max-width: 980px) {
		width: 100%;
		width: 100vw; } }

/* BEGIN FAQ */
.faq-block {
	font-size: 16px;
	line-height: 1.5;
	font-family: 'PT Sans', sans-serif;
	background-color: #f2f3f8;
	flex: 1;
	padding: 40px 0;
	& img {
		max-width: 90%; }
	& ol {
		counter-reset: item;
		& li {
			display: block; }
		& li:before {
			content: counters(item, ".") ". ";
			counter-increment: item;
			font-weight: bold; } }
	& ol.no-counter {
		& li {
			margin: 20px 0; }
		& li:before {
			content: '' !important; } }
	@media screen and (max-width: 980px) {
		font-size: 1rem; }
	&__container {
		display: flex;
		justify-content: space-between;
		align-items: center;
		align-content: center; }
	&__menu {
		display: flex;
		justify-content: center;
		align-items: stretch;
		align-content: center;
		width: 100%;
		flex-wrap: wrap;
		@media screen and (max-width: 1280px) {
			flex-direction: column;
			align-items: center; }
		&-item {
			width: 20%;
			min-width: 20%;
			display: flex;
			justify-content: center;
			align-content: center;
			align-items: center;
			flex-direction: column;
			border: 1px solid rgba(0,0,0,.2);
			padding: 1rem;
			border-radius: 6px;
			transition: all .2s ease-in-out;
			margin: 2rem;
			@media screen and (max-width: 1280px) {
				width: 40%;
				flex-direction: column;
				margin: .5rem; }
			@media screen and (max-width: 640px) {
				width: 80%;
				flex-direction: column;
				margin: .5rem; }

			&:hover {
				box-shadow: 5px 5px 20px rgba(0,0,0,.3);
				cursor: pointer;
				transform: translate(-2px, -2px); }
			&-img {
				display: flex;
				justify-content: center;
				align-content: center;
				align-items: center;
				padding-bottom: 3rem;
				& img {
					max-width: 100%;
					width: 100%;
					min-width: 150px;
					min-height: 150px; } }
			&-title {
				font-size: 2rem;
				text-align: center;
				@media screen and (max-width: 1280px) {
					font-size: 1.2rem; } } } }

	h1, h2, h3 {
		text-align: center;
		margin: 30px 0; }
	blockquote {
		padding: 10px 10px 5px 20px;
		border-left: 4px solid #3898ce;
		margin: 20px 0; }
	blockquote>p {
		font-style: italic;
		margin-top: 0; }
	li {
		margin-bottom: 10px;
		margin-left: 20px; }
	ul, ol {
		margin-left: 20px;
		margin-top: 10px;
		@media screen and (max-width: 980px) {
			margin-left: 10px;
			ul, ol {
				margin-left: 0;
				li {
					margin-left: 10px; } } } }
	a:active, a {
		text-decoration: none;
		color: #03a9f4; }
	a.btn {
		color: #fff; }
	a:hover {
		text-decoration: underline; }
	.view_xml {
		width: 95%;
		max-height: 60vh;
		overflow: auto;
		background: #eaeaea;
		font-size: 16px; }
	.wrapper {
		margin-right: auto;
		margin-left: auto;
		max-width: 1140px;
		padding-right: 10px;
		padding-left: 10px; }
	.return {
		position: fixed;
		right: 7%;
		bottom: 7%;
		font-size: 16px;
		background: #eaeaea;
		border: 1px solid #a0a0a0;
		padding: 5px;
		min-width: 50px;
		text-align: center; }
	@media (max-width: 800px) {
		body {
			font-size: 18px;
			line-height: 1.4; } }

	@media (max-width: 480px) {
		body {
			font-size: 16px;
			line-height: 1.3; } }

	.button_overlay {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0; }
	.write-btn {
		height: 50px;
		max-width: 20%;
		min-width: 72px;
		position: relative;
		box-shadow: 2px 2px 10px rgba(0,0,0,.4);
		display: flex;
		justify-content: center;
		align-items: center;
		align-content: center;
		& .copy-icon, & .done-icon {
			display: flex;
			justify-content: center;
			align-items: center;
			align-content: center; } }
	.copied_command {
		display: flex;
		align-items: center;
		margin: 10px 0; }
	.clickable_command {
		overflow: auto;
		white-space: nowrap;
		display: flex;
		align-items: center;
		padding-left: 20px;
		height: 50px;
		width: 80%;
		background: #eaeaea;
		font-size: 16px;
		box-shadow: 2px 2px 10px rgba(0,0,0,.4); }
	.trvmt_schema {
		width: 100%; } }


.faq-content__menu-list {
	& ol {
		font-size: 1rem;
		border-left: 4px solid #1581be;
		margin-left: 0;
		padding: 5px 20px 0px 20px; }
	h2 {
		text-align: left; } }
/* END Faq */

/* BEGIN Trovemat-dashboard */
.trovemat-dashboard {
	display: flex;
	justify-content: center;
	background-color: #f2f3f8;
	padding: 40px 0;
	& input, select {
		font-size: 14px;
		height: 40px; }
	& .wrapper {
		background-color: #fff;
		width: 80%;
		display: flex;
		flex-direction: column;
		padding: 20px;
		@media screen and (max-width: 640px) {
			width: 100%;
			padding: 10px; } }
	&__menu {
		display: flex;
		flex-wrap: nowrap;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
		margin: 10px 0;
		&-item {
			display: flex;
			justify-content: center;
			padding: 20px 40px;
			background-color: #EEEEEE;
			border-left: 4px solid #ccc;
			cursor: pointer;
			margin-left: -4px;
			&--active {
				border-left: 4px solid #3898ce;
				background-color: #ccc; } } }
	&__conf {
		display: flex;
		justify-content: flex-start;
		align-content: stretch;
		flex-wrap: wrap;
		@media (max-width: 1024px) {
			flex-direction: column;
			justify-content: center;
			align-content: center;
			align-items: center; }

		&--h {
			display: flex;
			justify-content: flex-start;
			align-content: stretch;
			flex-wrap: wrap;
			flex-direction: column;
			@media (max-width: 1024px) {
				flex-direction: column;
				justify-content: center;
				align-content: center;
				align-items: center; } }

		&-block {
			border-radius: 6px;
			border: 1px solid #eee;
			transition: all .2s ease-in-out;
			width: 31%;
			position: relative;
			margin: 15px 0;
			&--h {
				width: 98.5%; }
			&-container {
				display: flex;
				flex-wrap: wrap; }
			&--blur {
				filter: blur(1px) grayscale(1);
				position: relative; }
			@media (max-width: 1024px) {
				width: 100%; }
			@media screen and (min-width: 1024px) {
				&:hover {
					box-shadow: 5px 5px 20px rgba(0,0,0,.3);
					transform: translate(-2px, -2px); } }
			&-blur {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: #FFFFFF;
				opacity: .3;
				z-index: 1000; }
			& h3.subheader {
				padding: 30px 20px 30px 20px;
				font-size: 1.4rem;
				border-bottom: 2px solid #ccc;
				opacity: .8;
				cursor: pointer;
				margin: 0;
				display: flex;
				justify-content: space-between;
				align-content: center;
				align-items: center;
				background-repeat: no-repeat;
				background-position: 98% 50%;
				background-size: 48px 48px;
				&__arrow--down {
					background-image: url(/img/dashboard/arrow_down.svg); }
				&__arrow--up {
					background-image: url(/img/dashboard/arrow_up.svg); } }
			&-label {
				font-size: 1rem;
				padding: 3px 0;
				font-weight: bold; }
			&-submit {
				text-align: center;
				margin: 20px 0; }
			& input, select {
				width: 100%;
				margin-bottom: 20px; }
			& select {
				padding: 5px 20px;
				background-color: #fafafa;
				& option {
					padding: 20px;
					border: 1px solid rgba(0, 0, 0, 0.075); } } } }
	&__summary {
		&-devices {
			display: flex;
			justify-content: flex-start;
			padding: 10px;
			border-radius: 6px;
			border: 1px solid #eee;
			&-item {
				display: flex;
				flex-direction: row;
				justify-content: flex-start;
				align-content: center;
				align-items: center;
				margin: 3px 0;
				& b {
					margin: 0 6px; } } } }
	&__admin {
		&-camera {
			width: 100vw;
			height: 100vh;
			padding: 30px;
			background-color: rgba(0,0,0,0.5);
			display: flex;
			justify-content: center;
			align-items: center;
			align-content: center;
			position: fixed;
			top: 0;
			left: 0;
			z-index: 300;
			&-title {
				font-size: 1.4rem;
				text-align: center;
				margin-bottom: 1rem; }

			&-result {
				margin-top: 20px;
				background-color: #EEEEEE;
				border-radius: 6px;
				border: 1px solid #999;
				display: none; }
			&-container {
				width: 80vw;
				max-width: 500px;
				background-color: #FFFFFF;
				border-radius: 6px;
				box-shadow: 5px 5px 20px rgba(0,0,0,.3);
				padding: 30px;
				position: relative;
				&-controls {
					display: flex;
					justify-content: center;
					margin-top: 30px; } } }
		&-progress {
			display: flex;
			justify-content: center;
			align-items: center;
			align-content: center;
			flex-direction: column;
			& div {
				margin: 15px 0; } } }
	&__btn {
		border: none;
		padding: 10px;
		background-color: transparent;
		opacity: .8;
		transition: all .2s ease-in-out;
		&:hover:not(.trovemat-dashboard__btn--disabled) {
			opacity: 1; }
		&:hover:not(.trovemat-dashboard__btn--disabled) img {
			box-shadow: 1px 1px 10px rgba(0,0,0,.1); }
		&:active:not(.trovemat-dashboard__btn--disabled) img {
			box-shadow: 1px 1px 10px rgba(0,0,0,.1);
			transform: translate(2px, 2px); }
		&-img {
			border-radius: 40px;
			width: 40px;
			height: 40px;
			transition: all .2s ease-in-out; }
		&--disabled {
			filter: grayscale(1);
			opacity: .5; } }
	&__advanced {
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
		align-content: center;
		transition: all .2s ease-in-out;
		&-apply {
			visibility: hidden; }
		&-field {
			flex: 1;
			padding: 0 10px;
			display: flex;
			flex-direction: column;
			align-content: center;
			align-items: center;
			justify-content: center;
			&-default {
				color: #999;
				font-size: .8rem;
				margin-top: 5px;
				text-align: center; } } }
	& input, select {
		margin-bottom: 0; }
	&__command-container {
		background-color: transparent;
		transition: all .2s ease-in-out;
		padding: 10px 5px 15px 5px;
		border-bottom: 1px solid #efefef;
		border-radius: 6px;
		&--h {
			width: 49%;
			margin: .5%;
			@media screen and (max-width: 1024px) {
				width: 48%; }
			@media screen and (max-width: 768px) {
				width: 100%; } }
		&:hover {
			background-color: #ebf5ff;
			border-bottom: 1px solid transparent; } } }

.trovemat-marker {
	width: 20px;
	height: 20px;
	margin-left: 20px;
	display: inline-block;
	&--active {
		background-color: #ccd520; }
	&--inactive {
		background-color: #FF7049; } }
.trovemat-list {
	display: flex;
	flex-direction: column;
	padding: 15px;
	margin: 15px 0;
	position: relative;
	&__item {
		border: 1px solid #999999;
		padding: 3px;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		align-content: center;
		margin: 5px 0;
		transition: all .2s linear;
		height: auto;
		@media (max-width: 600px) {
			flex-direction: inherit; }
		&:hover {
			background-color: #fafafa; }
		&-icons {
			margin-left: 10px;
			border: 1px solid #999;
			border-radius: 6px;
			transition: all .2s ease-in-out;
			@media (max-width: 600px) {
				margin-top: 15px;
				margin-bottom: 15px; }
			&:hover {
				background-color: #eee;
				box-shadow: 1px 1px 10px rgba(0,0,0,.1);
				transform: translate(1px, 1px); } }

		&-status {
			width: 1rem;
			height: 1rem;
			background-color: #eee;
			border: 1px solid #999;
			margin: 0 3px 0 15px;
			cursor: pointer;
			@media (max-width: 600px) {
				margin-bottom: 10px; }
			&--active {
				background-color: #ccd520; }
			&--inactive {
				background-color: #FF7049; } }
		&-name {
			font-weight: bold;
			font-size: 1.3rem;
			margin: 0 5px;
			@media (max-width: 600px) {
				margin-bottom: 10px; } }
		&-location {
			margin: 0 5px;
			@media (max-width: 600px) {
				margin-bottom: 10px; } }
		&-ip {
			margin: 0 5px;
			font-size: .8rem;
			color: #999;
			@media (max-width: 600px) {
				margin-bottom: 10px; } }
		&-owner {
			margin: 0 5px;
			@media (max-width: 600px) {
				margin-bottom: 10px; } }
		&-state {
			margin: 0 5px;
			flex: 1;
			text-align: right;
			color: #999;
			@media (max-width: 600px) {
				margin-bottom: 10px;
				padding-right: 5px; } }
		&-menu {
			margin: 0 15px 0 5px;
			cursor: pointer;
			position: relative;
			&:hover .trovemat-list__item-menu-container {
				display: flex; }
			&-img {
				width: 16px;
				height: 16px; }
			&-container {
				width: auto;
				z-index: 3;
				top: 20px;
				left: -5px;
				display: none;
				flex-direction: column;
				border: 1px solid #999;
				position: absolute;
				background-color: #fff;
				border-radius: 6px;
				&--show {
					display: flex; }
				&-item {
					padding: 5px;
					transition: all .2s linear;
					min-width: 150px;
					font-size: 1.2rem;
					&:hover {
						background-color: #eee; } } } } } }

.trovemat-keys {
	padding: 10px 0;
	table {
		table-layout: fixed;
		th, td {
			padding: 8px;
			border: #aaa 1px solid; } }
	ul {
		li {
			padding-bottom: 10px;
			line-height: 24px; } }
	&_remove {
		cursor: pointer;
		width: 14px; } }
.trovemat-form {
	position: relative;

	input.trovemat-form__input {
		background: #fafafa;
		border-radius: 2px;
		height: 35px;
		font-size: 16px;
		color: #333333;
		padding-left: 8px;
		padding-right: 8px;
		box-shadow: none;
		letter-spacing: 1px;
		border: 1px solid rgba(0, 0, 0, 0.075);

		&_digit {
			max-width: 112px; }
		&_text {
			max-width: 250px; } }

	.unvalidated {
		border: red 1px solid; }

	&_modal {
		padding: 20px;
		text-align: center; }

	&__row {
		padding: 0 0 18px;

		&:after {
			content: '';
			display: block;
			clear: both; }

		&_left {
			width: 250px;
			float: left;
			line-height: 35px;
			text-align: left;
			@media (max-width: 600px) {
				float: none;
				margin: 0 auto; } }

		&_right {
			width: 250px;
			float: left;
			text-align: left;
			@media (max-width: 600px) {
				float: none;
				margin: 0 auto; } } } }

.trovemat-icon {
	width: 24px;
	height: 24px;
	transition: all .2s ease-in-out;
	cursor: pointer;
	&__disabled {
		opacity: .5;
		filter: grayscale(1);
		animation: rotating 3s linear infinite; } }
@keyframes rotating {
	from {
		transform: rotate(0deg); }
	to {
		transform: rotate(360deg); } }
/* END Trovemat-dashboard */

/* BEGIN in-progress loader */
.lds-grid {
	display: inline-block;
	position: relative;
	width: 64px;
	height: 64px; }

.lds-grid div {
	position: absolute;
	width: 13px;
	height: 13px;
	border-radius: 50%;
	background: #3898ce;
	animation: lds-grid 1.2s linear infinite; }

.lds-grid div:nth-child(1) {
	top: 6px;
	left: 6px;
	animation-delay: 0s; }

.lds-grid div:nth-child(2) {
	top: 6px;
	left: 26px;
	animation-delay: -0.4s; }

.lds-grid div:nth-child(3) {
	top: 6px;
	left: 45px;
	animation-delay: -0.8s; }
.lds-grid div:nth-child(4) {
	top: 26px;
	left: 6px;
	animation-delay: -0.4s; }
.lds-grid div:nth-child(5) {
	top: 26px;
	left: 26px;
	animation-delay: -0.8s; }

.lds-grid div:nth-child(6) {
	top: 26px;
	left: 45px;
	animation-delay: -1.2s; }

.lds-grid div:nth-child(7) {
	top: 45px;
	left: 6px;
	animation-delay: -0.8s; }
.lds-grid div:nth-child(8) {
	top: 45px;
	left: 26px;
	animation-delay: -1.2s; }

.lds-grid div:nth-child(9) {
	top: 45px;
	left: 45px;
	animation-delay: -1.6s; }

@keyframes lds-grid {
	0%, 100% {
		opacity: 1; }

	50% {
		opacity: 0.5; } }
/* END in-progress loader */
.withdraw-camera {
	display: flex;
	justify-content: center;
	align-items: center;
	align-content: center;
	z-index: 300;
	&-title {
		font-size: 1.4rem;
		text-align: center;
		margin-bottom: 1rem; }

	&-result {
		margin-top: 20px;
		background-color: #EEEEEE;
		border-radius: 6px;
		border: 1px solid #999;
		display: none; }
	&-container {
		width: 80vw;
		max-width: 500px;
		background-color: #FFFFFF;
		position: relative;
		&-controls {
			display: flex;
			justify-content: center;
			margin: 30px 0; } } }

.qr-scan {
	display: flex;
	& img {
		width: 64px;
		height: 64px;
		cursor: pointer;
		border: 1px solid rgba(0, 0, 0, 0.075); } }

.trovemat-dashboard-loader {
	display: flex;
	justify-content: center;
	align-items: center;
	align-content: center;
	position: relative;
	&__title {
		font-size: 1.2rem;
		color: #999;
		margin-bottom: 60px; } }

.trovemat-dashboard__payments {
	table {
		tr {
			td, th {
				padding: 4px 10px;
				border: #aaa 1px solid;
				&:first-child {
					font-weight: bold; } }
			th {
				color: #bbb; }

			td {

				&.trovemat-dashboard__payments__date {
					color: #2a7ba9;
					background-color: #fff;
					font-size: 1.2rem; } }
			&.trovemat-dashboard__payments__list {

				&:hover {
					box-shadow: 0 0 16px 0 rgba(204,204,204,0.5); }
				&--error {
					& td {
						background-color: rgba(255,0,0,.2) !important; } } } } }

	.trovemat-dashboard__table {
		overflow-x: auto;
		max-width: 100%;
		& th {
			background-color: #2a7ba9;
			border: 1px solid #fff;
			color: #fff;
			font-weight: bold; }
		& td {
			background-color: #eee;
			border: 1px solid #fff;
			transition: all .2s ease-in-out; }
		& tr {
			&:not(.trovemat-dashboard__payments__date):hover td {
				background-color: #ddecff; } }
		& tfoot {
			& tr {
				& td {
					background-color: #ddecff; } } } }
	.trovemat-dashboard__search {
		padding-bottom: 10px;

		.react-datepicker__input-container {

			input {
				max-width: 150px; } } } }


.brakepoint {
	color: transparent;
	margin: 0; }

.fee-container {
	display: flex;
	justify-content: center;
	align-content: center;
	align-items: center;
	color: #000;
	width: 100%;
	& .fee-data {
		display: flex;
		flex-direction: column; }
	& h3 {
		text-align: center; } }

.fee-modal-table {
	margin: 10px 0 30px 0;
	white-space: nowrap;
	background-color: #2a7ba9;
	border: 1px solid #2a7ba9;
	& th {
		background-color: #2a7ba9;
		color: #ffffff;
		padding: 10px 15px;
		margin: 1px;
		border: 1px solid #fff; }
	& td {
		background-color: #EEEEEE;
		padding: 6px 15px;
		margin: 1px;
		border: 1px solid #fff; }
	@media screen and (max-width: 520px) {
		font-size: .7rem;
		& th {
			padding: 5px 6px; }
		& td {
			padding: 5px 6px; } } }
.fee-tables {
	display: flex;
	justify-content: space-around;
	flex-direction: row;
	width: 100%;
	@media screen and (max-width: 520px) {
		flex-direction: column;
		justify-content: center;
		align-items: center;
		align-content: center; }
	&__title {
		margin: 15px 0;
		font-size: 1.3rem;
		text-align: center; } }
.footer {
	& .modal__body {
		color: #000000;
		margin: 0; } }
.faq-image-description {
	font-style: italic;
	font-size: .9rem;
	margin-top: 10px;
	margin-bottom: 18px;
	margin-left: 50px; }
.press-Yes {
	margin-top: 23px;
	margin-bottom: 23px; }
.code {
	font-weight: bold; }
.mid {
	text-align: center; }


.d-checkbox {
	width: 50px;
	height: 24px;
	background-color: #eee;
	border-radius: 15px;
	border: 1px solid #ccc;
	transition: all 1s linear;
	position: relative;
	&__state {
		position: absolute;
		width: 28px;
		height: 28px;
		box-shadow: 1px 1px 10px rgba(0,0,0,.5);
		background-color: #FF7049;
		border-radius: 90px;
		top: -3px;
		left: -2px;
		transition: all .4s ease-in-out;
		&--active {
			left: 24px;
			background-color: #ccd520; } }
	& input {
		position: absolute;
		left: -9999px; } }
.password-change {
	display: flex;
	align-items: center;
	align-content: center;
	justify-content: center;
	width: 100vw;
	height: 100vh;
	background-color: #eee;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 100000;
	overflow-y: scroll;
	&__wrapper {
		width: 50vw;
		display: flex;
		align-items: center;
		align-content: center;
		justify-content: center;
		border: 1px solid #ccc;
		box-shadow: 2px 2px 10px rgba(0,0,0,.4);
		padding: 30px;
		border-radius: 6px;
		background-color: #fff;
		transition: all .1s ease-in-out;
		@media screen and (max-width: 768px) {
			width: 80%; }
		@media screen and (max-width: 512px) {
			width: 100vw;
			height: 100vh; }
		&--blur {
			filter: blur(2px) grayscale(1); } }
	&__container {
		display: flex;
		align-items: center;
		align-content: center;
		justify-content: center;
		flex-direction: column;
		width: 100%;
		& h3 {
			text-align: center;
			margin-bottom: 20px; }
		& input {
			margin-bottom: 20px;
			width: 60%;
			background: #fafafa;
			border-radius: 2px;
			height: 35px;
			font-size: 16px;
			color: #333333;
			padding-left: 8px;
			padding-right: 8px;
			box-shadow: none;
			letter-spacing: 1px;
			border: 1px solid rgba(0, 0, 0, 0.075);
			@media screen and (max-width: 768px) {
				width: 90%; } }
		& label {
			color: rgba(0,0,0,.5);
			font-size: 1.4rem;
			text-align: left;
			display: block;
			width: 60%;
			@media screen and (max-width: 768px) {
				width: 90%; } } }
	&__errors {
		color: #ff7049;
		font-size: 1rem;
		text-align: center;
		display: block;
		width: 60%;
		@media screen and (max-width: 768px) {
			width: 90%; } } }
.__changed {
	background-color: #f5ffe9;
	& .trovemat-dashboard__advanced-apply {
		visibility: visible; } }

.device-commands {
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;
	flex-direction: row;
	position: relative;
	&--blur {
 }		//filter: blur(1px)
	&__blur {
		position: absolute;
		display: flex;
		justify-content: center;
		align-content: center;
		align-items: center;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		&--hidden {
			display: none; } }
	&__item {
		border: 1px solid rgba(0, 0, 0, 0.075);
		border-radius: 6px;
		transition: all .2s ease-in-out;
		display: flex;
		justify-content: center;
		align-items: center;
		align-content: center;
		width: 45%;
		padding: 40px 60px;
		cursor: pointer;
		margin: 15px;
		&-title {
			font-size: 1.3rem;
			text-align: center; }
		&:hover {
			box-shadow: 1px 1px 10px rgba(0, 0 ,0, .2);
			transform: translate(2px, 2px); } } }

.payments-loader {
	display: flex;
	justify-content: center;
	align-items: center;
	align-content: center;
	width: 100%;
	padding: 60px;
	position: relative; }

.email-checker {
	display: flex;
	width: 100vw;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	overflow: hidden;
	z-index: 10000;
	justify-content: center;
	align-items: center;
	align-content: center;
	background-color: #fff;
	&__wrapper {
		display: flex;
		padding: 30px;
		border-radius: 6px;
		border: 1px solid #ccc;
		width: 40%;
		flex-direction: column;
		@media screen and (max-width: 1024px) {
			width: 60%; }
		@media screen and (max-width: 768px) {
			width: 70%; }
		@media screen and (max-width: 520px) {
			width: 80%;
			border: none; } }
	&__header {
		font-size: 1.8rem;
		color: #000;
		margin: 7px 7px 30px 7px; }
	&__content {
		display: flex;
		flex-direction: column;
		width: 100%; }
	&__field {
		width: 100%;
		margin: 15px 7px; } }

.wallet-transfer-container {
	.choose-withdraw-type {
		height: 45px;
		background: #fff;
		border-radius: 2px;
		position: relative;
		padding: 0 10px;
		@include box-shadow(0 7px 29px 0px rgba(88, 87, 104, 0.18));

		&:before {
			content: '';
			width: 1px;
			background: #ededed;
			height: 28px;
			position: absolute;
			display: block;
			left: 0;
			right: 0;
			bottom: 0;
			top: 0;
			margin: auto; }

		&:after {
			content: '';
			display: block;
			height: 12px;
			width: 12px;
			transform: rotate(45deg);
			position: absolute;
			left: 0;
			right: 0;
			margin: auto;
			top: 39px;
			background: #fff; } }

	.choose-withdraw-type__button {
		border: none;
		width: 50%;
		float: left;
		color: rgba(0, 0, 0, 0.4);
		text-align: center;
		display: block;
		height: 48px;
		line-height: 48px;
		font-size: 16px;

		&:hover:not(.active) {
			color: $button-blue; }
		&:hover {
			color: rgba(0, 0, 0, 0.4); }
		&.active {
			font-weight: 600;
			color: #000; } }

	.qr-scan,
	.phone-input {
		width: 100%;
		margin-top: 15px;
		&--valid {
			background-color: #b8f4b8;
			transition: 0.5s all; } } }
