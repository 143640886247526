.cssload-loader, .loading_hover+.cssload-loader {
	top: 0px;
	bottom: 0px;
	width: 50px;
	height: 50px;
	margin: auto !important;
	left: 0px;
	right: 0px;
	z-index: 150; }


.cssload-loader .cssload-dot {
	background-color: #70bada; }

.cssload-loader * {
	box-sizing: border-box {
		-o-box-sizing: border-box;
		-ms-box-sizing: border-box;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box; } }


.cssload-loader {
	position: absolute;
	right: 0;
	top: 0;
	left: 0;
	right: 0;
	margin: auto;
	overflow: hidden;
	width: 55px;
	height: 55px;
	perspective: 165px {
		-o-perspective: 165px;
		-ms-perspective: 165px;
		-webkit-perspective: 165px;
		-moz-perspective: 165px; }
	transform-style: perserve-3d {
		-o-transform-style: perserve-3d;
		-ms-transform-style: perserve-3d;
		-webkit-transform-style: perserve-3d;
		-moz-transform-style: perserve-3d; } }


.cssload-dots {
	position: absolute;

	width: 100%;
	height: 100%;

	perspective: 165px {
		-o-perspective: 165px;
		-ms-perspective: 165px;
		-webkit-perspective: 165px;
		-moz-perspective: 165px; }
	transform-style: perserve-3d {
		-o-transform-style: perserve-3d;
		-ms-transform-style: perserve-3d;
		-webkit-transform-style: perserve-3d;
		-moz-transform-style: perserve-3d; }

	animation: cssload-dots 6900ms cubic-bezierrgb(0,0,0) infinite {
		-o-animation: cssload-dots 6900ms cubic-bezierrgb(0,0,0) infinite;
		-ms-animation: cssload-dots 6900ms cubic-bezierrgb(0,0,0) infinite;
		-webkit-animation: cssload-dots 6900ms cubic-bezierrgb(0,0,0) infinite;
		-moz-animation: cssload-dots 6900ms cubic-bezierrgb(0,0,0) infinite; } }

.cssload-dot {
	position: absolute;
	top: 50%;
	left: 50%;

	width: 5px;
	height: 5px;

	margin-top: -3px;
	margin-left: -3px;

	border-radius: 275px;

	background-color: #70bada;

	transform-style: perserve-3d {
		-o-transform-style: perserve-3d;
		-ms-transform-style: perserve-3d;
		-webkit-transform-style: perserve-3d;
		-moz-transform-style: perserve-3d; }

	animation: cssload-dot 1725ms cubic-bezier(.7,.05,.39,.88) infinite {
		-o-animation: cssload-dot 1725ms cubic-bezier(.7,.05,.39,.88) infinite;
		-ms-animation: cssload-dot 1725ms cubic-bezier(.7,.05,.39,.88) infinite;
		-webkit-animation: cssload-dot 1725ms cubic-bezier(.7,.05,.39,.88) infinite;
		-moz-animation: cssload-dot 1725ms cubic-bezier(.7,.05,.39,.88) infinite; } }


.cssload-dot:nth-child(1) {
	transform: translateY(-11px) {
		-o-transform: translateY(-11px);
		-ms-transform: translateY(-11px);
		-webkit-transform: translateY(-11px);
		-moz-transform: translateY(-11px); } }


.cssload-dot:nth-child(2) {
	transform: translateX(-5px) translateY(-5px) {
		-o-transform: translateX(-5px) translateY(-5px);
		-ms-transform: translateX(-5px) translateY(-5px);
		-webkit-transform: translateX(-5px) translateY(-5px);
		-moz-transform: translateX(-5px) translateY(-5px); }
	animation-name: cssload-dot2 {
		-o-animation-name: cssload-dot2;
		-ms-animation-name: cssload-dot2;
		-webkit-animation-name: cssload-dot2;
		-moz-animation-name: cssload-dot2; } }


.cssload-dot:nth-child(3) {
	transform: translateX(5px) translateY(-5px) {
		-o-transform: translateX(5px) translateY(-5px);
		-ms-transform: translateX(5px) translateY(-5px);
		-webkit-transform: translateX(5px) translateY(-5px);
		-moz-transform: translateX(5px) translateY(-5px); }
	animation-name: cssload-dot3 {
		-o-animation-name: cssload-dot3;
		-ms-animation-name: cssload-dot3;
		-webkit-animation-name: cssload-dot3;
		-moz-animation-name: cssload-dot3; } }


.cssload-dot:nth-child(4) {
	transform: translateX(-11px) translateY(0px) {
		-o-transform: translateX(-11px) translateY(0px);
		-ms-transform: translateX(-11px) translateY(0px);
		-webkit-transform: translateX(-11px) translateY(0px);
		-moz-transform: translateX(-11px) translateY(0px); }
	animation-name: cssload-dot4 {
		-o-animation-name: cssload-dot4;
		-ms-animation-name: cssload-dot4;
		-webkit-animation-name: cssload-dot4;
		-moz-animation-name: cssload-dot4; } }


.cssload-dot:nth-child(5) {
	transform: translateX(0px) translateY(0px) {
		-o-transform: translateX(0px) translateY(0px);
		-ms-transform: translateX(0px) translateY(0px);
		-webkit-transform: translateX(0px) translateY(0px);
		-moz-transform: translateX(0px) translateY(0px); }
	animation-name: cssload-dot5 {
		-o-animation-name: cssload-dot5;
		-ms-animation-name: cssload-dot5;
		-webkit-animation-name: cssload-dot5;
		-moz-animation-name: cssload-dot5; } }


.cssload-dot:nth-child(6) {
	transform: translateX(11px) translateY(0px) {
		-o-transform: translateX(11px) translateY(0px);
		-ms-transform: translateX(11px) translateY(0px);
		-webkit-transform: translateX(11px) translateY(0px);
		-moz-transform: translateX(11px) translateY(0px); }
	animation-name: cssload-dot6 {
		-o-animation-name: cssload-dot6;
		-ms-animation-name: cssload-dot6;
		-webkit-animation-name: cssload-dot6;
		-moz-animation-name: cssload-dot6; } }


.cssload-dot:nth-child(7) {
	transform: translateX(-5px) translateY(5px) {
		-o-transform: translateX(-5px) translateY(5px);
		-ms-transform: translateX(-5px) translateY(5px);
		-webkit-transform: translateX(-5px) translateY(5px);
		-moz-transform: translateX(-5px) translateY(5px); }
	animation-name: cssload-dot7 {
		-o-animation-name: cssload-dot7;
		-ms-animation-name: cssload-dot7;
		-webkit-animation-name: cssload-dot7;
		-moz-animation-name: cssload-dot7; } }


.cssload-dot:nth-child(8) {
	transform: translateX(5px) translateY(5px) {
		-o-transform: translateX(5px) translateY(5px);
		-ms-transform: translateX(5px) translateY(5px);
		-webkit-transform: translateX(5px) translateY(5px);
		-moz-transform: translateX(5px) translateY(5px); }
	animation-name: cssload-dot8 {
		-o-animation-name: cssload-dot8;
		-ms-animation-name: cssload-dot8;
		-webkit-animation-name: cssload-dot8;
		-moz-animation-name: cssload-dot8; } }


.cssload-dot:nth-child(9) {
	transform: translateX(0px) translateY(11px) {
		-o-transform: translateX(0px) translateY(11px);
		-ms-transform: translateX(0px) translateY(11px);
		-webkit-transform: translateX(0px) translateY(11px);
		-moz-transform: translateX(0px) translateY(11px); }
	animation-name: cssload-dot9 {
		-o-animation-name: cssload-dot9;
		-ms-animation-name: cssload-dot9;
		-webkit-animation-name: cssload-dot9;
		-moz-animation-name: cssload-dot9; } }
