// SASS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home
//   width: $icon-home-width
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites-small
$ad-name: 'ad';
$ad-x: 40px;
$ad-y: 0px;
$ad-offset-x: -40px;
$ad-offset-y: 0px;
$ad-width: 30px;
$ad-height: 15px;
$ad-total-width: 510px;
$ad-total-height: 490px;
$ad-image: 'sprite.png';
$ad: (40px, 0px, -40px, 0px, 30px, 15px, 510px, 490px, 'sprite.png', 'ad', );
$ae-name: 'ae';
$ae-x: 320px;
$ae-y: 325px;
$ae-offset-x: -320px;
$ae-offset-y: -325px;
$ae-width: 30px;
$ae-height: 15px;
$ae-total-width: 510px;
$ae-total-height: 490px;
$ae-image: 'sprite.png';
$ae: (320px, 325px, -320px, -325px, 30px, 15px, 510px, 490px, 'sprite.png', 'ae', );
$af-name: 'af';
$af-x: 0px;
$af-y: 25px;
$af-offset-x: 0px;
$af-offset-y: -25px;
$af-width: 30px;
$af-height: 15px;
$af-total-width: 510px;
$af-total-height: 490px;
$af-image: 'sprite.png';
$af: (0px, 25px, 0px, -25px, 30px, 15px, 510px, 490px, 'sprite.png', 'af', );
$ag-name: 'ag';
$ag-x: 40px;
$ag-y: 25px;
$ag-offset-x: -40px;
$ag-offset-y: -25px;
$ag-width: 30px;
$ag-height: 15px;
$ag-total-width: 510px;
$ag-total-height: 490px;
$ag-image: 'sprite.png';
$ag: (40px, 25px, -40px, -25px, 30px, 15px, 510px, 490px, 'sprite.png', 'ag', );
$ai-name: 'ai';
$ai-x: 0px;
$ai-y: 50px;
$ai-offset-x: 0px;
$ai-offset-y: -50px;
$ai-width: 30px;
$ai-height: 15px;
$ai-total-width: 510px;
$ai-total-height: 490px;
$ai-image: 'sprite.png';
$ai: (0px, 50px, 0px, -50px, 30px, 15px, 510px, 490px, 'sprite.png', 'ai', );
$al-name: 'al';
$al-x: 40px;
$al-y: 50px;
$al-offset-x: -40px;
$al-offset-y: -50px;
$al-width: 30px;
$al-height: 15px;
$al-total-width: 510px;
$al-total-height: 490px;
$al-image: 'sprite.png';
$al: (40px, 50px, -40px, -50px, 30px, 15px, 510px, 490px, 'sprite.png', 'al', );
$am-name: 'am';
$am-x: 80px;
$am-y: 0px;
$am-offset-x: -80px;
$am-offset-y: 0px;
$am-width: 30px;
$am-height: 15px;
$am-total-width: 510px;
$am-total-height: 490px;
$am-image: 'sprite.png';
$am: (80px, 0px, -80px, 0px, 30px, 15px, 510px, 490px, 'sprite.png', 'am', );
$ao-name: 'ao';
$ao-x: 80px;
$ao-y: 25px;
$ao-offset-x: -80px;
$ao-offset-y: -25px;
$ao-width: 30px;
$ao-height: 15px;
$ao-total-width: 510px;
$ao-total-height: 490px;
$ao-image: 'sprite.png';
$ao: (80px, 25px, -80px, -25px, 30px, 15px, 510px, 490px, 'sprite.png', 'ao', );
$aq-name: 'aq';
$aq-x: 80px;
$aq-y: 50px;
$aq-offset-x: -80px;
$aq-offset-y: -50px;
$aq-width: 30px;
$aq-height: 15px;
$aq-total-width: 510px;
$aq-total-height: 490px;
$aq-image: 'sprite.png';
$aq: (80px, 50px, -80px, -50px, 30px, 15px, 510px, 490px, 'sprite.png', 'aq', );
$ar-name: 'ar';
$ar-x: 0px;
$ar-y: 75px;
$ar-offset-x: 0px;
$ar-offset-y: -75px;
$ar-width: 30px;
$ar-height: 15px;
$ar-total-width: 510px;
$ar-total-height: 490px;
$ar-image: 'sprite.png';
$ar: (0px, 75px, 0px, -75px, 30px, 15px, 510px, 490px, 'sprite.png', 'ar', );
$as-name: 'as';
$as-x: 40px;
$as-y: 75px;
$as-offset-x: -40px;
$as-offset-y: -75px;
$as-width: 30px;
$as-height: 15px;
$as-total-width: 510px;
$as-total-height: 490px;
$as-image: 'sprite.png';
$as: (40px, 75px, -40px, -75px, 30px, 15px, 510px, 490px, 'sprite.png', 'as', );
$at-name: 'at';
$at-x: 80px;
$at-y: 75px;
$at-offset-x: -80px;
$at-offset-y: -75px;
$at-width: 30px;
$at-height: 15px;
$at-total-width: 510px;
$at-total-height: 490px;
$at-image: 'sprite.png';
$at: (80px, 75px, -80px, -75px, 30px, 15px, 510px, 490px, 'sprite.png', 'at', );
$au-name: 'au';
$au-x: 120px;
$au-y: 0px;
$au-offset-x: -120px;
$au-offset-y: 0px;
$au-width: 30px;
$au-height: 15px;
$au-total-width: 510px;
$au-total-height: 490px;
$au-image: 'sprite.png';
$au: (120px, 0px, -120px, 0px, 30px, 15px, 510px, 490px, 'sprite.png', 'au', );
$aw-name: 'aw';
$aw-x: 120px;
$aw-y: 25px;
$aw-offset-x: -120px;
$aw-offset-y: -25px;
$aw-width: 30px;
$aw-height: 15px;
$aw-total-width: 510px;
$aw-total-height: 490px;
$aw-image: 'sprite.png';
$aw: (120px, 25px, -120px, -25px, 30px, 15px, 510px, 490px, 'sprite.png', 'aw', );
$ax-name: 'ax';
$ax-x: 120px;
$ax-y: 50px;
$ax-offset-x: -120px;
$ax-offset-y: -50px;
$ax-width: 30px;
$ax-height: 15px;
$ax-total-width: 510px;
$ax-total-height: 490px;
$ax-image: 'sprite.png';
$ax: (120px, 50px, -120px, -50px, 30px, 15px, 510px, 490px, 'sprite.png', 'ax', );
$az-name: 'az';
$az-x: 120px;
$az-y: 75px;
$az-offset-x: -120px;
$az-offset-y: -75px;
$az-width: 30px;
$az-height: 15px;
$az-total-width: 510px;
$az-total-height: 490px;
$az-image: 'sprite.png';
$az: (120px, 75px, -120px, -75px, 30px, 15px, 510px, 490px, 'sprite.png', 'az', );
$ba-name: 'ba';
$ba-x: 0px;
$ba-y: 100px;
$ba-offset-x: 0px;
$ba-offset-y: -100px;
$ba-width: 30px;
$ba-height: 15px;
$ba-total-width: 510px;
$ba-total-height: 490px;
$ba-image: 'sprite.png';
$ba: (0px, 100px, 0px, -100px, 30px, 15px, 510px, 490px, 'sprite.png', 'ba', );
$bb-name: 'bb';
$bb-x: 40px;
$bb-y: 100px;
$bb-offset-x: -40px;
$bb-offset-y: -100px;
$bb-width: 30px;
$bb-height: 15px;
$bb-total-width: 510px;
$bb-total-height: 490px;
$bb-image: 'sprite.png';
$bb: (40px, 100px, -40px, -100px, 30px, 15px, 510px, 490px, 'sprite.png', 'bb', );
$bd-name: 'bd';
$bd-x: 80px;
$bd-y: 100px;
$bd-offset-x: -80px;
$bd-offset-y: -100px;
$bd-width: 30px;
$bd-height: 15px;
$bd-total-width: 510px;
$bd-total-height: 490px;
$bd-image: 'sprite.png';
$bd: (80px, 100px, -80px, -100px, 30px, 15px, 510px, 490px, 'sprite.png', 'bd', );
$be-name: 'be';
$be-x: 120px;
$be-y: 100px;
$be-offset-x: -120px;
$be-offset-y: -100px;
$be-width: 30px;
$be-height: 15px;
$be-total-width: 510px;
$be-total-height: 490px;
$be-image: 'sprite.png';
$be: (120px, 100px, -120px, -100px, 30px, 15px, 510px, 490px, 'sprite.png', 'be', );
$bf-name: 'bf';
$bf-x: 0px;
$bf-y: 125px;
$bf-offset-x: 0px;
$bf-offset-y: -125px;
$bf-width: 30px;
$bf-height: 15px;
$bf-total-width: 510px;
$bf-total-height: 490px;
$bf-image: 'sprite.png';
$bf: (0px, 125px, 0px, -125px, 30px, 15px, 510px, 490px, 'sprite.png', 'bf', );
$bg-name: 'bg';
$bg-x: 40px;
$bg-y: 125px;
$bg-offset-x: -40px;
$bg-offset-y: -125px;
$bg-width: 30px;
$bg-height: 15px;
$bg-total-width: 510px;
$bg-total-height: 490px;
$bg-image: 'sprite.png';
$bg: (40px, 125px, -40px, -125px, 30px, 15px, 510px, 490px, 'sprite.png', 'bg', );
$bh-name: 'bh';
$bh-x: 80px;
$bh-y: 125px;
$bh-offset-x: -80px;
$bh-offset-y: -125px;
$bh-width: 30px;
$bh-height: 15px;
$bh-total-width: 510px;
$bh-total-height: 490px;
$bh-image: 'sprite.png';
$bh: (80px, 125px, -80px, -125px, 30px, 15px, 510px, 490px, 'sprite.png', 'bh', );
$bi-name: 'bi';
$bi-x: 120px;
$bi-y: 125px;
$bi-offset-x: -120px;
$bi-offset-y: -125px;
$bi-width: 30px;
$bi-height: 15px;
$bi-total-width: 510px;
$bi-total-height: 490px;
$bi-image: 'sprite.png';
$bi: (120px, 125px, -120px, -125px, 30px, 15px, 510px, 490px, 'sprite.png', 'bi', );
$bj-name: 'bj';
$bj-x: 160px;
$bj-y: 0px;
$bj-offset-x: -160px;
$bj-offset-y: 0px;
$bj-width: 30px;
$bj-height: 15px;
$bj-total-width: 510px;
$bj-total-height: 490px;
$bj-image: 'sprite.png';
$bj: (160px, 0px, -160px, 0px, 30px, 15px, 510px, 490px, 'sprite.png', 'bj', );
$bl-name: 'bl';
$bl-x: 160px;
$bl-y: 25px;
$bl-offset-x: -160px;
$bl-offset-y: -25px;
$bl-width: 30px;
$bl-height: 15px;
$bl-total-width: 510px;
$bl-total-height: 490px;
$bl-image: 'sprite.png';
$bl: (160px, 25px, -160px, -25px, 30px, 15px, 510px, 490px, 'sprite.png', 'bl', );
$bm-name: 'bm';
$bm-x: 160px;
$bm-y: 50px;
$bm-offset-x: -160px;
$bm-offset-y: -50px;
$bm-width: 30px;
$bm-height: 15px;
$bm-total-width: 510px;
$bm-total-height: 490px;
$bm-image: 'sprite.png';
$bm: (160px, 50px, -160px, -50px, 30px, 15px, 510px, 490px, 'sprite.png', 'bm', );
$bn-name: 'bn';
$bn-x: 160px;
$bn-y: 75px;
$bn-offset-x: -160px;
$bn-offset-y: -75px;
$bn-width: 30px;
$bn-height: 15px;
$bn-total-width: 510px;
$bn-total-height: 490px;
$bn-image: 'sprite.png';
$bn: (160px, 75px, -160px, -75px, 30px, 15px, 510px, 490px, 'sprite.png', 'bn', );
$bo-name: 'bo';
$bo-x: 160px;
$bo-y: 100px;
$bo-offset-x: -160px;
$bo-offset-y: -100px;
$bo-width: 30px;
$bo-height: 15px;
$bo-total-width: 510px;
$bo-total-height: 490px;
$bo-image: 'sprite.png';
$bo: (160px, 100px, -160px, -100px, 30px, 15px, 510px, 490px, 'sprite.png', 'bo', );
$bq-name: 'bq';
$bq-x: 160px;
$bq-y: 125px;
$bq-offset-x: -160px;
$bq-offset-y: -125px;
$bq-width: 30px;
$bq-height: 15px;
$bq-total-width: 510px;
$bq-total-height: 490px;
$bq-image: 'sprite.png';
$bq: (160px, 125px, -160px, -125px, 30px, 15px, 510px, 490px, 'sprite.png', 'bq', );
$br-name: 'br';
$br-x: 0px;
$br-y: 150px;
$br-offset-x: 0px;
$br-offset-y: -150px;
$br-width: 30px;
$br-height: 15px;
$br-total-width: 510px;
$br-total-height: 490px;
$br-image: 'sprite.png';
$br: (0px, 150px, 0px, -150px, 30px, 15px, 510px, 490px, 'sprite.png', 'br', );
$bs-name: 'bs';
$bs-x: 40px;
$bs-y: 150px;
$bs-offset-x: -40px;
$bs-offset-y: -150px;
$bs-width: 30px;
$bs-height: 15px;
$bs-total-width: 510px;
$bs-total-height: 490px;
$bs-image: 'sprite.png';
$bs: (40px, 150px, -40px, -150px, 30px, 15px, 510px, 490px, 'sprite.png', 'bs', );
$bt-name: 'bt';
$bt-x: 80px;
$bt-y: 150px;
$bt-offset-x: -80px;
$bt-offset-y: -150px;
$bt-width: 30px;
$bt-height: 15px;
$bt-total-width: 510px;
$bt-total-height: 490px;
$bt-image: 'sprite.png';
$bt: (80px, 150px, -80px, -150px, 30px, 15px, 510px, 490px, 'sprite.png', 'bt', );
$bv-name: 'bv';
$bv-x: 120px;
$bv-y: 150px;
$bv-offset-x: -120px;
$bv-offset-y: -150px;
$bv-width: 30px;
$bv-height: 15px;
$bv-total-width: 510px;
$bv-total-height: 490px;
$bv-image: 'sprite.png';
$bv: (120px, 150px, -120px, -150px, 30px, 15px, 510px, 490px, 'sprite.png', 'bv', );
$bw-name: 'bw';
$bw-x: 160px;
$bw-y: 150px;
$bw-offset-x: -160px;
$bw-offset-y: -150px;
$bw-width: 30px;
$bw-height: 15px;
$bw-total-width: 510px;
$bw-total-height: 490px;
$bw-image: 'sprite.png';
$bw: (160px, 150px, -160px, -150px, 30px, 15px, 510px, 490px, 'sprite.png', 'bw', );
$by-name: 'by';
$by-x: 0px;
$by-y: 175px;
$by-offset-x: 0px;
$by-offset-y: -175px;
$by-width: 30px;
$by-height: 15px;
$by-total-width: 510px;
$by-total-height: 490px;
$by-image: 'sprite.png';
$by: (0px, 175px, 0px, -175px, 30px, 15px, 510px, 490px, 'sprite.png', 'by', );
$bz-name: 'bz';
$bz-x: 40px;
$bz-y: 175px;
$bz-offset-x: -40px;
$bz-offset-y: -175px;
$bz-width: 30px;
$bz-height: 15px;
$bz-total-width: 510px;
$bz-total-height: 490px;
$bz-image: 'sprite.png';
$bz: (40px, 175px, -40px, -175px, 30px, 15px, 510px, 490px, 'sprite.png', 'bz', );
$ca-name: 'ca';
$ca-x: 80px;
$ca-y: 175px;
$ca-offset-x: -80px;
$ca-offset-y: -175px;
$ca-width: 30px;
$ca-height: 15px;
$ca-total-width: 510px;
$ca-total-height: 490px;
$ca-image: 'sprite.png';
$ca: (80px, 175px, -80px, -175px, 30px, 15px, 510px, 490px, 'sprite.png', 'ca', );
$cc-name: 'cc';
$cc-x: 120px;
$cc-y: 175px;
$cc-offset-x: -120px;
$cc-offset-y: -175px;
$cc-width: 30px;
$cc-height: 15px;
$cc-total-width: 510px;
$cc-total-height: 490px;
$cc-image: 'sprite.png';
$cc: (120px, 175px, -120px, -175px, 30px, 15px, 510px, 490px, 'sprite.png', 'cc', );
$cd-name: 'cd';
$cd-x: 160px;
$cd-y: 175px;
$cd-offset-x: -160px;
$cd-offset-y: -175px;
$cd-width: 30px;
$cd-height: 15px;
$cd-total-width: 510px;
$cd-total-height: 490px;
$cd-image: 'sprite.png';
$cd: (160px, 175px, -160px, -175px, 30px, 15px, 510px, 490px, 'sprite.png', 'cd', );
$cf-name: 'cf';
$cf-x: 200px;
$cf-y: 0px;
$cf-offset-x: -200px;
$cf-offset-y: 0px;
$cf-width: 30px;
$cf-height: 15px;
$cf-total-width: 510px;
$cf-total-height: 490px;
$cf-image: 'sprite.png';
$cf: (200px, 0px, -200px, 0px, 30px, 15px, 510px, 490px, 'sprite.png', 'cf', );
$cg-name: 'cg';
$cg-x: 200px;
$cg-y: 25px;
$cg-offset-x: -200px;
$cg-offset-y: -25px;
$cg-width: 30px;
$cg-height: 15px;
$cg-total-width: 510px;
$cg-total-height: 490px;
$cg-image: 'sprite.png';
$cg: (200px, 25px, -200px, -25px, 30px, 15px, 510px, 490px, 'sprite.png', 'cg', );
$ch-name: 'ch';
$ch-x: 200px;
$ch-y: 50px;
$ch-offset-x: -200px;
$ch-offset-y: -50px;
$ch-width: 30px;
$ch-height: 15px;
$ch-total-width: 510px;
$ch-total-height: 490px;
$ch-image: 'sprite.png';
$ch: (200px, 50px, -200px, -50px, 30px, 15px, 510px, 490px, 'sprite.png', 'ch', );
$ci-name: 'ci';
$ci-x: 200px;
$ci-y: 75px;
$ci-offset-x: -200px;
$ci-offset-y: -75px;
$ci-width: 30px;
$ci-height: 15px;
$ci-total-width: 510px;
$ci-total-height: 490px;
$ci-image: 'sprite.png';
$ci: (200px, 75px, -200px, -75px, 30px, 15px, 510px, 490px, 'sprite.png', 'ci', );
$ck-name: 'ck';
$ck-x: 200px;
$ck-y: 100px;
$ck-offset-x: -200px;
$ck-offset-y: -100px;
$ck-width: 30px;
$ck-height: 15px;
$ck-total-width: 510px;
$ck-total-height: 490px;
$ck-image: 'sprite.png';
$ck: (200px, 100px, -200px, -100px, 30px, 15px, 510px, 490px, 'sprite.png', 'ck', );
$cl-name: 'cl';
$cl-x: 200px;
$cl-y: 125px;
$cl-offset-x: -200px;
$cl-offset-y: -125px;
$cl-width: 30px;
$cl-height: 15px;
$cl-total-width: 510px;
$cl-total-height: 490px;
$cl-image: 'sprite.png';
$cl: (200px, 125px, -200px, -125px, 30px, 15px, 510px, 490px, 'sprite.png', 'cl', );
$cm-name: 'cm';
$cm-x: 200px;
$cm-y: 150px;
$cm-offset-x: -200px;
$cm-offset-y: -150px;
$cm-width: 30px;
$cm-height: 15px;
$cm-total-width: 510px;
$cm-total-height: 490px;
$cm-image: 'sprite.png';
$cm: (200px, 150px, -200px, -150px, 30px, 15px, 510px, 490px, 'sprite.png', 'cm', );
$cn-name: 'cn';
$cn-x: 200px;
$cn-y: 175px;
$cn-offset-x: -200px;
$cn-offset-y: -175px;
$cn-width: 30px;
$cn-height: 15px;
$cn-total-width: 510px;
$cn-total-height: 490px;
$cn-image: 'sprite.png';
$cn: (200px, 175px, -200px, -175px, 30px, 15px, 510px, 490px, 'sprite.png', 'cn', );
$co-name: 'co';
$co-x: 0px;
$co-y: 200px;
$co-offset-x: 0px;
$co-offset-y: -200px;
$co-width: 30px;
$co-height: 15px;
$co-total-width: 510px;
$co-total-height: 490px;
$co-image: 'sprite.png';
$co: (0px, 200px, 0px, -200px, 30px, 15px, 510px, 490px, 'sprite.png', 'co', );
$cr-name: 'cr';
$cr-x: 40px;
$cr-y: 200px;
$cr-offset-x: -40px;
$cr-offset-y: -200px;
$cr-width: 30px;
$cr-height: 15px;
$cr-total-width: 510px;
$cr-total-height: 490px;
$cr-image: 'sprite.png';
$cr: (40px, 200px, -40px, -200px, 30px, 15px, 510px, 490px, 'sprite.png', 'cr', );
$cu-name: 'cu';
$cu-x: 80px;
$cu-y: 200px;
$cu-offset-x: -80px;
$cu-offset-y: -200px;
$cu-width: 30px;
$cu-height: 15px;
$cu-total-width: 510px;
$cu-total-height: 490px;
$cu-image: 'sprite.png';
$cu: (80px, 200px, -80px, -200px, 30px, 15px, 510px, 490px, 'sprite.png', 'cu', );
$cv-name: 'cv';
$cv-x: 120px;
$cv-y: 200px;
$cv-offset-x: -120px;
$cv-offset-y: -200px;
$cv-width: 30px;
$cv-height: 15px;
$cv-total-width: 510px;
$cv-total-height: 490px;
$cv-image: 'sprite.png';
$cv: (120px, 200px, -120px, -200px, 30px, 15px, 510px, 490px, 'sprite.png', 'cv', );
$cw-name: 'cw';
$cw-x: 160px;
$cw-y: 200px;
$cw-offset-x: -160px;
$cw-offset-y: -200px;
$cw-width: 30px;
$cw-height: 15px;
$cw-total-width: 510px;
$cw-total-height: 490px;
$cw-image: 'sprite.png';
$cw: (160px, 200px, -160px, -200px, 30px, 15px, 510px, 490px, 'sprite.png', 'cw', );
$cx-name: 'cx';
$cx-x: 200px;
$cx-y: 200px;
$cx-offset-x: -200px;
$cx-offset-y: -200px;
$cx-width: 30px;
$cx-height: 15px;
$cx-total-width: 510px;
$cx-total-height: 490px;
$cx-image: 'sprite.png';
$cx: (200px, 200px, -200px, -200px, 30px, 15px, 510px, 490px, 'sprite.png', 'cx', );
$cy-name: 'cy';
$cy-x: 240px;
$cy-y: 0px;
$cy-offset-x: -240px;
$cy-offset-y: 0px;
$cy-width: 30px;
$cy-height: 15px;
$cy-total-width: 510px;
$cy-total-height: 490px;
$cy-image: 'sprite.png';
$cy: (240px, 0px, -240px, 0px, 30px, 15px, 510px, 490px, 'sprite.png', 'cy', );
$cz-name: 'cz';
$cz-x: 240px;
$cz-y: 25px;
$cz-offset-x: -240px;
$cz-offset-y: -25px;
$cz-width: 30px;
$cz-height: 15px;
$cz-total-width: 510px;
$cz-total-height: 490px;
$cz-image: 'sprite.png';
$cz: (240px, 25px, -240px, -25px, 30px, 15px, 510px, 490px, 'sprite.png', 'cz', );
$de-name: 'de';
$de-x: 240px;
$de-y: 50px;
$de-offset-x: -240px;
$de-offset-y: -50px;
$de-width: 30px;
$de-height: 15px;
$de-total-width: 510px;
$de-total-height: 490px;
$de-image: 'sprite.png';
$de: (240px, 50px, -240px, -50px, 30px, 15px, 510px, 490px, 'sprite.png', 'de', );
$dj-name: 'dj';
$dj-x: 240px;
$dj-y: 75px;
$dj-offset-x: -240px;
$dj-offset-y: -75px;
$dj-width: 30px;
$dj-height: 15px;
$dj-total-width: 510px;
$dj-total-height: 490px;
$dj-image: 'sprite.png';
$dj: (240px, 75px, -240px, -75px, 30px, 15px, 510px, 490px, 'sprite.png', 'dj', );
$dk-name: 'dk';
$dk-x: 240px;
$dk-y: 100px;
$dk-offset-x: -240px;
$dk-offset-y: -100px;
$dk-width: 30px;
$dk-height: 15px;
$dk-total-width: 510px;
$dk-total-height: 490px;
$dk-image: 'sprite.png';
$dk: (240px, 100px, -240px, -100px, 30px, 15px, 510px, 490px, 'sprite.png', 'dk', );
$dm-name: 'dm';
$dm-x: 240px;
$dm-y: 125px;
$dm-offset-x: -240px;
$dm-offset-y: -125px;
$dm-width: 30px;
$dm-height: 15px;
$dm-total-width: 510px;
$dm-total-height: 490px;
$dm-image: 'sprite.png';
$dm: (240px, 125px, -240px, -125px, 30px, 15px, 510px, 490px, 'sprite.png', 'dm', );
$do-name: 'do';
$do-x: 240px;
$do-y: 150px;
$do-offset-x: -240px;
$do-offset-y: -150px;
$do-width: 30px;
$do-height: 15px;
$do-total-width: 510px;
$do-total-height: 490px;
$do-image: 'sprite.png';
$do: (240px, 150px, -240px, -150px, 30px, 15px, 510px, 490px, 'sprite.png', 'do', );
$dz-name: 'dz';
$dz-x: 240px;
$dz-y: 175px;
$dz-offset-x: -240px;
$dz-offset-y: -175px;
$dz-width: 30px;
$dz-height: 15px;
$dz-total-width: 510px;
$dz-total-height: 490px;
$dz-image: 'sprite.png';
$dz: (240px, 175px, -240px, -175px, 30px, 15px, 510px, 490px, 'sprite.png', 'dz', );
$ec-name: 'ec';
$ec-x: 240px;
$ec-y: 200px;
$ec-offset-x: -240px;
$ec-offset-y: -200px;
$ec-width: 30px;
$ec-height: 15px;
$ec-total-width: 510px;
$ec-total-height: 490px;
$ec-image: 'sprite.png';
$ec: (240px, 200px, -240px, -200px, 30px, 15px, 510px, 490px, 'sprite.png', 'ec', );
$ee-name: 'ee';
$ee-x: 0px;
$ee-y: 225px;
$ee-offset-x: 0px;
$ee-offset-y: -225px;
$ee-width: 30px;
$ee-height: 15px;
$ee-total-width: 510px;
$ee-total-height: 490px;
$ee-image: 'sprite.png';
$ee: (0px, 225px, 0px, -225px, 30px, 15px, 510px, 490px, 'sprite.png', 'ee', );
$eg-name: 'eg';
$eg-x: 40px;
$eg-y: 225px;
$eg-offset-x: -40px;
$eg-offset-y: -225px;
$eg-width: 30px;
$eg-height: 15px;
$eg-total-width: 510px;
$eg-total-height: 490px;
$eg-image: 'sprite.png';
$eg: (40px, 225px, -40px, -225px, 30px, 15px, 510px, 490px, 'sprite.png', 'eg', );
$eh-name: 'eh';
$eh-x: 80px;
$eh-y: 225px;
$eh-offset-x: -80px;
$eh-offset-y: -225px;
$eh-width: 30px;
$eh-height: 15px;
$eh-total-width: 510px;
$eh-total-height: 490px;
$eh-image: 'sprite.png';
$eh: (80px, 225px, -80px, -225px, 30px, 15px, 510px, 490px, 'sprite.png', 'eh', );
$er-name: 'er';
$er-x: 120px;
$er-y: 225px;
$er-offset-x: -120px;
$er-offset-y: -225px;
$er-width: 30px;
$er-height: 15px;
$er-total-width: 510px;
$er-total-height: 490px;
$er-image: 'sprite.png';
$er: (120px, 225px, -120px, -225px, 30px, 15px, 510px, 490px, 'sprite.png', 'er', );
$es-name: 'es';
$es-x: 160px;
$es-y: 225px;
$es-offset-x: -160px;
$es-offset-y: -225px;
$es-width: 30px;
$es-height: 15px;
$es-total-width: 510px;
$es-total-height: 490px;
$es-image: 'sprite.png';
$es: (160px, 225px, -160px, -225px, 30px, 15px, 510px, 490px, 'sprite.png', 'es', );
$et-name: 'et';
$et-x: 200px;
$et-y: 225px;
$et-offset-x: -200px;
$et-offset-y: -225px;
$et-width: 30px;
$et-height: 15px;
$et-total-width: 510px;
$et-total-height: 490px;
$et-image: 'sprite.png';
$et: (200px, 225px, -200px, -225px, 30px, 15px, 510px, 490px, 'sprite.png', 'et', );
$fi-name: 'fi';
$fi-x: 240px;
$fi-y: 225px;
$fi-offset-x: -240px;
$fi-offset-y: -225px;
$fi-width: 30px;
$fi-height: 15px;
$fi-total-width: 510px;
$fi-total-height: 490px;
$fi-image: 'sprite.png';
$fi: (240px, 225px, -240px, -225px, 30px, 15px, 510px, 490px, 'sprite.png', 'fi', );
$fj-name: 'fj';
$fj-x: 0px;
$fj-y: 250px;
$fj-offset-x: 0px;
$fj-offset-y: -250px;
$fj-width: 30px;
$fj-height: 15px;
$fj-total-width: 510px;
$fj-total-height: 490px;
$fj-image: 'sprite.png';
$fj: (0px, 250px, 0px, -250px, 30px, 15px, 510px, 490px, 'sprite.png', 'fj', );
$fk-name: 'fk';
$fk-x: 40px;
$fk-y: 250px;
$fk-offset-x: -40px;
$fk-offset-y: -250px;
$fk-width: 30px;
$fk-height: 15px;
$fk-total-width: 510px;
$fk-total-height: 490px;
$fk-image: 'sprite.png';
$fk: (40px, 250px, -40px, -250px, 30px, 15px, 510px, 490px, 'sprite.png', 'fk', );
$fm-name: 'fm';
$fm-x: 80px;
$fm-y: 250px;
$fm-offset-x: -80px;
$fm-offset-y: -250px;
$fm-width: 30px;
$fm-height: 15px;
$fm-total-width: 510px;
$fm-total-height: 490px;
$fm-image: 'sprite.png';
$fm: (80px, 250px, -80px, -250px, 30px, 15px, 510px, 490px, 'sprite.png', 'fm', );
$fo-name: 'fo';
$fo-x: 120px;
$fo-y: 250px;
$fo-offset-x: -120px;
$fo-offset-y: -250px;
$fo-width: 30px;
$fo-height: 15px;
$fo-total-width: 510px;
$fo-total-height: 490px;
$fo-image: 'sprite.png';
$fo: (120px, 250px, -120px, -250px, 30px, 15px, 510px, 490px, 'sprite.png', 'fo', );
$fr-name: 'fr';
$fr-x: 160px;
$fr-y: 250px;
$fr-offset-x: -160px;
$fr-offset-y: -250px;
$fr-width: 30px;
$fr-height: 15px;
$fr-total-width: 510px;
$fr-total-height: 490px;
$fr-image: 'sprite.png';
$fr: (160px, 250px, -160px, -250px, 30px, 15px, 510px, 490px, 'sprite.png', 'fr', );
$ga-name: 'ga';
$ga-x: 200px;
$ga-y: 250px;
$ga-offset-x: -200px;
$ga-offset-y: -250px;
$ga-width: 30px;
$ga-height: 15px;
$ga-total-width: 510px;
$ga-total-height: 490px;
$ga-image: 'sprite.png';
$ga: (200px, 250px, -200px, -250px, 30px, 15px, 510px, 490px, 'sprite.png', 'ga', );
$gb-eng-name: 'gb-eng';
$gb-eng-x: 240px;
$gb-eng-y: 250px;
$gb-eng-offset-x: -240px;
$gb-eng-offset-y: -250px;
$gb-eng-width: 30px;
$gb-eng-height: 15px;
$gb-eng-total-width: 510px;
$gb-eng-total-height: 490px;
$gb-eng-image: 'sprite.png';
$gb-eng: (240px, 250px, -240px, -250px, 30px, 15px, 510px, 490px, 'sprite.png', 'gb-eng', );
$gb-nir-name: 'gb-nir';
$gb-nir-x: 280px;
$gb-nir-y: 0px;
$gb-nir-offset-x: -280px;
$gb-nir-offset-y: 0px;
$gb-nir-width: 30px;
$gb-nir-height: 15px;
$gb-nir-total-width: 510px;
$gb-nir-total-height: 490px;
$gb-nir-image: 'sprite.png';
$gb-nir: (280px, 0px, -280px, 0px, 30px, 15px, 510px, 490px, 'sprite.png', 'gb-nir', );
$gb-sct-name: 'gb-sct';
$gb-sct-x: 280px;
$gb-sct-y: 25px;
$gb-sct-offset-x: -280px;
$gb-sct-offset-y: -25px;
$gb-sct-width: 30px;
$gb-sct-height: 15px;
$gb-sct-total-width: 510px;
$gb-sct-total-height: 490px;
$gb-sct-image: 'sprite.png';
$gb-sct: (280px, 25px, -280px, -25px, 30px, 15px, 510px, 490px, 'sprite.png', 'gb-sct', );
$gb-wls-name: 'gb-wls';
$gb-wls-x: 280px;
$gb-wls-y: 50px;
$gb-wls-offset-x: -280px;
$gb-wls-offset-y: -50px;
$gb-wls-width: 30px;
$gb-wls-height: 15px;
$gb-wls-total-width: 510px;
$gb-wls-total-height: 490px;
$gb-wls-image: 'sprite.png';
$gb-wls: (280px, 50px, -280px, -50px, 30px, 15px, 510px, 490px, 'sprite.png', 'gb-wls', );
$gb-name: 'gb';
$gb-x: 280px;
$gb-y: 75px;
$gb-offset-x: -280px;
$gb-offset-y: -75px;
$gb-width: 30px;
$gb-height: 15px;
$gb-total-width: 510px;
$gb-total-height: 490px;
$gb-image: 'sprite.png';
$gb: (280px, 75px, -280px, -75px, 30px, 15px, 510px, 490px, 'sprite.png', 'gb', );
$gd-name: 'gd';
$gd-x: 280px;
$gd-y: 100px;
$gd-offset-x: -280px;
$gd-offset-y: -100px;
$gd-width: 30px;
$gd-height: 15px;
$gd-total-width: 510px;
$gd-total-height: 490px;
$gd-image: 'sprite.png';
$gd: (280px, 100px, -280px, -100px, 30px, 15px, 510px, 490px, 'sprite.png', 'gd', );
$ge-name: 'ge';
$ge-x: 280px;
$ge-y: 125px;
$ge-offset-x: -280px;
$ge-offset-y: -125px;
$ge-width: 30px;
$ge-height: 15px;
$ge-total-width: 510px;
$ge-total-height: 490px;
$ge-image: 'sprite.png';
$ge: (280px, 125px, -280px, -125px, 30px, 15px, 510px, 490px, 'sprite.png', 'ge', );
$gf-name: 'gf';
$gf-x: 280px;
$gf-y: 150px;
$gf-offset-x: -280px;
$gf-offset-y: -150px;
$gf-width: 30px;
$gf-height: 15px;
$gf-total-width: 510px;
$gf-total-height: 490px;
$gf-image: 'sprite.png';
$gf: (280px, 150px, -280px, -150px, 30px, 15px, 510px, 490px, 'sprite.png', 'gf', );
$gg-name: 'gg';
$gg-x: 280px;
$gg-y: 175px;
$gg-offset-x: -280px;
$gg-offset-y: -175px;
$gg-width: 30px;
$gg-height: 15px;
$gg-total-width: 510px;
$gg-total-height: 490px;
$gg-image: 'sprite.png';
$gg: (280px, 175px, -280px, -175px, 30px, 15px, 510px, 490px, 'sprite.png', 'gg', );
$gh-name: 'gh';
$gh-x: 280px;
$gh-y: 200px;
$gh-offset-x: -280px;
$gh-offset-y: -200px;
$gh-width: 30px;
$gh-height: 15px;
$gh-total-width: 510px;
$gh-total-height: 490px;
$gh-image: 'sprite.png';
$gh: (280px, 200px, -280px, -200px, 30px, 15px, 510px, 490px, 'sprite.png', 'gh', );
$gi-name: 'gi';
$gi-x: 280px;
$gi-y: 225px;
$gi-offset-x: -280px;
$gi-offset-y: -225px;
$gi-width: 30px;
$gi-height: 15px;
$gi-total-width: 510px;
$gi-total-height: 490px;
$gi-image: 'sprite.png';
$gi: (280px, 225px, -280px, -225px, 30px, 15px, 510px, 490px, 'sprite.png', 'gi', );
$gl-name: 'gl';
$gl-x: 280px;
$gl-y: 250px;
$gl-offset-x: -280px;
$gl-offset-y: -250px;
$gl-width: 30px;
$gl-height: 15px;
$gl-total-width: 510px;
$gl-total-height: 490px;
$gl-image: 'sprite.png';
$gl: (280px, 250px, -280px, -250px, 30px, 15px, 510px, 490px, 'sprite.png', 'gl', );
$gm-name: 'gm';
$gm-x: 0px;
$gm-y: 275px;
$gm-offset-x: 0px;
$gm-offset-y: -275px;
$gm-width: 30px;
$gm-height: 15px;
$gm-total-width: 510px;
$gm-total-height: 490px;
$gm-image: 'sprite.png';
$gm: (0px, 275px, 0px, -275px, 30px, 15px, 510px, 490px, 'sprite.png', 'gm', );
$gn-name: 'gn';
$gn-x: 40px;
$gn-y: 275px;
$gn-offset-x: -40px;
$gn-offset-y: -275px;
$gn-width: 30px;
$gn-height: 15px;
$gn-total-width: 510px;
$gn-total-height: 490px;
$gn-image: 'sprite.png';
$gn: (40px, 275px, -40px, -275px, 30px, 15px, 510px, 490px, 'sprite.png', 'gn', );
$gp-name: 'gp';
$gp-x: 80px;
$gp-y: 275px;
$gp-offset-x: -80px;
$gp-offset-y: -275px;
$gp-width: 30px;
$gp-height: 15px;
$gp-total-width: 510px;
$gp-total-height: 490px;
$gp-image: 'sprite.png';
$gp: (80px, 275px, -80px, -275px, 30px, 15px, 510px, 490px, 'sprite.png', 'gp', );
$gq-name: 'gq';
$gq-x: 120px;
$gq-y: 275px;
$gq-offset-x: -120px;
$gq-offset-y: -275px;
$gq-width: 30px;
$gq-height: 15px;
$gq-total-width: 510px;
$gq-total-height: 490px;
$gq-image: 'sprite.png';
$gq: (120px, 275px, -120px, -275px, 30px, 15px, 510px, 490px, 'sprite.png', 'gq', );
$gr-name: 'gr';
$gr-x: 160px;
$gr-y: 275px;
$gr-offset-x: -160px;
$gr-offset-y: -275px;
$gr-width: 30px;
$gr-height: 15px;
$gr-total-width: 510px;
$gr-total-height: 490px;
$gr-image: 'sprite.png';
$gr: (160px, 275px, -160px, -275px, 30px, 15px, 510px, 490px, 'sprite.png', 'gr', );
$gs-name: 'gs';
$gs-x: 200px;
$gs-y: 275px;
$gs-offset-x: -200px;
$gs-offset-y: -275px;
$gs-width: 30px;
$gs-height: 15px;
$gs-total-width: 510px;
$gs-total-height: 490px;
$gs-image: 'sprite.png';
$gs: (200px, 275px, -200px, -275px, 30px, 15px, 510px, 490px, 'sprite.png', 'gs', );
$gt-name: 'gt';
$gt-x: 240px;
$gt-y: 275px;
$gt-offset-x: -240px;
$gt-offset-y: -275px;
$gt-width: 30px;
$gt-height: 15px;
$gt-total-width: 510px;
$gt-total-height: 490px;
$gt-image: 'sprite.png';
$gt: (240px, 275px, -240px, -275px, 30px, 15px, 510px, 490px, 'sprite.png', 'gt', );
$gu-name: 'gu';
$gu-x: 280px;
$gu-y: 275px;
$gu-offset-x: -280px;
$gu-offset-y: -275px;
$gu-width: 30px;
$gu-height: 15px;
$gu-total-width: 510px;
$gu-total-height: 490px;
$gu-image: 'sprite.png';
$gu: (280px, 275px, -280px, -275px, 30px, 15px, 510px, 490px, 'sprite.png', 'gu', );
$gw-name: 'gw';
$gw-x: 320px;
$gw-y: 0px;
$gw-offset-x: -320px;
$gw-offset-y: 0px;
$gw-width: 30px;
$gw-height: 15px;
$gw-total-width: 510px;
$gw-total-height: 490px;
$gw-image: 'sprite.png';
$gw: (320px, 0px, -320px, 0px, 30px, 15px, 510px, 490px, 'sprite.png', 'gw', );
$gy-name: 'gy';
$gy-x: 320px;
$gy-y: 25px;
$gy-offset-x: -320px;
$gy-offset-y: -25px;
$gy-width: 30px;
$gy-height: 15px;
$gy-total-width: 510px;
$gy-total-height: 490px;
$gy-image: 'sprite.png';
$gy: (320px, 25px, -320px, -25px, 30px, 15px, 510px, 490px, 'sprite.png', 'gy', );
$hk-name: 'hk';
$hk-x: 320px;
$hk-y: 50px;
$hk-offset-x: -320px;
$hk-offset-y: -50px;
$hk-width: 30px;
$hk-height: 15px;
$hk-total-width: 510px;
$hk-total-height: 490px;
$hk-image: 'sprite.png';
$hk: (320px, 50px, -320px, -50px, 30px, 15px, 510px, 490px, 'sprite.png', 'hk', );
$hm-name: 'hm';
$hm-x: 320px;
$hm-y: 75px;
$hm-offset-x: -320px;
$hm-offset-y: -75px;
$hm-width: 30px;
$hm-height: 15px;
$hm-total-width: 510px;
$hm-total-height: 490px;
$hm-image: 'sprite.png';
$hm: (320px, 75px, -320px, -75px, 30px, 15px, 510px, 490px, 'sprite.png', 'hm', );
$hn-name: 'hn';
$hn-x: 320px;
$hn-y: 100px;
$hn-offset-x: -320px;
$hn-offset-y: -100px;
$hn-width: 30px;
$hn-height: 15px;
$hn-total-width: 510px;
$hn-total-height: 490px;
$hn-image: 'sprite.png';
$hn: (320px, 100px, -320px, -100px, 30px, 15px, 510px, 490px, 'sprite.png', 'hn', );
$hr-name: 'hr';
$hr-x: 320px;
$hr-y: 125px;
$hr-offset-x: -320px;
$hr-offset-y: -125px;
$hr-width: 30px;
$hr-height: 15px;
$hr-total-width: 510px;
$hr-total-height: 490px;
$hr-image: 'sprite.png';
$hr: (320px, 125px, -320px, -125px, 30px, 15px, 510px, 490px, 'sprite.png', 'hr', );
$ht-name: 'ht';
$ht-x: 320px;
$ht-y: 150px;
$ht-offset-x: -320px;
$ht-offset-y: -150px;
$ht-width: 30px;
$ht-height: 15px;
$ht-total-width: 510px;
$ht-total-height: 490px;
$ht-image: 'sprite.png';
$ht: (320px, 150px, -320px, -150px, 30px, 15px, 510px, 490px, 'sprite.png', 'ht', );
$hu-name: 'hu';
$hu-x: 320px;
$hu-y: 175px;
$hu-offset-x: -320px;
$hu-offset-y: -175px;
$hu-width: 30px;
$hu-height: 15px;
$hu-total-width: 510px;
$hu-total-height: 490px;
$hu-image: 'sprite.png';
$hu: (320px, 175px, -320px, -175px, 30px, 15px, 510px, 490px, 'sprite.png', 'hu', );
$id-name: 'id';
$id-x: 320px;
$id-y: 200px;
$id-offset-x: -320px;
$id-offset-y: -200px;
$id-width: 30px;
$id-height: 15px;
$id-total-width: 510px;
$id-total-height: 490px;
$id-image: 'sprite.png';
$id: (320px, 200px, -320px, -200px, 30px, 15px, 510px, 490px, 'sprite.png', 'id', );
$ie-name: 'ie';
$ie-x: 320px;
$ie-y: 225px;
$ie-offset-x: -320px;
$ie-offset-y: -225px;
$ie-width: 30px;
$ie-height: 15px;
$ie-total-width: 510px;
$ie-total-height: 490px;
$ie-image: 'sprite.png';
$ie: (320px, 225px, -320px, -225px, 30px, 15px, 510px, 490px, 'sprite.png', 'ie', );
$il-name: 'il';
$il-x: 320px;
$il-y: 250px;
$il-offset-x: -320px;
$il-offset-y: -250px;
$il-width: 30px;
$il-height: 15px;
$il-total-width: 510px;
$il-total-height: 490px;
$il-image: 'sprite.png';
$il: (320px, 250px, -320px, -250px, 30px, 15px, 510px, 490px, 'sprite.png', 'il', );
$im-name: 'im';
$im-x: 320px;
$im-y: 275px;
$im-offset-x: -320px;
$im-offset-y: -275px;
$im-width: 30px;
$im-height: 15px;
$im-total-width: 510px;
$im-total-height: 490px;
$im-image: 'sprite.png';
$im: (320px, 275px, -320px, -275px, 30px, 15px, 510px, 490px, 'sprite.png', 'im', );
$in-name: 'in';
$in-x: 0px;
$in-y: 300px;
$in-offset-x: 0px;
$in-offset-y: -300px;
$in-width: 30px;
$in-height: 15px;
$in-total-width: 510px;
$in-total-height: 490px;
$in-image: 'sprite.png';
$in: (0px, 300px, 0px, -300px, 30px, 15px, 510px, 490px, 'sprite.png', 'in', );
$io-name: 'io';
$io-x: 40px;
$io-y: 300px;
$io-offset-x: -40px;
$io-offset-y: -300px;
$io-width: 30px;
$io-height: 15px;
$io-total-width: 510px;
$io-total-height: 490px;
$io-image: 'sprite.png';
$io: (40px, 300px, -40px, -300px, 30px, 15px, 510px, 490px, 'sprite.png', 'io', );
$iq-name: 'iq';
$iq-x: 80px;
$iq-y: 300px;
$iq-offset-x: -80px;
$iq-offset-y: -300px;
$iq-width: 30px;
$iq-height: 15px;
$iq-total-width: 510px;
$iq-total-height: 490px;
$iq-image: 'sprite.png';
$iq: (80px, 300px, -80px, -300px, 30px, 15px, 510px, 490px, 'sprite.png', 'iq', );
$ir-name: 'ir';
$ir-x: 120px;
$ir-y: 300px;
$ir-offset-x: -120px;
$ir-offset-y: -300px;
$ir-width: 30px;
$ir-height: 15px;
$ir-total-width: 510px;
$ir-total-height: 490px;
$ir-image: 'sprite.png';
$ir: (120px, 300px, -120px, -300px, 30px, 15px, 510px, 490px, 'sprite.png', 'ir', );
$is-name: 'is';
$is-x: 160px;
$is-y: 300px;
$is-offset-x: -160px;
$is-offset-y: -300px;
$is-width: 30px;
$is-height: 15px;
$is-total-width: 510px;
$is-total-height: 490px;
$is-image: 'sprite.png';
$is: (160px, 300px, -160px, -300px, 30px, 15px, 510px, 490px, 'sprite.png', 'is', );
$it-name: 'it';
$it-x: 200px;
$it-y: 300px;
$it-offset-x: -200px;
$it-offset-y: -300px;
$it-width: 30px;
$it-height: 15px;
$it-total-width: 510px;
$it-total-height: 490px;
$it-image: 'sprite.png';
$it: (200px, 300px, -200px, -300px, 30px, 15px, 510px, 490px, 'sprite.png', 'it', );
$je-name: 'je';
$je-x: 240px;
$je-y: 300px;
$je-offset-x: -240px;
$je-offset-y: -300px;
$je-width: 30px;
$je-height: 15px;
$je-total-width: 510px;
$je-total-height: 490px;
$je-image: 'sprite.png';
$je: (240px, 300px, -240px, -300px, 30px, 15px, 510px, 490px, 'sprite.png', 'je', );
$jm-name: 'jm';
$jm-x: 280px;
$jm-y: 300px;
$jm-offset-x: -280px;
$jm-offset-y: -300px;
$jm-width: 30px;
$jm-height: 15px;
$jm-total-width: 510px;
$jm-total-height: 490px;
$jm-image: 'sprite.png';
$jm: (280px, 300px, -280px, -300px, 30px, 15px, 510px, 490px, 'sprite.png', 'jm', );
$jo-name: 'jo';
$jo-x: 320px;
$jo-y: 300px;
$jo-offset-x: -320px;
$jo-offset-y: -300px;
$jo-width: 30px;
$jo-height: 15px;
$jo-total-width: 510px;
$jo-total-height: 490px;
$jo-image: 'sprite.png';
$jo: (320px, 300px, -320px, -300px, 30px, 15px, 510px, 490px, 'sprite.png', 'jo', );
$jp-name: 'jp';
$jp-x: 0px;
$jp-y: 325px;
$jp-offset-x: 0px;
$jp-offset-y: -325px;
$jp-width: 30px;
$jp-height: 15px;
$jp-total-width: 510px;
$jp-total-height: 490px;
$jp-image: 'sprite.png';
$jp: (0px, 325px, 0px, -325px, 30px, 15px, 510px, 490px, 'sprite.png', 'jp', );
$ke-name: 'ke';
$ke-x: 40px;
$ke-y: 325px;
$ke-offset-x: -40px;
$ke-offset-y: -325px;
$ke-width: 30px;
$ke-height: 15px;
$ke-total-width: 510px;
$ke-total-height: 490px;
$ke-image: 'sprite.png';
$ke: (40px, 325px, -40px, -325px, 30px, 15px, 510px, 490px, 'sprite.png', 'ke', );
$kg-name: 'kg';
$kg-x: 80px;
$kg-y: 325px;
$kg-offset-x: -80px;
$kg-offset-y: -325px;
$kg-width: 30px;
$kg-height: 15px;
$kg-total-width: 510px;
$kg-total-height: 490px;
$kg-image: 'sprite.png';
$kg: (80px, 325px, -80px, -325px, 30px, 15px, 510px, 490px, 'sprite.png', 'kg', );
$kh-name: 'kh';
$kh-x: 120px;
$kh-y: 325px;
$kh-offset-x: -120px;
$kh-offset-y: -325px;
$kh-width: 30px;
$kh-height: 15px;
$kh-total-width: 510px;
$kh-total-height: 490px;
$kh-image: 'sprite.png';
$kh: (120px, 325px, -120px, -325px, 30px, 15px, 510px, 490px, 'sprite.png', 'kh', );
$ki-name: 'ki';
$ki-x: 160px;
$ki-y: 325px;
$ki-offset-x: -160px;
$ki-offset-y: -325px;
$ki-width: 30px;
$ki-height: 15px;
$ki-total-width: 510px;
$ki-total-height: 490px;
$ki-image: 'sprite.png';
$ki: (160px, 325px, -160px, -325px, 30px, 15px, 510px, 490px, 'sprite.png', 'ki', );
$km-name: 'km';
$km-x: 200px;
$km-y: 325px;
$km-offset-x: -200px;
$km-offset-y: -325px;
$km-width: 30px;
$km-height: 15px;
$km-total-width: 510px;
$km-total-height: 490px;
$km-image: 'sprite.png';
$km: (200px, 325px, -200px, -325px, 30px, 15px, 510px, 490px, 'sprite.png', 'km', );
$kn-name: 'kn';
$kn-x: 240px;
$kn-y: 325px;
$kn-offset-x: -240px;
$kn-offset-y: -325px;
$kn-width: 30px;
$kn-height: 15px;
$kn-total-width: 510px;
$kn-total-height: 490px;
$kn-image: 'sprite.png';
$kn: (240px, 325px, -240px, -325px, 30px, 15px, 510px, 490px, 'sprite.png', 'kn', );
$kp-name: 'kp';
$kp-x: 280px;
$kp-y: 325px;
$kp-offset-x: -280px;
$kp-offset-y: -325px;
$kp-width: 30px;
$kp-height: 15px;
$kp-total-width: 510px;
$kp-total-height: 490px;
$kp-image: 'sprite.png';
$kp: (280px, 325px, -280px, -325px, 30px, 15px, 510px, 490px, 'sprite.png', 'kp', );
$kr-name: 'kr';
$kr-x: 0px;
$kr-y: 0px;
$kr-offset-x: 0px;
$kr-offset-y: 0px;
$kr-width: 30px;
$kr-height: 15px;
$kr-total-width: 510px;
$kr-total-height: 490px;
$kr-image: 'sprite.png';
$kr: (0px, 0px, 0px, 0px, 30px, 15px, 510px, 490px, 'sprite.png', 'kr', );
$kw-name: 'kw';
$kw-x: 360px;
$kw-y: 0px;
$kw-offset-x: -360px;
$kw-offset-y: 0px;
$kw-width: 30px;
$kw-height: 15px;
$kw-total-width: 510px;
$kw-total-height: 490px;
$kw-image: 'sprite.png';
$kw: (360px, 0px, -360px, 0px, 30px, 15px, 510px, 490px, 'sprite.png', 'kw', );
$ky-name: 'ky';
$ky-x: 360px;
$ky-y: 25px;
$ky-offset-x: -360px;
$ky-offset-y: -25px;
$ky-width: 30px;
$ky-height: 15px;
$ky-total-width: 510px;
$ky-total-height: 490px;
$ky-image: 'sprite.png';
$ky: (360px, 25px, -360px, -25px, 30px, 15px, 510px, 490px, 'sprite.png', 'ky', );
$kz-name: 'kz';
$kz-x: 360px;
$kz-y: 50px;
$kz-offset-x: -360px;
$kz-offset-y: -50px;
$kz-width: 30px;
$kz-height: 15px;
$kz-total-width: 510px;
$kz-total-height: 490px;
$kz-image: 'sprite.png';
$kz: (360px, 50px, -360px, -50px, 30px, 15px, 510px, 490px, 'sprite.png', 'kz', );
$la-name: 'la';
$la-x: 360px;
$la-y: 75px;
$la-offset-x: -360px;
$la-offset-y: -75px;
$la-width: 30px;
$la-height: 15px;
$la-total-width: 510px;
$la-total-height: 490px;
$la-image: 'sprite.png';
$la: (360px, 75px, -360px, -75px, 30px, 15px, 510px, 490px, 'sprite.png', 'la', );
$lb-name: 'lb';
$lb-x: 360px;
$lb-y: 100px;
$lb-offset-x: -360px;
$lb-offset-y: -100px;
$lb-width: 30px;
$lb-height: 15px;
$lb-total-width: 510px;
$lb-total-height: 490px;
$lb-image: 'sprite.png';
$lb: (360px, 100px, -360px, -100px, 30px, 15px, 510px, 490px, 'sprite.png', 'lb', );
$lc-name: 'lc';
$lc-x: 360px;
$lc-y: 125px;
$lc-offset-x: -360px;
$lc-offset-y: -125px;
$lc-width: 30px;
$lc-height: 15px;
$lc-total-width: 510px;
$lc-total-height: 490px;
$lc-image: 'sprite.png';
$lc: (360px, 125px, -360px, -125px, 30px, 15px, 510px, 490px, 'sprite.png', 'lc', );
$li-name: 'li';
$li-x: 360px;
$li-y: 150px;
$li-offset-x: -360px;
$li-offset-y: -150px;
$li-width: 30px;
$li-height: 15px;
$li-total-width: 510px;
$li-total-height: 490px;
$li-image: 'sprite.png';
$li: (360px, 150px, -360px, -150px, 30px, 15px, 510px, 490px, 'sprite.png', 'li', );
$lk-name: 'lk';
$lk-x: 360px;
$lk-y: 175px;
$lk-offset-x: -360px;
$lk-offset-y: -175px;
$lk-width: 30px;
$lk-height: 15px;
$lk-total-width: 510px;
$lk-total-height: 490px;
$lk-image: 'sprite.png';
$lk: (360px, 175px, -360px, -175px, 30px, 15px, 510px, 490px, 'sprite.png', 'lk', );
$lr-name: 'lr';
$lr-x: 360px;
$lr-y: 200px;
$lr-offset-x: -360px;
$lr-offset-y: -200px;
$lr-width: 30px;
$lr-height: 15px;
$lr-total-width: 510px;
$lr-total-height: 490px;
$lr-image: 'sprite.png';
$lr: (360px, 200px, -360px, -200px, 30px, 15px, 510px, 490px, 'sprite.png', 'lr', );
$ls-name: 'ls';
$ls-x: 360px;
$ls-y: 225px;
$ls-offset-x: -360px;
$ls-offset-y: -225px;
$ls-width: 30px;
$ls-height: 15px;
$ls-total-width: 510px;
$ls-total-height: 490px;
$ls-image: 'sprite.png';
$ls: (360px, 225px, -360px, -225px, 30px, 15px, 510px, 490px, 'sprite.png', 'ls', );
$lt-name: 'lt';
$lt-x: 360px;
$lt-y: 250px;
$lt-offset-x: -360px;
$lt-offset-y: -250px;
$lt-width: 30px;
$lt-height: 15px;
$lt-total-width: 510px;
$lt-total-height: 490px;
$lt-image: 'sprite.png';
$lt: (360px, 250px, -360px, -250px, 30px, 15px, 510px, 490px, 'sprite.png', 'lt', );
$lu-name: 'lu';
$lu-x: 360px;
$lu-y: 275px;
$lu-offset-x: -360px;
$lu-offset-y: -275px;
$lu-width: 30px;
$lu-height: 15px;
$lu-total-width: 510px;
$lu-total-height: 490px;
$lu-image: 'sprite.png';
$lu: (360px, 275px, -360px, -275px, 30px, 15px, 510px, 490px, 'sprite.png', 'lu', );
$lv-name: 'lv';
$lv-x: 360px;
$lv-y: 300px;
$lv-offset-x: -360px;
$lv-offset-y: -300px;
$lv-width: 30px;
$lv-height: 15px;
$lv-total-width: 510px;
$lv-total-height: 490px;
$lv-image: 'sprite.png';
$lv: (360px, 300px, -360px, -300px, 30px, 15px, 510px, 490px, 'sprite.png', 'lv', );
$ly-name: 'ly';
$ly-x: 360px;
$ly-y: 325px;
$ly-offset-x: -360px;
$ly-offset-y: -325px;
$ly-width: 30px;
$ly-height: 15px;
$ly-total-width: 510px;
$ly-total-height: 490px;
$ly-image: 'sprite.png';
$ly: (360px, 325px, -360px, -325px, 30px, 15px, 510px, 490px, 'sprite.png', 'ly', );
$ma-name: 'ma';
$ma-x: 0px;
$ma-y: 350px;
$ma-offset-x: 0px;
$ma-offset-y: -350px;
$ma-width: 30px;
$ma-height: 15px;
$ma-total-width: 510px;
$ma-total-height: 490px;
$ma-image: 'sprite.png';
$ma: (0px, 350px, 0px, -350px, 30px, 15px, 510px, 490px, 'sprite.png', 'ma', );
$mc-name: 'mc';
$mc-x: 40px;
$mc-y: 350px;
$mc-offset-x: -40px;
$mc-offset-y: -350px;
$mc-width: 30px;
$mc-height: 15px;
$mc-total-width: 510px;
$mc-total-height: 490px;
$mc-image: 'sprite.png';
$mc: (40px, 350px, -40px, -350px, 30px, 15px, 510px, 490px, 'sprite.png', 'mc', );
$md-name: 'md';
$md-x: 80px;
$md-y: 350px;
$md-offset-x: -80px;
$md-offset-y: -350px;
$md-width: 30px;
$md-height: 15px;
$md-total-width: 510px;
$md-total-height: 490px;
$md-image: 'sprite.png';
$md: (80px, 350px, -80px, -350px, 30px, 15px, 510px, 490px, 'sprite.png', 'md', );
$me-name: 'me';
$me-x: 120px;
$me-y: 350px;
$me-offset-x: -120px;
$me-offset-y: -350px;
$me-width: 30px;
$me-height: 15px;
$me-total-width: 510px;
$me-total-height: 490px;
$me-image: 'sprite.png';
$me: (120px, 350px, -120px, -350px, 30px, 15px, 510px, 490px, 'sprite.png', 'me', );
$mf-name: 'mf';
$mf-x: 160px;
$mf-y: 350px;
$mf-offset-x: -160px;
$mf-offset-y: -350px;
$mf-width: 30px;
$mf-height: 15px;
$mf-total-width: 510px;
$mf-total-height: 490px;
$mf-image: 'sprite.png';
$mf: (160px, 350px, -160px, -350px, 30px, 15px, 510px, 490px, 'sprite.png', 'mf', );
$mg-name: 'mg';
$mg-x: 200px;
$mg-y: 350px;
$mg-offset-x: -200px;
$mg-offset-y: -350px;
$mg-width: 30px;
$mg-height: 15px;
$mg-total-width: 510px;
$mg-total-height: 490px;
$mg-image: 'sprite.png';
$mg: (200px, 350px, -200px, -350px, 30px, 15px, 510px, 490px, 'sprite.png', 'mg', );
$mh-name: 'mh';
$mh-x: 240px;
$mh-y: 350px;
$mh-offset-x: -240px;
$mh-offset-y: -350px;
$mh-width: 30px;
$mh-height: 15px;
$mh-total-width: 510px;
$mh-total-height: 490px;
$mh-image: 'sprite.png';
$mh: (240px, 350px, -240px, -350px, 30px, 15px, 510px, 490px, 'sprite.png', 'mh', );
$mk-name: 'mk';
$mk-x: 280px;
$mk-y: 350px;
$mk-offset-x: -280px;
$mk-offset-y: -350px;
$mk-width: 30px;
$mk-height: 15px;
$mk-total-width: 510px;
$mk-total-height: 490px;
$mk-image: 'sprite.png';
$mk: (280px, 350px, -280px, -350px, 30px, 15px, 510px, 490px, 'sprite.png', 'mk', );
$ml-name: 'ml';
$ml-x: 320px;
$ml-y: 350px;
$ml-offset-x: -320px;
$ml-offset-y: -350px;
$ml-width: 30px;
$ml-height: 15px;
$ml-total-width: 510px;
$ml-total-height: 490px;
$ml-image: 'sprite.png';
$ml: (320px, 350px, -320px, -350px, 30px, 15px, 510px, 490px, 'sprite.png', 'ml', );
$mm-name: 'mm';
$mm-x: 360px;
$mm-y: 350px;
$mm-offset-x: -360px;
$mm-offset-y: -350px;
$mm-width: 30px;
$mm-height: 15px;
$mm-total-width: 510px;
$mm-total-height: 490px;
$mm-image: 'sprite.png';
$mm: (360px, 350px, -360px, -350px, 30px, 15px, 510px, 490px, 'sprite.png', 'mm', );
$mn-name: 'mn';
$mn-x: 0px;
$mn-y: 375px;
$mn-offset-x: 0px;
$mn-offset-y: -375px;
$mn-width: 30px;
$mn-height: 15px;
$mn-total-width: 510px;
$mn-total-height: 490px;
$mn-image: 'sprite.png';
$mn: (0px, 375px, 0px, -375px, 30px, 15px, 510px, 490px, 'sprite.png', 'mn', );
$mo-name: 'mo';
$mo-x: 40px;
$mo-y: 375px;
$mo-offset-x: -40px;
$mo-offset-y: -375px;
$mo-width: 30px;
$mo-height: 15px;
$mo-total-width: 510px;
$mo-total-height: 490px;
$mo-image: 'sprite.png';
$mo: (40px, 375px, -40px, -375px, 30px, 15px, 510px, 490px, 'sprite.png', 'mo', );
$mp-name: 'mp';
$mp-x: 80px;
$mp-y: 375px;
$mp-offset-x: -80px;
$mp-offset-y: -375px;
$mp-width: 30px;
$mp-height: 15px;
$mp-total-width: 510px;
$mp-total-height: 490px;
$mp-image: 'sprite.png';
$mp: (80px, 375px, -80px, -375px, 30px, 15px, 510px, 490px, 'sprite.png', 'mp', );
$mq-name: 'mq';
$mq-x: 120px;
$mq-y: 375px;
$mq-offset-x: -120px;
$mq-offset-y: -375px;
$mq-width: 30px;
$mq-height: 15px;
$mq-total-width: 510px;
$mq-total-height: 490px;
$mq-image: 'sprite.png';
$mq: (120px, 375px, -120px, -375px, 30px, 15px, 510px, 490px, 'sprite.png', 'mq', );
$mr-name: 'mr';
$mr-x: 160px;
$mr-y: 375px;
$mr-offset-x: -160px;
$mr-offset-y: -375px;
$mr-width: 30px;
$mr-height: 15px;
$mr-total-width: 510px;
$mr-total-height: 490px;
$mr-image: 'sprite.png';
$mr: (160px, 375px, -160px, -375px, 30px, 15px, 510px, 490px, 'sprite.png', 'mr', );
$ms-name: 'ms';
$ms-x: 200px;
$ms-y: 375px;
$ms-offset-x: -200px;
$ms-offset-y: -375px;
$ms-width: 30px;
$ms-height: 15px;
$ms-total-width: 510px;
$ms-total-height: 490px;
$ms-image: 'sprite.png';
$ms: (200px, 375px, -200px, -375px, 30px, 15px, 510px, 490px, 'sprite.png', 'ms', );
$mt-name: 'mt';
$mt-x: 240px;
$mt-y: 375px;
$mt-offset-x: -240px;
$mt-offset-y: -375px;
$mt-width: 30px;
$mt-height: 15px;
$mt-total-width: 510px;
$mt-total-height: 490px;
$mt-image: 'sprite.png';
$mt: (240px, 375px, -240px, -375px, 30px, 15px, 510px, 490px, 'sprite.png', 'mt', );
$mu-name: 'mu';
$mu-x: 280px;
$mu-y: 375px;
$mu-offset-x: -280px;
$mu-offset-y: -375px;
$mu-width: 30px;
$mu-height: 15px;
$mu-total-width: 510px;
$mu-total-height: 490px;
$mu-image: 'sprite.png';
$mu: (280px, 375px, -280px, -375px, 30px, 15px, 510px, 490px, 'sprite.png', 'mu', );
$mv-name: 'mv';
$mv-x: 320px;
$mv-y: 375px;
$mv-offset-x: -320px;
$mv-offset-y: -375px;
$mv-width: 30px;
$mv-height: 15px;
$mv-total-width: 510px;
$mv-total-height: 490px;
$mv-image: 'sprite.png';
$mv: (320px, 375px, -320px, -375px, 30px, 15px, 510px, 490px, 'sprite.png', 'mv', );
$mw-name: 'mw';
$mw-x: 360px;
$mw-y: 375px;
$mw-offset-x: -360px;
$mw-offset-y: -375px;
$mw-width: 30px;
$mw-height: 15px;
$mw-total-width: 510px;
$mw-total-height: 490px;
$mw-image: 'sprite.png';
$mw: (360px, 375px, -360px, -375px, 30px, 15px, 510px, 490px, 'sprite.png', 'mw', );
$mx-name: 'mx';
$mx-x: 400px;
$mx-y: 0px;
$mx-offset-x: -400px;
$mx-offset-y: 0px;
$mx-width: 30px;
$mx-height: 15px;
$mx-total-width: 510px;
$mx-total-height: 490px;
$mx-image: 'sprite.png';
$mx: (400px, 0px, -400px, 0px, 30px, 15px, 510px, 490px, 'sprite.png', 'mx', );
$my-name: 'my';
$my-x: 400px;
$my-y: 25px;
$my-offset-x: -400px;
$my-offset-y: -25px;
$my-width: 30px;
$my-height: 15px;
$my-total-width: 510px;
$my-total-height: 490px;
$my-image: 'sprite.png';
$my: (400px, 25px, -400px, -25px, 30px, 15px, 510px, 490px, 'sprite.png', 'my', );
$mz-name: 'mz';
$mz-x: 400px;
$mz-y: 50px;
$mz-offset-x: -400px;
$mz-offset-y: -50px;
$mz-width: 30px;
$mz-height: 15px;
$mz-total-width: 510px;
$mz-total-height: 490px;
$mz-image: 'sprite.png';
$mz: (400px, 50px, -400px, -50px, 30px, 15px, 510px, 490px, 'sprite.png', 'mz', );
$na-name: 'na';
$na-x: 400px;
$na-y: 75px;
$na-offset-x: -400px;
$na-offset-y: -75px;
$na-width: 30px;
$na-height: 15px;
$na-total-width: 510px;
$na-total-height: 490px;
$na-image: 'sprite.png';
$na: (400px, 75px, -400px, -75px, 30px, 15px, 510px, 490px, 'sprite.png', 'na', );
$nc-name: 'nc';
$nc-x: 400px;
$nc-y: 100px;
$nc-offset-x: -400px;
$nc-offset-y: -100px;
$nc-width: 30px;
$nc-height: 15px;
$nc-total-width: 510px;
$nc-total-height: 490px;
$nc-image: 'sprite.png';
$nc: (400px, 100px, -400px, -100px, 30px, 15px, 510px, 490px, 'sprite.png', 'nc', );
$ne-name: 'ne';
$ne-x: 400px;
$ne-y: 125px;
$ne-offset-x: -400px;
$ne-offset-y: -125px;
$ne-width: 30px;
$ne-height: 15px;
$ne-total-width: 510px;
$ne-total-height: 490px;
$ne-image: 'sprite.png';
$ne: (400px, 125px, -400px, -125px, 30px, 15px, 510px, 490px, 'sprite.png', 'ne', );
$nf-name: 'nf';
$nf-x: 400px;
$nf-y: 150px;
$nf-offset-x: -400px;
$nf-offset-y: -150px;
$nf-width: 30px;
$nf-height: 15px;
$nf-total-width: 510px;
$nf-total-height: 490px;
$nf-image: 'sprite.png';
$nf: (400px, 150px, -400px, -150px, 30px, 15px, 510px, 490px, 'sprite.png', 'nf', );
$ng-name: 'ng';
$ng-x: 400px;
$ng-y: 175px;
$ng-offset-x: -400px;
$ng-offset-y: -175px;
$ng-width: 30px;
$ng-height: 15px;
$ng-total-width: 510px;
$ng-total-height: 490px;
$ng-image: 'sprite.png';
$ng: (400px, 175px, -400px, -175px, 30px, 15px, 510px, 490px, 'sprite.png', 'ng', );
$ni-name: 'ni';
$ni-x: 400px;
$ni-y: 200px;
$ni-offset-x: -400px;
$ni-offset-y: -200px;
$ni-width: 30px;
$ni-height: 15px;
$ni-total-width: 510px;
$ni-total-height: 490px;
$ni-image: 'sprite.png';
$ni: (400px, 200px, -400px, -200px, 30px, 15px, 510px, 490px, 'sprite.png', 'ni', );
$nl-name: 'nl';
$nl-x: 400px;
$nl-y: 225px;
$nl-offset-x: -400px;
$nl-offset-y: -225px;
$nl-width: 30px;
$nl-height: 15px;
$nl-total-width: 510px;
$nl-total-height: 490px;
$nl-image: 'sprite.png';
$nl: (400px, 225px, -400px, -225px, 30px, 15px, 510px, 490px, 'sprite.png', 'nl', );
$no-name: 'no';
$no-x: 400px;
$no-y: 250px;
$no-offset-x: -400px;
$no-offset-y: -250px;
$no-width: 30px;
$no-height: 15px;
$no-total-width: 510px;
$no-total-height: 490px;
$no-image: 'sprite.png';
$no: (400px, 250px, -400px, -250px, 30px, 15px, 510px, 490px, 'sprite.png', 'no', );
$np-name: 'np';
$np-x: 400px;
$np-y: 275px;
$np-offset-x: -400px;
$np-offset-y: -275px;
$np-width: 30px;
$np-height: 15px;
$np-total-width: 510px;
$np-total-height: 490px;
$np-image: 'sprite.png';
$np: (400px, 275px, -400px, -275px, 30px, 15px, 510px, 490px, 'sprite.png', 'np', );
$nr-name: 'nr';
$nr-x: 400px;
$nr-y: 300px;
$nr-offset-x: -400px;
$nr-offset-y: -300px;
$nr-width: 30px;
$nr-height: 15px;
$nr-total-width: 510px;
$nr-total-height: 490px;
$nr-image: 'sprite.png';
$nr: (400px, 300px, -400px, -300px, 30px, 15px, 510px, 490px, 'sprite.png', 'nr', );
$nu-name: 'nu';
$nu-x: 400px;
$nu-y: 325px;
$nu-offset-x: -400px;
$nu-offset-y: -325px;
$nu-width: 30px;
$nu-height: 15px;
$nu-total-width: 510px;
$nu-total-height: 490px;
$nu-image: 'sprite.png';
$nu: (400px, 325px, -400px, -325px, 30px, 15px, 510px, 490px, 'sprite.png', 'nu', );
$nz-name: 'nz';
$nz-x: 400px;
$nz-y: 350px;
$nz-offset-x: -400px;
$nz-offset-y: -350px;
$nz-width: 30px;
$nz-height: 15px;
$nz-total-width: 510px;
$nz-total-height: 490px;
$nz-image: 'sprite.png';
$nz: (400px, 350px, -400px, -350px, 30px, 15px, 510px, 490px, 'sprite.png', 'nz', );
$om-name: 'om';
$om-x: 400px;
$om-y: 375px;
$om-offset-x: -400px;
$om-offset-y: -375px;
$om-width: 30px;
$om-height: 15px;
$om-total-width: 510px;
$om-total-height: 490px;
$om-image: 'sprite.png';
$om: (400px, 375px, -400px, -375px, 30px, 15px, 510px, 490px, 'sprite.png', 'om', );
$pa-name: 'pa';
$pa-x: 0px;
$pa-y: 400px;
$pa-offset-x: 0px;
$pa-offset-y: -400px;
$pa-width: 30px;
$pa-height: 15px;
$pa-total-width: 510px;
$pa-total-height: 490px;
$pa-image: 'sprite.png';
$pa: (0px, 400px, 0px, -400px, 30px, 15px, 510px, 490px, 'sprite.png', 'pa', );
$pe-name: 'pe';
$pe-x: 40px;
$pe-y: 400px;
$pe-offset-x: -40px;
$pe-offset-y: -400px;
$pe-width: 30px;
$pe-height: 15px;
$pe-total-width: 510px;
$pe-total-height: 490px;
$pe-image: 'sprite.png';
$pe: (40px, 400px, -40px, -400px, 30px, 15px, 510px, 490px, 'sprite.png', 'pe', );
$pf-name: 'pf';
$pf-x: 80px;
$pf-y: 400px;
$pf-offset-x: -80px;
$pf-offset-y: -400px;
$pf-width: 30px;
$pf-height: 15px;
$pf-total-width: 510px;
$pf-total-height: 490px;
$pf-image: 'sprite.png';
$pf: (80px, 400px, -80px, -400px, 30px, 15px, 510px, 490px, 'sprite.png', 'pf', );
$pg-name: 'pg';
$pg-x: 120px;
$pg-y: 400px;
$pg-offset-x: -120px;
$pg-offset-y: -400px;
$pg-width: 30px;
$pg-height: 15px;
$pg-total-width: 510px;
$pg-total-height: 490px;
$pg-image: 'sprite.png';
$pg: (120px, 400px, -120px, -400px, 30px, 15px, 510px, 490px, 'sprite.png', 'pg', );
$ph-name: 'ph';
$ph-x: 160px;
$ph-y: 400px;
$ph-offset-x: -160px;
$ph-offset-y: -400px;
$ph-width: 30px;
$ph-height: 15px;
$ph-total-width: 510px;
$ph-total-height: 490px;
$ph-image: 'sprite.png';
$ph: (160px, 400px, -160px, -400px, 30px, 15px, 510px, 490px, 'sprite.png', 'ph', );
$pk-name: 'pk';
$pk-x: 200px;
$pk-y: 400px;
$pk-offset-x: -200px;
$pk-offset-y: -400px;
$pk-width: 30px;
$pk-height: 15px;
$pk-total-width: 510px;
$pk-total-height: 490px;
$pk-image: 'sprite.png';
$pk: (200px, 400px, -200px, -400px, 30px, 15px, 510px, 490px, 'sprite.png', 'pk', );
$pl-name: 'pl';
$pl-x: 240px;
$pl-y: 400px;
$pl-offset-x: -240px;
$pl-offset-y: -400px;
$pl-width: 30px;
$pl-height: 15px;
$pl-total-width: 510px;
$pl-total-height: 490px;
$pl-image: 'sprite.png';
$pl: (240px, 400px, -240px, -400px, 30px, 15px, 510px, 490px, 'sprite.png', 'pl', );
$pm-name: 'pm';
$pm-x: 280px;
$pm-y: 400px;
$pm-offset-x: -280px;
$pm-offset-y: -400px;
$pm-width: 30px;
$pm-height: 15px;
$pm-total-width: 510px;
$pm-total-height: 490px;
$pm-image: 'sprite.png';
$pm: (280px, 400px, -280px, -400px, 30px, 15px, 510px, 490px, 'sprite.png', 'pm', );
$pn-name: 'pn';
$pn-x: 320px;
$pn-y: 400px;
$pn-offset-x: -320px;
$pn-offset-y: -400px;
$pn-width: 30px;
$pn-height: 15px;
$pn-total-width: 510px;
$pn-total-height: 490px;
$pn-image: 'sprite.png';
$pn: (320px, 400px, -320px, -400px, 30px, 15px, 510px, 490px, 'sprite.png', 'pn', );
$pr-name: 'pr';
$pr-x: 360px;
$pr-y: 400px;
$pr-offset-x: -360px;
$pr-offset-y: -400px;
$pr-width: 30px;
$pr-height: 15px;
$pr-total-width: 510px;
$pr-total-height: 490px;
$pr-image: 'sprite.png';
$pr: (360px, 400px, -360px, -400px, 30px, 15px, 510px, 490px, 'sprite.png', 'pr', );
$ps-name: 'ps';
$ps-x: 400px;
$ps-y: 400px;
$ps-offset-x: -400px;
$ps-offset-y: -400px;
$ps-width: 30px;
$ps-height: 15px;
$ps-total-width: 510px;
$ps-total-height: 490px;
$ps-image: 'sprite.png';
$ps: (400px, 400px, -400px, -400px, 30px, 15px, 510px, 490px, 'sprite.png', 'ps', );
$pt-name: 'pt';
$pt-x: 440px;
$pt-y: 0px;
$pt-offset-x: -440px;
$pt-offset-y: 0px;
$pt-width: 30px;
$pt-height: 15px;
$pt-total-width: 510px;
$pt-total-height: 490px;
$pt-image: 'sprite.png';
$pt: (440px, 0px, -440px, 0px, 30px, 15px, 510px, 490px, 'sprite.png', 'pt', );
$pw-name: 'pw';
$pw-x: 440px;
$pw-y: 25px;
$pw-offset-x: -440px;
$pw-offset-y: -25px;
$pw-width: 30px;
$pw-height: 15px;
$pw-total-width: 510px;
$pw-total-height: 490px;
$pw-image: 'sprite.png';
$pw: (440px, 25px, -440px, -25px, 30px, 15px, 510px, 490px, 'sprite.png', 'pw', );
$py-name: 'py';
$py-x: 440px;
$py-y: 50px;
$py-offset-x: -440px;
$py-offset-y: -50px;
$py-width: 30px;
$py-height: 15px;
$py-total-width: 510px;
$py-total-height: 490px;
$py-image: 'sprite.png';
$py: (440px, 50px, -440px, -50px, 30px, 15px, 510px, 490px, 'sprite.png', 'py', );
$qa-name: 'qa';
$qa-x: 440px;
$qa-y: 75px;
$qa-offset-x: -440px;
$qa-offset-y: -75px;
$qa-width: 30px;
$qa-height: 15px;
$qa-total-width: 510px;
$qa-total-height: 490px;
$qa-image: 'sprite.png';
$qa: (440px, 75px, -440px, -75px, 30px, 15px, 510px, 490px, 'sprite.png', 'qa', );
$re-name: 're';
$re-x: 440px;
$re-y: 100px;
$re-offset-x: -440px;
$re-offset-y: -100px;
$re-width: 30px;
$re-height: 15px;
$re-total-width: 510px;
$re-total-height: 490px;
$re-image: 'sprite.png';
$re: (440px, 100px, -440px, -100px, 30px, 15px, 510px, 490px, 'sprite.png', 're', );
$ro-name: 'ro';
$ro-x: 440px;
$ro-y: 125px;
$ro-offset-x: -440px;
$ro-offset-y: -125px;
$ro-width: 30px;
$ro-height: 15px;
$ro-total-width: 510px;
$ro-total-height: 490px;
$ro-image: 'sprite.png';
$ro: (440px, 125px, -440px, -125px, 30px, 15px, 510px, 490px, 'sprite.png', 'ro', );
$rs-name: 'rs';
$rs-x: 440px;
$rs-y: 150px;
$rs-offset-x: -440px;
$rs-offset-y: -150px;
$rs-width: 30px;
$rs-height: 15px;
$rs-total-width: 510px;
$rs-total-height: 490px;
$rs-image: 'sprite.png';
$rs: (440px, 150px, -440px, -150px, 30px, 15px, 510px, 490px, 'sprite.png', 'rs', );
$ru-name: 'ru';
$ru-x: 440px;
$ru-y: 175px;
$ru-offset-x: -440px;
$ru-offset-y: -175px;
$ru-width: 30px;
$ru-height: 15px;
$ru-total-width: 510px;
$ru-total-height: 490px;
$ru-image: 'sprite.png';
$ru: (440px, 175px, -440px, -175px, 30px, 15px, 510px, 490px, 'sprite.png', 'ru', );
$rw-name: 'rw';
$rw-x: 440px;
$rw-y: 200px;
$rw-offset-x: -440px;
$rw-offset-y: -200px;
$rw-width: 30px;
$rw-height: 15px;
$rw-total-width: 510px;
$rw-total-height: 490px;
$rw-image: 'sprite.png';
$rw: (440px, 200px, -440px, -200px, 30px, 15px, 510px, 490px, 'sprite.png', 'rw', );
$sa-name: 'sa';
$sa-x: 440px;
$sa-y: 225px;
$sa-offset-x: -440px;
$sa-offset-y: -225px;
$sa-width: 30px;
$sa-height: 15px;
$sa-total-width: 510px;
$sa-total-height: 490px;
$sa-image: 'sprite.png';
$sa: (440px, 225px, -440px, -225px, 30px, 15px, 510px, 490px, 'sprite.png', 'sa', );
$sb-name: 'sb';
$sb-x: 440px;
$sb-y: 250px;
$sb-offset-x: -440px;
$sb-offset-y: -250px;
$sb-width: 30px;
$sb-height: 15px;
$sb-total-width: 510px;
$sb-total-height: 490px;
$sb-image: 'sprite.png';
$sb: (440px, 250px, -440px, -250px, 30px, 15px, 510px, 490px, 'sprite.png', 'sb', );
$sc-name: 'sc';
$sc-x: 440px;
$sc-y: 275px;
$sc-offset-x: -440px;
$sc-offset-y: -275px;
$sc-width: 30px;
$sc-height: 15px;
$sc-total-width: 510px;
$sc-total-height: 490px;
$sc-image: 'sprite.png';
$sc: (440px, 275px, -440px, -275px, 30px, 15px, 510px, 490px, 'sprite.png', 'sc', );
$sd-name: 'sd';
$sd-x: 440px;
$sd-y: 300px;
$sd-offset-x: -440px;
$sd-offset-y: -300px;
$sd-width: 30px;
$sd-height: 15px;
$sd-total-width: 510px;
$sd-total-height: 490px;
$sd-image: 'sprite.png';
$sd: (440px, 300px, -440px, -300px, 30px, 15px, 510px, 490px, 'sprite.png', 'sd', );
$se-name: 'se';
$se-x: 440px;
$se-y: 325px;
$se-offset-x: -440px;
$se-offset-y: -325px;
$se-width: 30px;
$se-height: 15px;
$se-total-width: 510px;
$se-total-height: 490px;
$se-image: 'sprite.png';
$se: (440px, 325px, -440px, -325px, 30px, 15px, 510px, 490px, 'sprite.png', 'se', );
$sg-name: 'sg';
$sg-x: 440px;
$sg-y: 350px;
$sg-offset-x: -440px;
$sg-offset-y: -350px;
$sg-width: 30px;
$sg-height: 15px;
$sg-total-width: 510px;
$sg-total-height: 490px;
$sg-image: 'sprite.png';
$sg: (440px, 350px, -440px, -350px, 30px, 15px, 510px, 490px, 'sprite.png', 'sg', );
$sh-name: 'sh';
$sh-x: 440px;
$sh-y: 375px;
$sh-offset-x: -440px;
$sh-offset-y: -375px;
$sh-width: 30px;
$sh-height: 15px;
$sh-total-width: 510px;
$sh-total-height: 490px;
$sh-image: 'sprite.png';
$sh: (440px, 375px, -440px, -375px, 30px, 15px, 510px, 490px, 'sprite.png', 'sh', );
$si-name: 'si';
$si-x: 440px;
$si-y: 400px;
$si-offset-x: -440px;
$si-offset-y: -400px;
$si-width: 30px;
$si-height: 15px;
$si-total-width: 510px;
$si-total-height: 490px;
$si-image: 'sprite.png';
$si: (440px, 400px, -440px, -400px, 30px, 15px, 510px, 490px, 'sprite.png', 'si', );
$sj-name: 'sj';
$sj-x: 0px;
$sj-y: 425px;
$sj-offset-x: 0px;
$sj-offset-y: -425px;
$sj-width: 30px;
$sj-height: 15px;
$sj-total-width: 510px;
$sj-total-height: 490px;
$sj-image: 'sprite.png';
$sj: (0px, 425px, 0px, -425px, 30px, 15px, 510px, 490px, 'sprite.png', 'sj', );
$sk-name: 'sk';
$sk-x: 40px;
$sk-y: 425px;
$sk-offset-x: -40px;
$sk-offset-y: -425px;
$sk-width: 30px;
$sk-height: 15px;
$sk-total-width: 510px;
$sk-total-height: 490px;
$sk-image: 'sprite.png';
$sk: (40px, 425px, -40px, -425px, 30px, 15px, 510px, 490px, 'sprite.png', 'sk', );
$sl-name: 'sl';
$sl-x: 80px;
$sl-y: 425px;
$sl-offset-x: -80px;
$sl-offset-y: -425px;
$sl-width: 30px;
$sl-height: 15px;
$sl-total-width: 510px;
$sl-total-height: 490px;
$sl-image: 'sprite.png';
$sl: (80px, 425px, -80px, -425px, 30px, 15px, 510px, 490px, 'sprite.png', 'sl', );
$sm-name: 'sm';
$sm-x: 120px;
$sm-y: 425px;
$sm-offset-x: -120px;
$sm-offset-y: -425px;
$sm-width: 30px;
$sm-height: 15px;
$sm-total-width: 510px;
$sm-total-height: 490px;
$sm-image: 'sprite.png';
$sm: (120px, 425px, -120px, -425px, 30px, 15px, 510px, 490px, 'sprite.png', 'sm', );
$sn-name: 'sn';
$sn-x: 160px;
$sn-y: 425px;
$sn-offset-x: -160px;
$sn-offset-y: -425px;
$sn-width: 30px;
$sn-height: 15px;
$sn-total-width: 510px;
$sn-total-height: 490px;
$sn-image: 'sprite.png';
$sn: (160px, 425px, -160px, -425px, 30px, 15px, 510px, 490px, 'sprite.png', 'sn', );
$so-name: 'so';
$so-x: 200px;
$so-y: 425px;
$so-offset-x: -200px;
$so-offset-y: -425px;
$so-width: 30px;
$so-height: 15px;
$so-total-width: 510px;
$so-total-height: 490px;
$so-image: 'sprite.png';
$so: (200px, 425px, -200px, -425px, 30px, 15px, 510px, 490px, 'sprite.png', 'so', );
$sr-name: 'sr';
$sr-x: 240px;
$sr-y: 425px;
$sr-offset-x: -240px;
$sr-offset-y: -425px;
$sr-width: 30px;
$sr-height: 15px;
$sr-total-width: 510px;
$sr-total-height: 490px;
$sr-image: 'sprite.png';
$sr: (240px, 425px, -240px, -425px, 30px, 15px, 510px, 490px, 'sprite.png', 'sr', );
$ss-name: 'ss';
$ss-x: 280px;
$ss-y: 425px;
$ss-offset-x: -280px;
$ss-offset-y: -425px;
$ss-width: 30px;
$ss-height: 15px;
$ss-total-width: 510px;
$ss-total-height: 490px;
$ss-image: 'sprite.png';
$ss: (280px, 425px, -280px, -425px, 30px, 15px, 510px, 490px, 'sprite.png', 'ss', );
$st-name: 'st';
$st-x: 320px;
$st-y: 425px;
$st-offset-x: -320px;
$st-offset-y: -425px;
$st-width: 30px;
$st-height: 15px;
$st-total-width: 510px;
$st-total-height: 490px;
$st-image: 'sprite.png';
$st: (320px, 425px, -320px, -425px, 30px, 15px, 510px, 490px, 'sprite.png', 'st', );
$sv-name: 'sv';
$sv-x: 360px;
$sv-y: 425px;
$sv-offset-x: -360px;
$sv-offset-y: -425px;
$sv-width: 30px;
$sv-height: 15px;
$sv-total-width: 510px;
$sv-total-height: 490px;
$sv-image: 'sprite.png';
$sv: (360px, 425px, -360px, -425px, 30px, 15px, 510px, 490px, 'sprite.png', 'sv', );
$sx-name: 'sx';
$sx-x: 400px;
$sx-y: 425px;
$sx-offset-x: -400px;
$sx-offset-y: -425px;
$sx-width: 30px;
$sx-height: 15px;
$sx-total-width: 510px;
$sx-total-height: 490px;
$sx-image: 'sprite.png';
$sx: (400px, 425px, -400px, -425px, 30px, 15px, 510px, 490px, 'sprite.png', 'sx', );
$sy-name: 'sy';
$sy-x: 440px;
$sy-y: 425px;
$sy-offset-x: -440px;
$sy-offset-y: -425px;
$sy-width: 30px;
$sy-height: 15px;
$sy-total-width: 510px;
$sy-total-height: 490px;
$sy-image: 'sprite.png';
$sy: (440px, 425px, -440px, -425px, 30px, 15px, 510px, 490px, 'sprite.png', 'sy', );
$sz-name: 'sz';
$sz-x: 0px;
$sz-y: 450px;
$sz-offset-x: 0px;
$sz-offset-y: -450px;
$sz-width: 30px;
$sz-height: 15px;
$sz-total-width: 510px;
$sz-total-height: 490px;
$sz-image: 'sprite.png';
$sz: (0px, 450px, 0px, -450px, 30px, 15px, 510px, 490px, 'sprite.png', 'sz', );
$tc-name: 'tc';
$tc-x: 40px;
$tc-y: 450px;
$tc-offset-x: -40px;
$tc-offset-y: -450px;
$tc-width: 30px;
$tc-height: 15px;
$tc-total-width: 510px;
$tc-total-height: 490px;
$tc-image: 'sprite.png';
$tc: (40px, 450px, -40px, -450px, 30px, 15px, 510px, 490px, 'sprite.png', 'tc', );
$td-name: 'td';
$td-x: 80px;
$td-y: 450px;
$td-offset-x: -80px;
$td-offset-y: -450px;
$td-width: 30px;
$td-height: 15px;
$td-total-width: 510px;
$td-total-height: 490px;
$td-image: 'sprite.png';
$td: (80px, 450px, -80px, -450px, 30px, 15px, 510px, 490px, 'sprite.png', 'td', );
$tf-name: 'tf';
$tf-x: 120px;
$tf-y: 450px;
$tf-offset-x: -120px;
$tf-offset-y: -450px;
$tf-width: 30px;
$tf-height: 15px;
$tf-total-width: 510px;
$tf-total-height: 490px;
$tf-image: 'sprite.png';
$tf: (120px, 450px, -120px, -450px, 30px, 15px, 510px, 490px, 'sprite.png', 'tf', );
$tg-name: 'tg';
$tg-x: 160px;
$tg-y: 450px;
$tg-offset-x: -160px;
$tg-offset-y: -450px;
$tg-width: 30px;
$tg-height: 15px;
$tg-total-width: 510px;
$tg-total-height: 490px;
$tg-image: 'sprite.png';
$tg: (160px, 450px, -160px, -450px, 30px, 15px, 510px, 490px, 'sprite.png', 'tg', );
$th-name: 'th';
$th-x: 200px;
$th-y: 450px;
$th-offset-x: -200px;
$th-offset-y: -450px;
$th-width: 30px;
$th-height: 15px;
$th-total-width: 510px;
$th-total-height: 490px;
$th-image: 'sprite.png';
$th: (200px, 450px, -200px, -450px, 30px, 15px, 510px, 490px, 'sprite.png', 'th', );
$tj-name: 'tj';
$tj-x: 240px;
$tj-y: 450px;
$tj-offset-x: -240px;
$tj-offset-y: -450px;
$tj-width: 30px;
$tj-height: 15px;
$tj-total-width: 510px;
$tj-total-height: 490px;
$tj-image: 'sprite.png';
$tj: (240px, 450px, -240px, -450px, 30px, 15px, 510px, 490px, 'sprite.png', 'tj', );
$tk-name: 'tk';
$tk-x: 280px;
$tk-y: 450px;
$tk-offset-x: -280px;
$tk-offset-y: -450px;
$tk-width: 30px;
$tk-height: 15px;
$tk-total-width: 510px;
$tk-total-height: 490px;
$tk-image: 'sprite.png';
$tk: (280px, 450px, -280px, -450px, 30px, 15px, 510px, 490px, 'sprite.png', 'tk', );
$tl-name: 'tl';
$tl-x: 320px;
$tl-y: 450px;
$tl-offset-x: -320px;
$tl-offset-y: -450px;
$tl-width: 30px;
$tl-height: 15px;
$tl-total-width: 510px;
$tl-total-height: 490px;
$tl-image: 'sprite.png';
$tl: (320px, 450px, -320px, -450px, 30px, 15px, 510px, 490px, 'sprite.png', 'tl', );
$tm-name: 'tm';
$tm-x: 360px;
$tm-y: 450px;
$tm-offset-x: -360px;
$tm-offset-y: -450px;
$tm-width: 30px;
$tm-height: 15px;
$tm-total-width: 510px;
$tm-total-height: 490px;
$tm-image: 'sprite.png';
$tm: (360px, 450px, -360px, -450px, 30px, 15px, 510px, 490px, 'sprite.png', 'tm', );
$tn-name: 'tn';
$tn-x: 400px;
$tn-y: 450px;
$tn-offset-x: -400px;
$tn-offset-y: -450px;
$tn-width: 30px;
$tn-height: 15px;
$tn-total-width: 510px;
$tn-total-height: 490px;
$tn-image: 'sprite.png';
$tn: (400px, 450px, -400px, -450px, 30px, 15px, 510px, 490px, 'sprite.png', 'tn', );
$to-name: 'to';
$to-x: 440px;
$to-y: 450px;
$to-offset-x: -440px;
$to-offset-y: -450px;
$to-width: 30px;
$to-height: 15px;
$to-total-width: 510px;
$to-total-height: 490px;
$to-image: 'sprite.png';
$to: (440px, 450px, -440px, -450px, 30px, 15px, 510px, 490px, 'sprite.png', 'to', );
$tr-name: 'tr';
$tr-x: 480px;
$tr-y: 0px;
$tr-offset-x: -480px;
$tr-offset-y: 0px;
$tr-width: 30px;
$tr-height: 15px;
$tr-total-width: 510px;
$tr-total-height: 490px;
$tr-image: 'sprite.png';
$tr: (480px, 0px, -480px, 0px, 30px, 15px, 510px, 490px, 'sprite.png', 'tr', );
$tt-name: 'tt';
$tt-x: 480px;
$tt-y: 25px;
$tt-offset-x: -480px;
$tt-offset-y: -25px;
$tt-width: 30px;
$tt-height: 15px;
$tt-total-width: 510px;
$tt-total-height: 490px;
$tt-image: 'sprite.png';
$tt: (480px, 25px, -480px, -25px, 30px, 15px, 510px, 490px, 'sprite.png', 'tt', );
$tv-name: 'tv';
$tv-x: 480px;
$tv-y: 50px;
$tv-offset-x: -480px;
$tv-offset-y: -50px;
$tv-width: 30px;
$tv-height: 15px;
$tv-total-width: 510px;
$tv-total-height: 490px;
$tv-image: 'sprite.png';
$tv: (480px, 50px, -480px, -50px, 30px, 15px, 510px, 490px, 'sprite.png', 'tv', );
$tw-name: 'tw';
$tw-x: 480px;
$tw-y: 75px;
$tw-offset-x: -480px;
$tw-offset-y: -75px;
$tw-width: 30px;
$tw-height: 15px;
$tw-total-width: 510px;
$tw-total-height: 490px;
$tw-image: 'sprite.png';
$tw: (480px, 75px, -480px, -75px, 30px, 15px, 510px, 490px, 'sprite.png', 'tw', );
$tz-name: 'tz';
$tz-x: 480px;
$tz-y: 100px;
$tz-offset-x: -480px;
$tz-offset-y: -100px;
$tz-width: 30px;
$tz-height: 15px;
$tz-total-width: 510px;
$tz-total-height: 490px;
$tz-image: 'sprite.png';
$tz: (480px, 100px, -480px, -100px, 30px, 15px, 510px, 490px, 'sprite.png', 'tz', );
$ua-name: 'ua';
$ua-x: 480px;
$ua-y: 125px;
$ua-offset-x: -480px;
$ua-offset-y: -125px;
$ua-width: 30px;
$ua-height: 15px;
$ua-total-width: 510px;
$ua-total-height: 490px;
$ua-image: 'sprite.png';
$ua: (480px, 125px, -480px, -125px, 30px, 15px, 510px, 490px, 'sprite.png', 'ua', );
$ug-name: 'ug';
$ug-x: 480px;
$ug-y: 150px;
$ug-offset-x: -480px;
$ug-offset-y: -150px;
$ug-width: 30px;
$ug-height: 15px;
$ug-total-width: 510px;
$ug-total-height: 490px;
$ug-image: 'sprite.png';
$ug: (480px, 150px, -480px, -150px, 30px, 15px, 510px, 490px, 'sprite.png', 'ug', );
$um-name: 'um';
$um-x: 480px;
$um-y: 175px;
$um-offset-x: -480px;
$um-offset-y: -175px;
$um-width: 30px;
$um-height: 15px;
$um-total-width: 510px;
$um-total-height: 490px;
$um-image: 'sprite.png';
$um: (480px, 175px, -480px, -175px, 30px, 15px, 510px, 490px, 'sprite.png', 'um', );
$us-name: 'us';
$us-x: 480px;
$us-y: 200px;
$us-offset-x: -480px;
$us-offset-y: -200px;
$us-width: 30px;
$us-height: 15px;
$us-total-width: 510px;
$us-total-height: 490px;
$us-image: 'sprite.png';
$us: (480px, 200px, -480px, -200px, 30px, 15px, 510px, 490px, 'sprite.png', 'us', );
$uy-name: 'uy';
$uy-x: 480px;
$uy-y: 225px;
$uy-offset-x: -480px;
$uy-offset-y: -225px;
$uy-width: 30px;
$uy-height: 15px;
$uy-total-width: 510px;
$uy-total-height: 490px;
$uy-image: 'sprite.png';
$uy: (480px, 225px, -480px, -225px, 30px, 15px, 510px, 490px, 'sprite.png', 'uy', );
$uz-name: 'uz';
$uz-x: 480px;
$uz-y: 250px;
$uz-offset-x: -480px;
$uz-offset-y: -250px;
$uz-width: 30px;
$uz-height: 15px;
$uz-total-width: 510px;
$uz-total-height: 490px;
$uz-image: 'sprite.png';
$uz: (480px, 250px, -480px, -250px, 30px, 15px, 510px, 490px, 'sprite.png', 'uz', );
$va-name: 'va';
$va-x: 480px;
$va-y: 275px;
$va-offset-x: -480px;
$va-offset-y: -275px;
$va-width: 30px;
$va-height: 15px;
$va-total-width: 510px;
$va-total-height: 490px;
$va-image: 'sprite.png';
$va: (480px, 275px, -480px, -275px, 30px, 15px, 510px, 490px, 'sprite.png', 'va', );
$vc-name: 'vc';
$vc-x: 480px;
$vc-y: 300px;
$vc-offset-x: -480px;
$vc-offset-y: -300px;
$vc-width: 30px;
$vc-height: 15px;
$vc-total-width: 510px;
$vc-total-height: 490px;
$vc-image: 'sprite.png';
$vc: (480px, 300px, -480px, -300px, 30px, 15px, 510px, 490px, 'sprite.png', 'vc', );
$ve-name: 've';
$ve-x: 480px;
$ve-y: 325px;
$ve-offset-x: -480px;
$ve-offset-y: -325px;
$ve-width: 30px;
$ve-height: 15px;
$ve-total-width: 510px;
$ve-total-height: 490px;
$ve-image: 'sprite.png';
$ve: (480px, 325px, -480px, -325px, 30px, 15px, 510px, 490px, 'sprite.png', 've', );
$vg-name: 'vg';
$vg-x: 480px;
$vg-y: 350px;
$vg-offset-x: -480px;
$vg-offset-y: -350px;
$vg-width: 30px;
$vg-height: 15px;
$vg-total-width: 510px;
$vg-total-height: 490px;
$vg-image: 'sprite.png';
$vg: (480px, 350px, -480px, -350px, 30px, 15px, 510px, 490px, 'sprite.png', 'vg', );
$vi-name: 'vi';
$vi-x: 480px;
$vi-y: 375px;
$vi-offset-x: -480px;
$vi-offset-y: -375px;
$vi-width: 30px;
$vi-height: 15px;
$vi-total-width: 510px;
$vi-total-height: 490px;
$vi-image: 'sprite.png';
$vi: (480px, 375px, -480px, -375px, 30px, 15px, 510px, 490px, 'sprite.png', 'vi', );
$vn-name: 'vn';
$vn-x: 480px;
$vn-y: 400px;
$vn-offset-x: -480px;
$vn-offset-y: -400px;
$vn-width: 30px;
$vn-height: 15px;
$vn-total-width: 510px;
$vn-total-height: 490px;
$vn-image: 'sprite.png';
$vn: (480px, 400px, -480px, -400px, 30px, 15px, 510px, 490px, 'sprite.png', 'vn', );
$vu-name: 'vu';
$vu-x: 480px;
$vu-y: 425px;
$vu-offset-x: -480px;
$vu-offset-y: -425px;
$vu-width: 30px;
$vu-height: 15px;
$vu-total-width: 510px;
$vu-total-height: 490px;
$vu-image: 'sprite.png';
$vu: (480px, 425px, -480px, -425px, 30px, 15px, 510px, 490px, 'sprite.png', 'vu', );
$wf-name: 'wf';
$wf-x: 480px;
$wf-y: 450px;
$wf-offset-x: -480px;
$wf-offset-y: -450px;
$wf-width: 30px;
$wf-height: 15px;
$wf-total-width: 510px;
$wf-total-height: 490px;
$wf-image: 'sprite.png';
$wf: (480px, 450px, -480px, -450px, 30px, 15px, 510px, 490px, 'sprite.png', 'wf', );
$ws-name: 'ws';
$ws-x: 0px;
$ws-y: 475px;
$ws-offset-x: 0px;
$ws-offset-y: -475px;
$ws-width: 30px;
$ws-height: 15px;
$ws-total-width: 510px;
$ws-total-height: 490px;
$ws-image: 'sprite.png';
$ws: (0px, 475px, 0px, -475px, 30px, 15px, 510px, 490px, 'sprite.png', 'ws', );
$xk-name: 'xk';
$xk-x: 40px;
$xk-y: 475px;
$xk-offset-x: -40px;
$xk-offset-y: -475px;
$xk-width: 30px;
$xk-height: 15px;
$xk-total-width: 510px;
$xk-total-height: 490px;
$xk-image: 'sprite.png';
$xk: (40px, 475px, -40px, -475px, 30px, 15px, 510px, 490px, 'sprite.png', 'xk', );
$ye-name: 'ye';
$ye-x: 80px;
$ye-y: 475px;
$ye-offset-x: -80px;
$ye-offset-y: -475px;
$ye-width: 30px;
$ye-height: 15px;
$ye-total-width: 510px;
$ye-total-height: 490px;
$ye-image: 'sprite.png';
$ye: (80px, 475px, -80px, -475px, 30px, 15px, 510px, 490px, 'sprite.png', 'ye', );
$yt-name: 'yt';
$yt-x: 120px;
$yt-y: 475px;
$yt-offset-x: -120px;
$yt-offset-y: -475px;
$yt-width: 30px;
$yt-height: 15px;
$yt-total-width: 510px;
$yt-total-height: 490px;
$yt-image: 'sprite.png';
$yt: (120px, 475px, -120px, -475px, 30px, 15px, 510px, 490px, 'sprite.png', 'yt', );
$spritesheet-width: 510px;
$spritesheet-height: 490px;
$spritesheet-image: 'sprite.png';
$spritesheet-sprites-small: ($ad, $ae, $af, $ag, $ai, $al, $am, $ao, $aq, $ar, $as, $at, $au, $aw, $ax, $az, $ba, $bb, $bd, $be, $bf, $bg, $bh, $bi, $bj, $bl, $bm, $bn, $bo, $bq, $br, $bs, $bt, $bv, $bw, $by, $bz, $ca, $cc, $cd, $cf, $cg, $ch, $ci, $ck, $cl, $cm, $cn, $co, $cr, $cu, $cv, $cw, $cx, $cy, $cz, $de, $dj, $dk, $dm, $do, $dz, $ec, $ee, $eg, $eh, $er, $es, $et, $fi, $fj, $fk, $fm, $fo, $fr, $ga, $gb-eng, $gb-nir, $gb-sct, $gb-wls, $gb, $gd, $ge, $gf, $gg, $gh, $gi, $gl, $gm, $gn, $gp, $gq, $gr, $gs, $gt, $gu, $gw, $gy, $hk, $hm, $hn, $hr, $ht, $hu, $id, $ie, $il, $im, $in, $io, $iq, $ir, $is, $it, $je, $jm, $jo, $jp, $ke, $kg, $kh, $ki, $km, $kn, $kp, $kr, $kw, $ky, $kz, $la, $lb, $lc, $li, $lk, $lr, $ls, $lt, $lu, $lv, $ly, $ma, $mc, $md, $me, $mf, $mg, $mh, $mk, $ml, $mm, $mn, $mo, $mp, $mq, $mr, $ms, $mt, $mu, $mv, $mw, $mx, $my, $mz, $na, $nc, $ne, $nf, $ng, $ni, $nl, $no, $np, $nr, $nu, $nz, $om, $pa, $pe, $pf, $pg, $ph, $pk, $pl, $pm, $pn, $pr, $ps, $pt, $pw, $py, $qa, $re, $ro, $rs, $ru, $rw, $sa, $sb, $sc, $sd, $se, $sg, $sh, $si, $sj, $sk, $sl, $sm, $sn, $so, $sr, $ss, $st, $sv, $sx, $sy, $sz, $tc, $td, $tf, $tg, $th, $tj, $tk, $tl, $tm, $tn, $to, $tr, $tt, $tv, $tw, $tz, $ua, $ug, $um, $us, $uy, $uz, $va, $vc, $ve, $vg, $vi, $vn, $vu, $wf, $ws, $xk, $ye, $yt, );
$spritesheet: (510px, 490px, 'sprite.png', $spritesheet-sprites-small, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home
//   @include sprite-width($icon-home)
//
// .icon-email
//   @include sprite($icon-email)
//
// Example usage in HTML:
//   `display: block` sprite:
//   <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//   // CSS
//   .icon {
//     display: inline-block;
//   }
//
//   // HTML
//   <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5); }

@mixin sprite-height($sprite) {
  height: nth($sprite, 6); }

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y; }

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url('../img/flags_sprite_small.png'); }

@mixin sprite($sprite) {
  &::before {
    content: "";
    float: left;
    @include sprite-image($sprite);
    @include sprite-position($sprite);
    @include sprite-width($sprite);
    @include sprite-height($sprite); } }


// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SASS
//
// @include sprites($spritesheet-sprites-small)
@mixin sprites-small($sprites) {
  @each $sprite in $sprites {
    $sprite-name: 'flag-small-' + nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite); } } }
