.pdf-modal {
	width: 95% !important;
	height: 100% !important;
	min-width: 320px; }

.pdf-modal-container {
	width: 100%;
	height: 100%;
	max-width: 100%;
	padding: 20px;
	display: flex;
	align-items: center;
	flex-direction: column;

	@media (max-width:768px) {
	  padding: 10px; } }

.pdf-modal-container__title {
	text-align: center;
	padding: 0 40px 20px;
	margin: 0;

	@media (max-width:768px) {
		font-size: 1.2em;
		padding: 5px;
		padding-bottom: 10px; } }

.pdf-modal-container__link {
	// display: inline-block
	margin: 20px auto 10px;
	// background: #352ddb
	padding: 15px 25px;
	// border-radius: 30px
	// color: white

	// &:hover
	// 	color: white
	// 	background: lighten(#352ddb, 20%)

	@media (max-width:768px) {
		margin: 5px;
		padding: 5px 10px; } }
